import "./style.css"
import { useState, useEffect } from "react"
import axios from "axios"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import img_logo from "../../Assets/Img/logo.png"
import Spinner from 'react-bootstrap/Spinner'

const api = axios.create({
  baseURL: "https://api.brconnect.com.br/api/",
  headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=utf-8"
  }
})

function Login({ setLogon, setNovoUsuario, setRecuperarSenha, setCategorias, setSubCategorias }) {  
  const [loading, setLoading] = useState("")
  const [error, setError] = useState("")
  
  const [cpfCnpj, setCpfCnpj] = useState("")
  const [senha, setSenha] = useState("")

  useEffect(() => {
    document.getElementById("cpfCnpj_Login") &&
      document.getElementById("cpfCnpj_Login").focus()
  }, [loading])

  const logar = () => {
    var retornoJson = { CONCRETIZADO: false, RESULTADO: { ID: 0 }, ERRO: "", AVISO: "" }
    var retornoCategoria = { CONCRETIZADO: false, RESULTADO: { ID: 0 }, ERRO: "", AVISO: "" }
    var retornoSubCategoria = { CONCRETIZADO: false, RESULTADO: { ID: 0 }, ERRO: "", AVISO: "" }

    setLoading("Aguarde efetuando o login...")
    setError("")

    if (cpfCnpj === "")
    {
      setLoading("")
      setError("É necessário informar o CPF/CNPJ!")
      return;
    }

    if (senha === "")
    {
      setLoading("")
      setError("É necessário informar a senha!")

      document.getElementById("senha_Login") &&
        document.getElementById("senha_Login").focus()

      return;
    }

    var jsonLogin = "{ \"CPF_CNPJ\": \"90243583000174\", \"SENHA\": \"hK96w0K4\" }"
    var jsonUsuario = "{ \"CPF_CNPJ\": \""+ cpfCnpj.replaceAll("_","") +"\", \"SENHA\": \""+ senha +"\" }"

    api.get("USUARIO/Logar?jsonLogin="+ jsonLogin +"&jsonUsuario="+ jsonUsuario)
    .then((response) => { retornoJson = JSON.parse(response.data) })
    .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível efetuar o login. ("+ response +")\"}") })
    .finally(() => { 
      if (retornoJson.CONCRETIZADO === false){
        setLoading("")
        setError(retornoJson.ERRO)
        return
      }
      else
      {
        if (retornoJson.AVISO === "Nenhum registro encontrado."){
          setLoading("")
          setError("Não foi encontrado o usuário com CPF/CNPJ e senha digitados.")
          return
        }
        
        // Busca informações das categorias
        api.get("CATEGORIA/Consultar?jsonLogin="+ jsonLogin)
        .then((response) => { retornoCategoria = JSON.parse(response.data) })
        .catch((response) => { retornoCategoria = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível obter as categorias. ("+ response +")\"}") })
        .finally(() => { 
          if (retornoCategoria.CONCRETIZADO === false){
            setLoading("")
            setError(retornoCategoria.ERRO)
            return
          }
          setCategorias(retornoCategoria.RESULTADO)
        })

        // Busca informações das sub-categorias
        api.get("SUB_CATEGORIA/Consultar?jsonLogin="+ jsonLogin)
        .then((response) => { retornoSubCategoria = JSON.parse(response.data) })
        .catch((response) => { retornoSubCategoria = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível obter as categorias. ("+ response +")\"}") })
        .finally(() => { 
          if (retornoSubCategoria.CONCRETIZADO === false){
            setLoading("")
            setError(retornoSubCategoria.ERRO)
            return
          }
          setSubCategorias(retornoSubCategoria.RESULTADO)
          setLogon(retornoJson.RESULTADO[0])
        })
      }
      setLoading("")
      setError("")
    })
  }

  return(
    <div id="main_Login">
      { loading ?
          <div id="container_Login">
            <div id="containerLogo_Login">
              <img src={img_logo} id="imgLogo_Login" alt="Logo" />
            </div>
            <div className="mensagem_Login">
              <Spinner animation="border" variant="info" />
              <label id="mensagemLabel_Login">{loading}</label>
            </div>
          </div>
        :
          <div id="container_Login">
            <div id="containerLogo_Login">
              <img src={img_logo} id="imgLogo_Login" alt="Logo" />
            </div>

            <div className="view_Login">
              <InputMasked 
                title=""
                name="cpfCnpj_Login"
                mask="99999999999999"
                disabled={false}
                typeImage={1}
                placeholder="CPF/CNPJ"
                withBorder={true}
                value={cpfCnpj}
                onChange={(event) => setCpfCnpj(event.target.value)} />
            </div>

            <div className="view_Login">
              <Input 
                type="password"
                title=""
                name="senha_Login"
                disabled={false}
                maxLength={8}
                typeImage={1}
                placeholder="Senha"
                withBorder={true}
                value={senha}
                onChange={(event) => setSenha(event.target.value)} />
            </div>

            { error &&
              <div className="mensagem_Login">
                <label id="menssageError_Login">{error}</label>
              </div>
            }

            <div id="botao_Login" onClick={logar}>
              <div id="botaoDiv_Login">
                <label id="botaoLabel_Login">Entrar</label>
              </div>
            </div>

            <div id="novo_Login" onClick={() => setNovoUsuario()}>
              <label id="novoLabel_Login">Novo usuário?</label>
            </div>

            <div id="esqueceu_Login" onClick={() => setRecuperarSenha()}>
              <label id="esqueceuLabel_Login">Esqueceu a senha?</label>
            </div>
          </div>
      }
    </div>
  )
}

export default Login