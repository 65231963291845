import "./style.css"
import Img_assistencia from "../../Assets/Img/assistencia.png";
import Img_aula from "../../Assets/Img/aula.png";
import Img_autos from "../../Assets/Img/autos.png";
import Img_consultoria from "../../Assets/Img/consultoria.png";
import Img_eventos from "../../Assets/Img/eventos.png";
import Img_moda_beleza from "../../Assets/Img/moda_beleza.png";
import Img_reformas from "../../Assets/Img/reformas.png";
import Img_saude from "../../Assets/Img/saude.png";
import Img_servico_domestico from "../../Assets/Img/servico_domestico.png";

const CategoriaCard = ({ categoria, setSelecionarCategoria }) =>  {
  return(
    <div id="card_Categoria">
      <div id="cardConteudo_Categoria">
        { categoria.ID === 1 &&
          <img className="cardImagem_Categoria" src={Img_assistencia} alt="Imagem categoria"/>
        }

        { categoria.ID === 2 &&
          <img className="cardImagem_Categoria" src={Img_aula} alt="Imagem categoria"/>
        }

        { categoria.ID === 3 &&
          <img className="cardImagem_Categoria" src={Img_autos} alt="Imagem categoria"/>
        }

        { categoria.ID === 4 &&
          <img className="cardImagem_Categoria" src={Img_consultoria} alt="Imagem categoria"/>
        }

        { categoria.ID === 5 &&
          <img className="cardImagem_Categoria" src={Img_eventos} alt="Imagem categoria"/>
        }

        { categoria.ID === 6 &&
          <img className="cardImagem_Categoria" src={Img_moda_beleza} alt="Imagem categoria"/>
        }

        { categoria.ID === 7 &&
          <img className="cardImagem_Categoria" src={Img_reformas} alt="Imagem categoria"/>
        }

        { categoria.ID === 8 &&
          <img className="cardImagem_Categoria" src={Img_saude} alt="Imagem categoria"/>
        }

        { categoria.ID === 9 &&
          <img className="cardImagem_Categoria" src={Img_servico_domestico} alt="Imagem categoria"/>
        }
      </div>
    </div>
  )
}

export default CategoriaCard