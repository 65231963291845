import "./style.css"
import React from "react"
import { Carousel } from "react-bootstrap"
import button_return from "../../Assets/Img/Botoes/excluir.png"


const EnlargePhoto = ({ listPhoto, setShowEnlargePhoto }) => {
  return(
    <div id="main_EnlargePhoto">
      <div style={{ display: "flex", justifyContent: "center", marginBottom: 5 }} onClick={() => setShowEnlargePhoto(false)}>
        <img src={button_return} className="closePhoto_EnlargePhoto" alt="Retornar" />
        <div style={{ display: "flex", alignItems: "center" }}>
          <label style={{ fontFamily: "Roboto-Regular", color: "rgb(2, 155, 222)", fontWeight: 600, textAlign: "center", marginLeft: 10}} >Fechar Vizualização das Fotos</label>
        </div>
      </div>

      <Carousel>
        { listPhoto.map(itemF => (                                                                        
            <Carousel.Item key={"listPhoto"+ itemF.ID}>
              <img className="photo_EnlargePhoto" src={itemF.CAMINHO} alt={"Photo" + String(itemF.ID)} />
            </Carousel.Item>
          ))
        }
      </Carousel>
    </div>
  )
}

export default EnlargePhoto