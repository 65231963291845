import "./style.css"
import { useState, useEffect } from "react"
import axios from "axios"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import Img_logo from "../../Assets/Img/logo.png"
import Spinner from 'react-bootstrap/Spinner'

const api = axios.create({
  baseURL: "https://api.brconnect.com.br/api/",
  headers: {                
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json;charset=utf-8"
  }
})

function RecuperarSenha({ setVoltar }) {
  const [loading, setLoading] = useState("")
  const [error, setError] = useState("")

  const [email, setEmail] = useState("")
  const [telefone, setTelefone] = useState("")  

  useEffect(() => {
    document.getElementById("email_RecuperarSenha") &&
      document.getElementById("email_RecuperarSenha").focus()
  }, [])  

  const recuperar = () => {
    var retornoJson = []        
    var jsonLogin   = "{ \"CPF_CNPJ\": \"90243583000174\", \"SENHA\": \"hK96w0K4\" }"
    var jsonUsuario = "{ \"EMAIL\": \""+ email +"\", \"TELEFONE\": \""+ telefone.replaceAll("_","") + "\" }"

    setLoading("Aguarde recuperando a senha...")
    setError("")
        
    if (email === "")
    {
      setLoading("")
      setError("É necessário informar o email!")

      document.getElementById("email_RecuperarSenha") &&
        document.getElementById("email_RecuperarSenha").focus()

      return;
    }
    
    if (telefone === "")
    {
      setLoading("")
      setError("É necessário informar o telefone!");

      document.getElementById("telefone_RecuperarSenha") &&
        document.getElementById("telefone_RecuperarSenha").focus()

      return;
    }        

    api.get("USUARIO/RecuperarSenha?jsonLogin="+ jsonLogin +"&jsonUsuario="+ jsonUsuario)
    .then((response) => { retornoJson = JSON.parse(response.data) })
    .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível comunicar com a WebAPI ("+ response +")\"}") })
    .finally(() => { 
      if (retornoJson.CONCRETIZADO === false){
        setLoading("")
        setError(retornoJson.ERRO)
        return
      }
      else
      {
        if (retornoJson.AVISO === "Nenhum registro encontrado."){
          setLoading("")
          setError("Não foi encontrado o usuário com o email e telefone digitados.")

          document.getElementById("email_RecuperarSenha") &&
            document.getElementById("email_RecuperarSenha").focus()          

          return
        }
        
        alert("Reset da senha efetuado com sucesso! Nova senha ("+ retornoJson.RESULTADO + ")")
        setVoltar()
      }      
    })
  }

  return(
    <div id="main_RecuperarSenha">
      <div id="containerLogo_RecuperarSenha">
        <img id="imgLogo_RecuperarSenha" src={Img_logo} alt="Logo" />
      </div>
        
      { loading ?
          <>
            <div id="containerCarregando_RecuperarSenha">
              <Spinner animation="border" variant="info" />
              <label id="textCarregando_RecuperarSenha">{loading}</label>
            </div>
          </>
        :
          <>          
            <div id="containerTitulo_RecuperarSenha">
              <label id="titulo_RecuperarSenha">RECUPERAR</label><label id="tituloSub_RecuperarSenha">SENHA</label>
            </div>

            <div id="containerCampos_RecuperarSenha">
              <div className="campo_RecuperarSenha marginTop10_RecuperaSenha">
                <Input 
                  type="text"
                  title="Email:"
                  name="email_RecuperarSenha"
                  disabled={false}                  
                  maxLength={50}
                  typeImage={1}
                  placeholder=""
                  value={email}
                  onChange={(event) => setEmail(event.target.value)} />
              </div>

              <div className="campo_RecuperarSenha">
                <InputMasked 
                  title="Telefone:"
                  name="telefone_RecuperarSenha"
                  mask="(99)999999999"
                  disabled={false}
                  typeImage={0}
                  placeholder=""
                  value={telefone || ""}
                  onChange={(event) => setTelefone(event.target.value)} />
              </div>            

              { error &&
                <div id="mensagem_RecuperarSenha">
                  <label id="menssageError_RecuperarSenha">{error}</label>
                </div>
              }

              <div className="containerBotao_RecuperaSenha" onClick={recuperar}>
                <div className="textBotao_RecuperaSenha">Recuperar</div>
              </div>
  
              <div className="containerBotao_RecuperaSenha marginTop10_RecuperaSenha marginBottom10_RecuperaSenha" onClick={setVoltar}>
                <div className="textBotao_RecuperaSenha">Voltar</div>
              </div>
            </div>
          </>
      }
    </div>
  )
}

export default RecuperarSenha