import "./style.css"
import axios from "axios"
import React, { useState, useEffect } from "react"
import Spinner from 'react-bootstrap/Spinner'
import Input from "../../../Components/Input"
import Img_Btn_Limpar from "../../../Assets/Img/clean.svg"
import Img_botao_sub_selecionada from "../../../Assets/Img/Botoes/sub_selecionada.png"

const api = axios.create({
  baseURL: "https://api.brconnect.com.br/api/",
  headers: {                
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json;charset=utf-8"
  }
})

const ListaUsuario = ({ usuario, nome, setNome, setSelecionado, setLimpar }) => {
  const [desabilitar, setDesabilitar] = useState(false)
    
  const [retorno, setRetorno] = useState({})

  const [carregando, setCarregando] = useState("")
  const [mensagem, setMensagem] = useState("")
  const [mensagemErro, setMensagemErro] = useState("")

  useEffect(() => {    
    if (nome !== "")
    {
      var retornoJson = []

      setCarregando("Aguarde buscando os usuários...")
      setMensagem("")
      setMensagemErro("")

      var jsonLogin = "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ +"\", \"SENHA\": \""+ usuario.SENHA +"\" }"

      var jsonUsuario = "{ "
      if (nome !== "") jsonUsuario += "\"NOME\": \""+ nome + "\""
      jsonUsuario += " }"

      api.get("USUARIO/Consultar?jsonLogin="+ jsonLogin + "&jsonUsuario=" + jsonUsuario)
      .then((response) => { retornoJson = JSON.parse(response.data) })
      .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível consultar os usuários ("+ response +")\"}") })
      .finally(() => {
        if (retornoJson.CONCRETIZADO === false){
          setCarregando("")
          setMensagem("")
          setMensagemErro(retornoJson.ERRO)

          document.getElementById("nomee_ListaUsuario") &&
            document.getElementById("nomee_ListaUsuario").focus()

          return
        }
        else
        {                    
          setMensagem("")
          setMensagemErro("")
          setRetorno(retornoJson)
          setCarregando("")

          document.getElementById("nomee_ListaUsuario") &&
            document.getElementById("nomee_ListaUsuario").focus()          
        }        
      })
    }
  }, [usuario, nome])

  const formatarCpfCnpj = (cpfCnpj) => {
    let formatar = ""

    if (String(cpfCnpj).length === 11){
      formatar = String(cpfCnpj).substring(0,3) + "." + String(cpfCnpj).substring(3,6) + "." + String(cpfCnpj).substring(6,9) + "-" +
        String(cpfCnpj).substring(9,11)
    }
    else{
      formatar = String(cpfCnpj).substring(0,2) + "." + String(cpfCnpj).substring(2,5) + "." + String(cpfCnpj).substring(5,8) + "/" +
        String(cpfCnpj).substring(8,12) + "-" + String(cpfCnpj).substring(12,14)
    }

    return formatar
  }

  const limpar = () => {
    setDesabilitar(false)
    setLimpar("")
  }

  const selecionar = (usuario) => {
    setDesabilitar(true)
    setSelecionado(usuario)
  }

  return(
    <div className="main_ListaUsuario">
      { (carregando !== "") &&
        <div id="carregando_ListaUsuario">
          <Spinner animation="border" variant="info" />
          <label id="carregandoTexto_ListaUsuario">{carregando}</label>
        </div>
      }

      { (carregando === "") &&
        <div className="main_ListaUsuario">
          <div className="main_ListaUsuario">
            <div id="campo_ListaUsuario">
              <div id="nome_ListaUsuario">
                <Input 
                  type="search"
                  title={ desabilitar ? "Cliente:" : "Usuário:" } 
                  name="nomee_ListaUsuario"
                  disabled={desabilitar}
                  maxLength={50}
                  placeholder="Selecione o usuário..."
                  typeImage={0}
                  value={nome || ""}
                  onChange={(event) => setNome(event.target.value)} />
              </div>
              <div id={ desabilitar ? "limparSelecionado_ListaUsuario" : "limpar_ListaUsuario"  } >
                <img
                  name="limpar_ListaUsuario"
                  src={Img_Btn_Limpar}
                  alt="Limpar"
                  onClick={() => limpar()} />
              </div>
            </div>
          </div>

          { (nome !== "") &&  (!desabilitar) &&
            <div id="grid_ListaUsuario">
              { (Array.isArray(retorno.RESULTADO)) &&
                <div id="selecione_ListaUsuario">
                  <label id="selecioneTexto_ListaUsuario">Selecione o usuário:</label>
                </div>
              }

              { (Array.isArray(retorno.RESULTADO)) &&
                retorno.RESULTADO.map(item => (
                  <div key={"trListaUsuario_Resultado"+ item.ID} className="resultado_ListaUsuario" onClick ={(() => selecionar(item))}>
                    <div className="resultadoDescricao_ListaUsuario">
                      <label>{formatarCpfCnpj(item.CPF_CNPJ)} {" - "} {item.NOME}</label>
                    </div>
                    <div className="resultadoBotao_ListaUsuario">
                      <img src={Img_botao_sub_selecionada} alt="Botão" className="botao_ListaUsuario" />
                    </div>
                  </div>
                ))
              }

              { (!Array.isArray(retorno.RESULTADO)) &&
                <div id="nenhumRegistro_ListaUsuario">
                  <label id="nenhumRegistroTexto_ListaUsuario">Nenhum usuário encontrado com o filtro informado!</label>
                </div>
              }
            </div>
          }

          { (mensagem !== "") &&
            <div id="mensagem_ListaUsuario">
              <label id="mensagemTexto_ListaUsuario">{mensagem}</label>
            </div>
          }

          { (mensagemErro !== "") &&
            <div id="mensagemErro_ListaUsuario">
              <label id="mensagemErroTexto_ListaUsuario">{mensagemErro}</label>
            </div>
          }
        </div>      
      }

    </div>
  )
}

export default ListaUsuario