import "./style.css"
import { useEffect, useState } from "react"
import axios from "axios"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import Select from "../../Components/Select"
import Img_menu from "../../Assets/Img/Botoes/menu.png"
import Img_logo_topo from "../../Assets/Img/logo_topo_colorida.png"
import Img_progredir from "../../Assets/Img/Botoes/progredir.png"
import Img_salvar from "../../Assets/Img/Botoes/salvar.png"
import Img_excluir from "../../Assets/Img/Botoes/excluir.png"
import Spinner from 'react-bootstrap/Spinner'

const api = axios.create({
  baseURL: "https://api.brconnect.com.br/api/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json;charset=utf-8"
  }
})

const Usuario = (({ usuario, setSelecionar, setVoltarNovo, setVoltar, chamarMenu, width }) => {
  const [codigo, setCodigo] = useState(undefined)
  const [cpfCnpj, setCpfCnpj] = useState("")
  const [nome, setNome] = useState("")
  const [senha, setSenha] = useState("")
  const [email, setEmail] = useState("")
  const [telefone, setTelefone] = useState("")
  const [whatsapp, setWhatsapp] = useState(0)

  const [liberarEndereco, setLiberarEndereco] = useState(false)
  const [cepSelecionado, setCepSelecionado] = useState(false)

  const [cep, setCep] = useState("")
  const [logradouro, setLogradouro] = useState("")
  const [numero, setNumero] = useState("")
  const [complemento, setComplemento] = useState("")
  const [bairro, setBairro] = useState("")
  const [cidade, setCidade] = useState("")
  const [uf, setUf] = useState("")
  const [coordenadas, setCoordenadas] = useState("")
  const [caminhoFoto, setCaminhoFoto] = useState("")
  const [alteracaoImagem, setAlteracaoImagem] = useState(0)

  const [loading, setLoading] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {
    if (usuario.ID !== undefined) {
      setCodigo(usuario.ID)
      setCepSelecionado(true)

      document.getElementById("nome_Usuario") &&
        document.getElementById("nome_Usuario").focus()      
    }
    else{
      document.getElementById("cpfCnpj_Usuario") &&
        document.getElementById("cpfCnpj_Usuario").focus()
    }
       
    if (usuario.CPF_CNPJ !== undefined) setCpfCnpj(usuario.CPF_CNPJ)
    if (usuario.NOME !== undefined) setNome(usuario.NOME)
    if (usuario.SENHA !== undefined) setSenha(usuario.SENHA)
    if (usuario.EMAIL !== undefined) setEmail(usuario.EMAIL)
    if (usuario.TELEFONE !== undefined) setTelefone(usuario.TELEFONE)
    if (usuario.WHATSAPP !== undefined) setWhatsapp(usuario.WHATSAPP)
    if (usuario.CEP !== undefined) setCep(usuario.CEP)
    if (usuario.LOGRADOURO !== undefined) setLogradouro(usuario.LOGRADOURO)
    if (usuario.NUMERO !== undefined) setNumero(usuario.NUMERO)
    if ((usuario.COMPLEMENTO !== undefined) && (usuario.COMPLEMENTO !== null)) setComplemento(usuario.COMPLEMENTO)
    if (usuario.BAIRRO !== undefined) setBairro(usuario.BAIRRO)
    if (usuario.CIDADE !== undefined) setCidade(usuario.CIDADE)
    if (usuario.UF !== undefined) setUf(usuario.UF)
    if (usuario.COORDENADAS !== undefined) setCoordenadas(usuario.COORDENADAS)
    if ((usuario.CAMINHO_FOTO !== undefined) && (usuario.CAMINHO_FOTO !== null)) setCaminhoFoto(usuario.CAMINHO_FOTO)
    if (usuario.ALTERACAO_IMAGEM !== undefined) setAlteracaoImagem(usuario.ALTERACAO_IMAGEM)


  }, [])

  const buscaCEP = () => {
    if (cep === "")
    {
      setLoading("")
      setError("CEP não informado!!!")      

      document.getElementById("cep_Usuario") &&
        document.getElementById("cep_Usuario").focus()

      return
    }        

    setLoading("Aguarde buscando o CEP...")
    setError("")

    const apiCEP = axios.create({
      baseURL: "https://viacep.com.br/ws/"
    })

    apiCEP.get(cep + "/json/")
    .then((response) => {     
      if (response.data.erro !== true) {
        if ((response.data.logradouro === "") && (response.data.bairro === "")) setLiberarEndereco(true)
        else setLiberarEndereco(false)

        setLogradouro(response.data.logradouro)
        setBairro(response.data.bairro)
        setCidade(response.data.localidade)
        setUf(response.data.uf)

        setCepSelecionado(true)
      } 
      else
      {
        setError("CEP não encontrado.")

        setLogradouro("")
        setBairro("")
        setCidade("")
        setUf("")
      }
    })
    .catch(() => 
    { 
      setError("Não foi possível obter CEP.")

      setLogradouro("")
      setBairro("")
      setCidade("")
      setUf("")
    })
    .finally(() => { setLoading("") })
  }

  const enviar = async(e) => {
    var retornoJson = []

    const formData = new FormData();
    formData.append("JSON_LOGIN", "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ +"\", \"SENHA\": \""+ usuario.SENHA +"\" }");
    formData.append("JSON_USUARIO", "{ \"ID\": "+ usuario.ID + ", \"ALTERACAO_IMAGEM\": "+ (usuario.ALTERACAO_IMAGEM + 1) +" }");
    formData.append("FILE", e);
       
    try
    {
      setLoading("Aguarde efetuando o upload da foto do usuário...")

      const res = await axios({
        method: "post",
        url: "https://api.brconnect.com.br/api/USUARIO/Upload",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      
      retornoJson = JSON.parse(res.data)

      if (retornoJson.CONCRETIZADO){
        alert("Foto enviada com sucesso.")
        
        usuario.CAMINHO_FOTO     = (retornoJson.RESULTADO)
        usuario.ALTERACAO_IMAGEM = (usuario.ALTERACAO_IMAGEM + 1)

        setCaminhoFoto(retornoJson.RESULTADO)
        setAlteracaoImagem(usuario.ALTERACAO_IMAGEM + 1)
      }
      else{
        setError(retornoJson.ERRO)
        setCaminhoFoto("")
      }
    } catch (ex) {
      setError("Não foi possível enviar a foto. ("+ ex +")")
      setCaminhoFoto("")
    }
    finally { setLoading("") }
  }

  const handleArquivo = (e) => {
    const _file = e.target.files[0];
    enviar(_file)
  }

  const limparCep = () => {
    setLiberarEndereco(false)
    
    setCep("")
    setLogradouro("")
    setNumero("")
    setComplemento("")
    setBairro("")
    setCidade("")
    setUf("")
    setCoordenadas("")
    
    setCepSelecionado(false)
    setError("")

    document.getElementById("cep_Usuario") &&
      document.getElementById("cep_Usuario").focus()
  }

  const salvar = () => {
    var retornoJson = []

    var jsonLogin = "{ "
    if (usuario.CPF_CNPJ === undefined)
    {
      jsonLogin += "\"CPF_CNPJ\": \"90243583000174\""
      jsonLogin += ", \"SENHA\": \"hK96w0K4\""
    }
    else
    {
      jsonLogin += "\"CPF_CNPJ\": \""+ usuario.CPF_CNPJ + "\""
      jsonLogin += ", \"SENHA\": \""+ usuario.SENHA + "\""
    }
    jsonLogin += "}"

    var jsonUsuario_Antigo = "{ "
    if (usuario.ID !== undefined)
    {
      jsonUsuario_Antigo += "\"ID\": "+ usuario.ID
      jsonUsuario_Antigo += ", \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ.replaceAll("_","") + "\""
      jsonUsuario_Antigo += ", \"NOME\": \""+ usuario.NOME + "\""
      jsonUsuario_Antigo += ", \"SENHA\": \""+ usuario.SENHA + "\""
      jsonUsuario_Antigo += ", \"EMAIL\": \""+ usuario.EMAIL + "\""
      jsonUsuario_Antigo += ", \"TELEFONE\": \""+ usuario.TELEFONE.replace("(","").replace(")","").replaceAll("_","") + "\""
      jsonUsuario_Antigo += ", \"WHATSAPP\": "+ usuario.WHATSAPP
      jsonUsuario_Antigo += ", \"CEP\": \""+ usuario.CEP.replaceAll("_","") + "\""    
      jsonUsuario_Antigo += ", \"LOGRADOURO\": \""+ usuario.LOGRADOURO + "\""
      jsonUsuario_Antigo += ", \"NUMERO\": \""+ usuario.NUMERO + "\""
    
      if (usuario.COMPLEMENTO !== undefined)
        jsonUsuario_Antigo += ", \"COMPLEMENTO\": \""+ usuario.COMPLEMENTO + "\""
    
      jsonUsuario_Antigo += ", \"BAIRRO\": \""+ usuario.BAIRRO + "\""
      jsonUsuario_Antigo += ", \"CIDADE\": \""+ usuario.CIDADE + "\""
      jsonUsuario_Antigo += ", \"UF\": \""+ usuario.UF + "\""
      jsonUsuario_Antigo += ", \"COORDENADAS\": \""+ usuario.COORDENADAS + "\""
    
      if (usuario.CAMINHO_FOTO !== undefined)
        jsonUsuario_Antigo += ", \"CAMINHO_FOTO\": \""+ usuario.CAMINHO_FOTO + "\""
            
      jsonUsuario_Antigo += ", \"ALTERACAO_IMAGEM\": "+ usuario.ALTERACAO_IMAGEM
    }
    jsonUsuario_Antigo += "}"

    var jsonUsuario = "{ "
    if (codigo !== undefined) jsonUsuario += "\"ID\": "+ codigo

    if (cpfCnpj !== "")
    {
      if (jsonUsuario !== "{ ") jsonUsuario += ", "
      jsonUsuario += "\"CPF_CNPJ\": \""+ cpfCnpj.replaceAll("_","") + "\""
    }
    else
    {
      setError("É necessário informar o CPF/CNPJ!")

      document.getElementById("cpfCnpj_Usuario") &&
        document.getElementById("cpfCnpj_Usuario").focus()

      return;
    }

    if (senha !== "")
    {
      if (jsonUsuario !== "{ ") jsonUsuario += ", "
      jsonUsuario += "\"SENHA\": \""+ senha + "\""
    }
    else
    {
      setError("É necessário informar a senha!")

      document.getElementById("senha_Usuario") &&
        document.getElementById("senha_Usuario").focus()

      return;
    }

    if (nome !== "")
    {
      if (jsonUsuario !== "{ ") jsonUsuario += ", "
      jsonUsuario += "\"NOME\": \""+ nome + "\""
    }
    else
    {
      setError("É necessário informar o nome!")

      document.getElementById("nome_Usuario") &&
        document.getElementById("nome_Usuario").focus()

      return;
    }

    if (email !== "")
    {
      if (jsonUsuario !== "{ ") jsonUsuario += ", "
      jsonUsuario += "\"EMAIL\": \""+ email + "\""
    }
    else
    {
      setError("É necessário informar o email!")

      document.getElementById("email_Usuario") &&
        document.getElementById("email_Usuario").focus()

      return;
    }

    if (telefone !== "")
    {
      if (jsonUsuario !== "{ ") jsonUsuario += ", "
      jsonUsuario += "\"TELEFONE\": \""+ telefone.replace("(","").replace(")","").replaceAll("_","") + "\""
    }
    else
    {
      setError("É necessário informar o telefone!")

      document.getElementById("telefone_Usuario") &&
        document.getElementById("telefone_Usuario").focus()

      return;
    }

    if (whatsapp !== 0)
    {
      if (jsonUsuario !== "{ ") jsonUsuario += ", "
      jsonUsuario += "\"WHATSAPP\": "+ whatsapp
    }
    else
    {
      setError("É necessário informar se possui whatsapp!")

      document.getElementById("whatsapp_Usuario") &&
        document.getElementById("whatsapp_Usuario").focus()
      
      return;
    }

    if (cep !== "")
    {
      if (jsonUsuario !== "{ ") jsonUsuario += ", "
      jsonUsuario += "\"CEP\": \""+ cep.replaceAll("_","") + "\""
    }
    else
    {
      setError("É necessário informar o CEP!")

      document.getElementById("cep_Usuario") &&
        document.getElementById("cep_Usuario").focus()

      return;
    }

    if (logradouro !== "")
    {
      if (jsonUsuario !== "{ ") jsonUsuario += ", "
      jsonUsuario += "\"LOGRADOURO\": \""+ logradouro + "\""
    }
    else
    {
      setError("É necessário informar o endereço. Aperte na lupa!")

      document.getElementById("cep_Usuario") &&
        document.getElementById("cep_Usuario").focus()

      return;
    }

    if (numero !== "")
    {
      if (jsonUsuario !== "{ ") jsonUsuario += ", "
      jsonUsuario += "\"NUMERO\": \""+ numero.replaceAll("_","") + "\""
    }
    else
    {
      setError("É necessário informar o número.")

      document.getElementById("numero_Usuario") &&
        document.getElementById("numero_Usuario").focus()

      return;
    }

    if (complemento !== "")
    {
      if (jsonUsuario !== "{ ") jsonUsuario += ", "
      jsonUsuario += "\"COMPLEMENTO\": \""+ complemento + "\""
    }

    if (bairro !== "")
    {
      if (jsonUsuario !== "{ ") jsonUsuario += ", "
      jsonUsuario += "\"BAIRRO\": \""+ bairro + "\""
    }
    else
    {
      setError("É necessário informar o endereço. Aperte na lupa!")

      document.getElementById("cep_Usuario") &&
        document.getElementById("cep_Usuario").focus()

      return;
    }

    if (cidade !== "")
    {
      if (jsonUsuario !== "{ ") jsonUsuario += ", "
      jsonUsuario += "\"CIDADE\": \""+ cidade + "\""
    }
    else
    {
      setError("É necessário informar o endereço. Aperte na lupa!")

      document.getElementById("cep_Usuario") &&
        document.getElementById("cep_Usuario").focus()

      return;
    }

    if (uf !== "")
    {
      if (jsonUsuario !== "{ ") jsonUsuario += ", "
      jsonUsuario += "\"UF\": \""+ uf + "\""
    }
    else
    {
      setError("É necessário informar o endereço. Aperte na lupa!")

      document.getElementById("cep_Usuario") &&
        document.getElementById("cep_Usuario").focus()

      return;
    }

    if (coordenadas !== "")
    {
      if (jsonUsuario !== "{ ") jsonUsuario += ", "
      jsonUsuario += "\"COORDENADAS\": \""+ coordenadas + "\""
    }

    if (caminhoFoto !== "")
    {
      if (jsonUsuario !== "{ ") jsonUsuario += ", "
      jsonUsuario += "\"CAMINHO_FOTO\": \""+ caminhoFoto + "\""
    }

    if (jsonUsuario !== "{ ") jsonUsuario += ", "
    jsonUsuario += "\"ALTERACAO_IMAGEM\": \""+ alteracaoImagem + "\""

    jsonUsuario += " }"

    if (codigo === undefined){            
      setLoading("Aguarde incluindo o usuário...")

      api.post("USUARIO/Incluir?jsonLogin=" + jsonLogin + "&jsonUsuario=" + jsonUsuario)
      .then((response) => { retornoJson = JSON.parse(response.data) })
      .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível incluir o usuário ("+ response +")\"}") })
      .finally(() => {
        if (retornoJson.CONCRETIZADO === false){
          setLoading("")
          setError(retornoJson.ERRO)
          return
        }
        else setSelecionar(JSON.parse(retornoJson.RESULTADO))
      })                    
    }
    else{            
      setLoading("Aguarde alterando o usuário...")

      api.put("USUARIO/Alterar?jsonLogin=" + jsonLogin + 
        "&jsonUsuarioAntigo="+ jsonUsuario_Antigo + "&jsonUsuarioNovo="+ jsonUsuario)
      .then((response) => { retornoJson = JSON.parse(response.data) })
      .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível alterar o usuário ("+ response +")\"}") })
      .finally(() => {
        if (retornoJson.CONCRETIZADO === false){
          setLoading("")
          setError(retornoJson.ERRO)
          return
        }
        else setSelecionar(JSON.parse(retornoJson.RESULTADO))
      })
    }
  }

  return (
    <div id="main_Usuario">
      <div id="cabecalho_Usuario">
        <div id="cabecalhoMenu_Usuario">
          <img src={Img_menu} alt="Menu" onClick={ (codigo !== "") && (() => chamarMenu()) } />
        </div>
        <div id="cabecalhoTitulo_Usuario">
          <label id="titulo_Usuario">CADASTRO</label><label id="tituloSub_Usuario">USUÁRIO</label>
        </div>
        <div id="cabecalhoLogo_Usuario">
          <img src={Img_logo_topo} alt="Logo" />
        </div>
      </div>
      <div id="traco_Usuario"/>

      { loading ?
          <>
            <div id="containerCarregando_Usuario">
              <Spinner animation="border" variant="info" />
              <label id="textCarregando_Usuario">{loading}</label>
            </div>
          </>
        :
          <div id="container_Usuario">
            { (codigo !== undefined) &&
              <div id="containerFoto_Usuario">
                <Input 
                  type="file"
                  name="arquivo_Usuario"
                  title=""
                  maxLength={255}
                  typeImage={1}
                  disabled={false}
                  placeholder=""
                  grow={1}
                  accept=".jpg,.jpeg,.png,.bmp"
                  pathPhoto={caminhoFoto}
                  onChange={handleArquivo} />
              </div>
            }

            { (width < 261) &&
              <div className="div_Usuario">
                <div className="grow0_Usuario">
                  <InputMasked
                    title="CPF/CNPJ:"
                    name="cpfCnpj_Usuario"
                    mask="99999999999999"
                    disabled={codigo === undefined ? false : true}
                    typeImage={0}
                    placeholder=""
                    grow={1}
                    withBorder={true}
                    value={cpfCnpj}
                    onChange={(event) => setCpfCnpj(event.target.value)} />
                </div>

                <div className="grow0_Usuario">
                  <Input
                    type="text"
                    name="senha_Usuario"
                    title="Senha:"
                    maxLength={8}
                    typeImage={0}
                    disabled={false}
                    placeholder=""
                    grow={1}      
                    withBorder={true}              
                    value={senha}
                    onChange={(event) => setSenha(event.target.value)} />
                </div>

                <div className="grow0_Usuario">
                  <Input
                    type="text"
                    name="nome_Usuario"
                    title="Nome:"
                    maxLength={120}
                    typeImage={0}
                    disabled={false}
                    placeholder=""
                    grow={1}
                    withBorder={true}
                    value={nome}
                    onChange={(event) => setNome(event.target.value)} />
                </div>

                <div className="grow0_Usuario">
                  <Input
                    type="text"
                    name="email_Usuario"
                    title="Email:"
                    maxLength={50}
                    typeImage={0}
                    disabled={false}
                    placeholder=""
                    grow={1}
                    withBorder={true}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)} />
                </div>

                <div className="grow0_Usuario">
                  <InputMasked 
                    title="Telefone:"
                    name="telefone_Usuario"
                    mask="(99)999999999"
                    disabled={false}
                    typeImage={0}
                    placeholder=""
                    withBorder={true}
                    value={telefone || ""}
                    onChange={(event) => setTelefone(event.target.value)} />
                </div>

                <div className="grow0_Usuario">
                  <Select
                    title="WhatsApp:"
                    name="whatsapp_Usuario"
                    options={JSON.parse("{ \"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\" }, {\"ID\": 1, \"VALOR\": \"Sim\" }, {\"ID\": 2, \"VALOR\": \"Não\" }] }")}                        
                    disabled={false}
                    value={whatsapp || 0}
                    onChange={setWhatsapp} />
                </div>

                <div className="grow0_Usuario">
                  <div className="grow1_Usuario">
                    <InputMasked
                      title="CEP:"
                      name="cep_Usuario"
                      mask="99999999"
                      disabled={ cepSelecionado ? true : false }
                      typeImage={2}
                      placeholder=""
                      withBorder={true}
                      value={cep}
                      onChange={(event) => setCep(event.target.value)}
                      setImagem={ cepSelecionado ? () => alert("CEP já foi selecionado!") : buscaCEP} 
                      setImagemExclusion={limparCep} />
                  </div>
                </div>

                <div className="grow0_Usuario">
                  { (!liberarEndereco) ?
                    <Input
                      type="text"
                      name="logradouro_Usuario"
                      title="Logradouro:"
                      maxLength={120}
                      typeImage={0}
                      disabled={true}
                      placeholder=""
                      grow={1}
                      withBorder={true}
                      value={logradouro} />
                    :
                    <Input
                      type="text"
                      name="logradouro_Usuario"
                      title="Logradouro:"
                      maxLength={120}
                      typeImage={0}
                      disabled={false}
                      placeholder=""
                      grow={1}
                      withBorder={true}
                      value={logradouro} 
                      onChange={(event) => setLogradouro(event.target.value)} />
                  }
                </div>

                <div className="grow0_Usuario">
                  <InputMasked
                    title="Número:"
                    name="numero_Usuario"
                    mask={[/\d/,/\d/,/\d/,/\d/,/\d/]}
                    disabled={false}
                    typeImage={0}
                    placeholder=""
                    value={numero}
                    withBorder={true}
                    onChange={(event) => setNumero(event.target.value)} />
                </div>

                <div className="grow0_Usuario">
                  <Input
                    type="text"
                    name="complemento_Usuario"
                    title="Complemento:"
                    maxLength={60}
                    typeImage={0}
                    disabled={false}
                    placeholder=""
                    grow={1}
                    withBorder={true}
                    value={complemento} 
                    onChange={(event) => setComplemento(event.target.value)} />
                </div>

                <div className="grow0_Usuario">
                  { (!liberarEndereco) ?
                    <Input
                      type="text"
                      name="bairro_Usuario"
                      title="Bairro:"
                      maxLength={60}
                      typeImage={0}
                      disabled={true}
                      placeholder=""
                      grow={1}
                      value={bairro} />
                    :
                    <Input
                      type="text"
                      name="bairro_Usuario"
                      title="Bairro:"
                      maxLength={60}
                      typeImage={0}
                      disabled={false}
                      placeholder=""
                      grow={1}
                      value={bairro} 
                      onChange={(event) => setBairro(event.target.value)} />
                  }
                </div>

                <div className="grow0_Usuario">
                  { (!liberarEndereco) ?
                    <Input
                      type="text"
                      name="cidade_Usuario"
                      title="Cidade:"
                      maxLength={60}
                      typeImage={0}
                      disabled={true}
                      placeholder=""
                      grow={1}
                      value={cidade} />
                    :
                    <Input
                      type="text"
                      name="cidade_Usuario"
                      title="Cidade:"
                      maxLength={60}
                      typeImage={0}
                      disabled={false}
                      placeholder=""
                      grow={1}
                      value={bairro} 
                      onChange={(event) => setCidade(event.target.value)} />
                  }
                </div>

                <div className="grow0_Usuario">
                  <Input
                    type="text"
                    name="estado_Usuario"
                    title="Estado:"
                    maxLength={4}
                    typeImage={0}
                    disabled={true}
                    placeholder=""
                    value={uf} />
                </div>
              </div>
            }

            { ((width >= 261) && (width < 420)) &&
              <div className="div_Usuario">
                <div className="grow0_Usuario">
                  <div className="grow1_Usuario">
                    <InputMasked
                      title="CPF/CNPJ:"
                      name="cpfCnpj_Usuario"
                      mask="99999999999999"
                      disabled={codigo === undefined ? false : true}
                      typeImage={0}
                      placeholder=""
                      grow={1}
                      value={cpfCnpj}
                      onChange={(event) => setCpfCnpj(event.target.value)} />
                  </div>
                  <div className="grow1_espaco_Usuario">
                    <Input
                      type="text"
                      name="senha_Usuario"
                      title="Senha:"
                      maxLength={8}
                      typeImage={0}
                      disabled={false}
                      placeholder=""
                      grow={1}                    
                      value={senha}
                      onChange={(event) => setSenha(event.target.value)} />
                  </div>
                </div>

                <div className="grow0_Usuario">
                  <Input
                    type="text"
                    name="nome_Usuario"
                    title="Nome:"
                    maxLength={120}
                    typeImage={0}
                    disabled={false}
                    placeholder=""
                    grow={1}
                    value={nome}
                    onChange={(event) => setNome(event.target.value)} />
                </div>

                <div className="grow0_Usuario">
                  <Input
                    type="text"
                    name="email_Usuario"
                    title="Email:"
                    maxLength={50}
                    typeImage={0}
                    disabled={false}
                    placeholder=""
                    grow={1}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)} />
                </div>

                <div className="grow0_Usuario">
                  <div className="grow1_Usuario">
                    <InputMasked 
                      title="Telefone:"
                      name="telefone_Usuario"
                      mask="(99)999999999"
                      disabled={false}
                      typeImage={0}
                      placeholder=""
                      value={telefone || ""}
                      onChange={(event) => setTelefone(event.target.value)} />
                  </div>
                  <div className="grow1_espaco_Usuario">
                    <Select
                        title="WhatsApp:"
                        name="whatsapp_Usuario"
                        options={JSON.parse("{ \"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\" }, {\"ID\": 1, \"VALOR\": \"Sim\" }, {\"ID\": 2, \"VALOR\": \"Não\" }] }")}                        
                        disabled={false}
                        value={whatsapp || 0}
                        onChange={setWhatsapp} />
                  </div>
                </div>

                <div className="grow0_Usuario">
                  <div className="grow1_Usuario">
                    <InputMasked
                      title="CEP:"
                      name="cep_Usuario"
                      mask={[/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/]}
                      disabled={false}
                      typeImage={2}
                      placeholder=""
                      value={cep}
                      onChange={(event) => setCep(event.target.value)}
                      setImagem={buscaCEP} 
                      setImagemExclusion={limparCep} />
                  </div>
                </div>

                <div className="grow0_Usuario">
                  { (!liberarEndereco) ?
                    <Input
                      type="text"
                      name="logradouro_Usuario"
                      title="Logradouro:"
                      maxLength={120}
                      typeImage={0}
                      disabled={true}
                      placeholder=""
                      grow={1}
                      value={logradouro} />
                    :
                    <Input
                      type="text"
                      name="logradouro_Usuario"
                      title="Logradouro:"
                      maxLength={120}
                      typeImage={0}
                      disabled={false}
                      placeholder=""
                      grow={1}
                      value={logradouro} 
                      onChange={(event) => setLogradouro(event.target.value)} />
                  }
                </div>

                <div className="grow0_Usuario">
                  <div className="grow1_Usuario">
                    <InputMasked
                      title="Número:"
                      name="numero_Usuario"
                      mask={[/\d/,/\d/,/\d/,/\d/,/\d/]}
                      disabled={false}
                      typeImage={0}
                      placeholder=""
                      value={numero}
                      onChange={(event) => setNumero(event.target.value)} />
                  </div>
                  <div className="grow1_espaco_Usuario">
                    <Input
                      type="text"
                      name="complemento_Usuario"
                      title="Complemento:"
                      maxLength={60}
                      typeImage={0}
                      disabled={false}
                      placeholder=""
                      grow={1}
                      value={complemento} 
                      onChange={(event) => setComplemento(event.target.value)} />
                  </div>
                </div>

                <div className="grow0_Usuario">
                  { (!liberarEndereco) ?
                    <Input
                      type="text"
                      name="bairro_Usuario"
                      title="Bairro:"
                      maxLength={60}
                      typeImage={0}
                      disabled={true}
                      placeholder=""
                      grow={1}
                      value={bairro} />
                    :
                    <Input
                      type="text"
                      name="bairro_Usuario"
                      title="Bairro:"
                      maxLength={60}
                      typeImage={0}
                      disabled={false}
                      placeholder=""
                      grow={1}
                      value={bairro} 
                      onChange={(event) => setBairro(event.target.value)} />
                  }
                </div>

                <div className="grow0_Usuario">
                  <div className="grow1_Usuario">
                    { (!liberarEndereco) ?
                      <Input
                        type="text"
                        name="cidade_Usuario"
                        title="Cidade:"
                        maxLength={60}
                        typeImage={0}
                        disabled={true}
                        placeholder=""
                        grow={1}
                        value={cidade} />
                      :
                      <Input
                        type="text"
                        name="cidade_Usuario"
                        title="Cidade:"
                        maxLength={60}
                        typeImage={0}
                        disabled={false}
                        placeholder=""
                        grow={1}
                        value={cidade} 
                        onChange={(event) => setCidade(event.target.value)} />
                    }
                  </div>
                  <div className="grow1_espaco_Usuario">
                    <Input
                      type="text"
                      name="estado_Usuario"
                      title="Estado:"
                      maxLength={4}
                      typeImage={0}
                      disabled={true}
                      placeholder=""
                      value={uf} />
                  </div>
                </div>
              </div>
            }

            { ((width >= 420) && (width < 600)) &&
              <div className="div_Usuario">
                <div className="grow0_Usuario">
                  <div className="grow1_Usuario">
                    <InputMasked
                      title="CPF/CNPJ:"
                      name="cpfCnpj_Usuario"
                      mask="99999999999999"
                      disabled={codigo === undefined ? false : true}
                      typeImage={0}
                      placeholder=""
                      grow={1}
                      value={cpfCnpj}
                      onChange={(event) => setCpfCnpj(event.target.value)} />
                  </div>
                  <div className="grow1_espaco_Usuario">
                    <Input
                      type="text"
                      name="senha_Usuario"
                      title="Senha:"
                      maxLength={8}
                      typeImage={0}
                      disabled={false}
                      placeholder=""
                      grow={1}                    
                      value={senha}
                      onChange={(event) => setSenha(event.target.value)} />
                  </div>
                </div>

                <div className="grow0_Usuario">
                  <Input
                    type="text"
                    name="nome_Usuario"
                    title="Nome:"
                    maxLength={120}
                    typeImage={0}
                    disabled={false}
                    placeholder=""
                    grow={1}
                    value={nome}
                    onChange={(event) => setNome(event.target.value)} />
                </div>

                <div className="grow0_Usuario">
                  <Input
                    type="text"
                    name="email_Usuario"
                    title="Email:"
                    maxLength={50}
                    typeImage={0}
                    disabled={false}
                    placeholder=""
                    grow={1}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)} />
                </div>

                <div className="grow0_Usuario">
                  <div className="grow1_Usuario">
                    <InputMasked 
                      title="Telefone:"
                      name="telefone_Usuario"
                      mask="(99)999999999"
                      disabled={false}
                      typeImage={0}
                      placeholder=""
                      value={telefone || ""}
                      onChange={(event) => setTelefone(event.target.value)} />
                  </div>

                  <div className="grow1_espaco_Usuario">
                    <Select
                        title="WhatsApp:"
                        name="whatsapp_Usuario"
                        options={JSON.parse("{ \"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\" }, {\"ID\": 1, \"VALOR\": \"Sim\" }, {\"ID\": 2, \"VALOR\": \"Não\" }] }")}                        
                        disabled={false}
                        value={whatsapp || 0}
                        onChange={setWhatsapp} />
                  </div>
                </div>

                <div className="grow0_Usuario">
                  <div className="grow1_Usuario">
                    <InputMasked
                      title="CEP:"
                      name="cep_Usuario"
                      mask={[/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/]}
                      disabled={false}
                      typeImage={2}
                      placeholder=""
                      value={cep}
                      onChange={(event) => setCep(event.target.value)}
                      setImagem={buscaCEP}
                      setImagemExclusion={limparCep} />
                  </div>
                </div>

                <div className="grow0_Usuario">
                  { (!liberarEndereco) ?
                    <Input
                      type="text"
                      name="logradouro_Usuario"
                      title="Logradouro:"
                      maxLength={120}
                      typeImage={0}
                      disabled={true}
                      placeholder=""
                      grow={1}
                      value={logradouro} />
                    :
                    <Input
                      type="text"
                      name="logradouro_Usuario"
                      title="Logradouro:"
                      maxLength={120}
                      typeImage={0}
                      disabled={false}
                      placeholder=""
                      grow={1}
                      value={logradouro} 
                      onChange={(event) => setLogradouro(event.target.value)} />
                  }
                </div>

                <div className="grow0_Usuario">
                  <div className="grow1_Usuario">
                    <InputMasked
                      title="Número:"
                      name="numero_Usuario"
                      mask={[/\d/,/\d/,/\d/,/\d/,/\d/]}
                      disabled={false}
                      typeImage={0}
                      placeholder=""
                      value={numero}
                      onChange={(event) => setNumero(event.target.value)} />
                  </div>
                  <div className="grow1_espaco_Usuario">
                    <Input
                      type="text"
                      name="complemento_Usuario"
                      title="Complemento:"
                      maxLength={60}
                      typeImage={0}
                      disabled={false}
                      placeholder=""
                      grow={1}
                      value={complemento} 
                      onChange={(event) => setComplemento(event.target.value)} />
                  </div>
                </div>

                <div className="grow0_Usuario">
                  { (!liberarEndereco) ?
                    <Input
                      type="text"
                      name="bairro_Usuario"
                      title="Bairro:"
                      maxLength={60}
                      typeImage={0}
                      disabled={true}
                      placeholder=""
                      grow={1}
                      value={bairro} />
                    :
                    <Input
                      type="text"
                      name="bairro_Usuario"
                      title="Bairro:"
                      maxLength={60}
                      typeImage={0}
                      disabled={false}
                      placeholder=""
                      grow={1}
                      value={bairro} 
                      onChange={(event) => setBairro(event.target.value)} />
                  }
                </div>

                <div className="grow0_Usuario">
                  <div className="grow1_Usuario">
                    { (!liberarEndereco) ?
                      <Input
                        type="text"
                        name="cidade_Usuario"
                        title="Cidade:"
                        maxLength={60}
                        typeImage={0}
                        disabled={true}
                        placeholder=""
                        grow={1}
                        value={cidade} />
                      :
                      <Input
                        type="text"
                        name="cidade_Usuario"
                        title="Cidade:"
                        maxLength={60}
                        typeImage={0}
                        disabled={false}
                        placeholder=""
                        grow={1}
                        value={cidade} 
                        onChange={(event) => setCidade(event.target.value)} />
                    }
                  </div>
                  <div className="grow1_espaco_Usuario">
                    <Input
                      type="text"
                      name="estado_Usuario"
                      title="Estado:"
                      maxLength={4}
                      typeImage={0}
                      disabled={true}
                      placeholder=""
                      value={uf} />
                  </div>
                </div>                  
              </div>
            }

            { (width >= 600) &&
              <div className="div_Usuario">
                <div className="grow0_Usuario">
                  <div className="grow1_Usuario">
                    <InputMasked
                      title="CPF/CNPJ:"
                      name="cpfCnpj_Usuario"
                      mask="99999999999999"
                      disabled={codigo === undefined ? false : true}
                      typeImage={0}
                      placeholder=""
                      grow={1}
                      value={cpfCnpj}
                      onChange={(event) => setCpfCnpj(event.target.value)} />
                  </div>
                  <div className="grow1_espaco_Usuario">
                    <Input
                      type="text"
                      name="senha_Usuario"
                      title="Senha:"
                      maxLength={8}
                      typeImage={0}
                      disabled={false}
                      placeholder=""
                      grow={1}                    
                      value={senha}
                      onChange={(event) => setSenha(event.target.value)} />
                  </div>
                </div>

                <div className="grow0_Usuario">
                  <Input
                    type="text"
                    name="nome_Usuario"
                    title="Nome:"
                    maxLength={120}
                    typeImage={0}
                    disabled={false}
                    placeholder=""
                    grow={1}
                    value={nome}
                    onChange={(event) => setNome(event.target.value)} />
                </div>

                <div className="grow0_Usuario">
                  <div className="grow1_Usuario">
                    <Input
                      type="text"
                      name="email_Usuario"
                      title="Email:"
                      maxLength={50}
                      typeImage={0}
                      disabled={false}
                      placeholder=""
                      grow={1}
                      value={email}
                      onChange={(event) => setEmail(event.target.value)} />
                  </div>
                  
                  <div className="grow1_espaco_Usuario">
                    <InputMasked 
                      title="Telefone:"
                      name="telefone_Usuario"
                      mask="(99)999999999"
                      disabled={false}
                      typeImage={0}
                      placeholder=""
                      value={telefone || ""}
                      onChange={(event) => setTelefone(event.target.value)} />
                  </div>

                  <div className="grow1_espaco_Usuario">
                    <Select
                        title="WhatsApp:"
                        name="whatsapp_Usuario"
                        options={JSON.parse("{ \"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\" }, {\"ID\": 1, \"VALOR\": \"Sim\" }, {\"ID\": 2, \"VALOR\": \"Não\" }] }")}                        
                        disabled={false}
                        value={whatsapp || 0}
                        onChange={setWhatsapp} />
                  </div>
                </div>

                <div className="grow0_Usuario">
                  <div className="grow0_Usuario">
                    <InputMasked
                      title="CEP:"
                      name="cep_Usuario"
                      mask={[/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/]}
                      disabled={false}
                      typeImage={2}
                      placeholder=""
                      value={cep}
                      onChange={(event) => setCep(event.target.value)}
                      setImagem={buscaCEP} 
                      setImagemExclusion={limparCep} />
                  </div>
                  <div className="grow1_espaco_Usuario">
                    { (!liberarEndereco) ?
                      <Input
                        type="text"
                        name="logradouro_Usuario"
                        title="Logradouro:"
                        maxLength={120}
                        typeImage={0}
                        disabled={true}
                        placeholder=""
                        grow={1}
                        value={logradouro} />
                      :
                      <Input
                        type="text"
                        name="logradouro_Usuario"
                        title="Logradouro:"
                        maxLength={120}
                        typeImage={0}
                        disabled={false}
                        placeholder=""
                        grow={1}
                        value={logradouro} 
                        onChange={(event) => setLogradouro(event.target.value)} />
                    }
                  </div>
                </div>

                <div className="grow0_Usuario">
                  <div className="grow1_Usuario">
                    <InputMasked
                      title="Número:"
                      name="numero_Usuario"
                      mask={[/\d/,/\d/,/\d/,/\d/,/\d/]}
                      disabled={false}
                      typeImage={0}
                      placeholder=""
                      value={numero}
                      onChange={(event) => setNumero(event.target.value)} />
                  </div>
                  <div className="grow1_espaco_Usuario">
                    <Input
                      type="text"
                      name="complemento_Usuario"
                      title="Complemento:"
                      maxLength={60}
                      typeImage={0}
                      disabled={false}
                      placeholder=""
                      grow={1}
                      value={complemento} 
                      onChange={(event) => setComplemento(event.target.value)} />
                  </div>
                </div>

                <div className="grow0_Usuario">
                  <div className="grow1_Usuario">
                    { (!liberarEndereco) ?
                      <Input
                        type="text"
                        name="bairro_Usuario"
                        title="Bairro:"
                        maxLength={60}
                        typeImage={0}
                        disabled={true}
                        placeholder=""
                        grow={1}
                        value={bairro} />
                      :
                      <Input
                        type="text"
                        name="bairro_Usuario"
                        title="Bairro:"
                        maxLength={60}
                        typeImage={0}
                        disabled={false}
                        placeholder=""
                        grow={1}
                        value={bairro} 
                        onChange={(event) => setBairro(event.target.value)} />
                    }
                  </div>
                  <div className="grow1_espaco_Usuario">
                    <Input
                      type="text"
                      name="cidade_Usuario"
                      title="Cidade:"
                      maxLength={60}
                      typeImage={0}
                      disabled={true}
                      placeholder=""
                      value={cidade} />
                  </div>
                  <div className="grow1_espaco_Usuario">
                    <Input
                      type="text"
                      name="estado_Usuario"
                      title="Estado:"
                      maxLength={4}
                      typeImage={0}
                      disabled={true}
                      placeholder=""
                      value={uf} />
                  </div>
                </div>
              </div>
            }

            { error &&
              <div id="mensagem_Usuario">
                <label id="menssageError_Usuario">{error}</label>
              </div>
            }

            <div id="botoes_Usuario">
              { codigo === undefined &&
                <div className="containerBotao_Usuario">
                  <img src={Img_progredir} className="botao_Usuario" alt="progredir" onClick={setVoltarNovo} />
                </div>
              }

              { codigo !== undefined &&
                <div className="containerBotao_Usuario">
                  <img src={Img_progredir} className="botao_Usuario" alt="progredir" onClick={setVoltar} />
                </div>
              }

              <img src={Img_salvar} className="botao_Usuario" alt="salvar" onClick={salvar} />
            </div>
          </div>
      }
    </div>
  )
})

export default Usuario
    
{/* 
  { codigo !== undefined &&
      <div id="usuario-pagamento">
          <br/>
          <h5 id="usuario-pagamento-titulo">Pagamentos</h5>

          <Table striped hover width='99%'>
              <thead>
                  <tr>
                      <th>Data</th>
                      <th>Valor</th>
                      <th>Dt. validade</th>
                      <th>Tipo Pagto.</th>
                      <th>Nr. transação</th>
                  </tr>
              </thead>
              <tbody>
                  { usuario.Lst_PAGAMENTO && usuario.Lst_PAGAMENTO.map(item => (
                      <tr key={"trUsuario_Resultado"+ item.ID}>
                          <td width="20%">
                              { String(item.DATA).substring(8,10) + "/" + 
                                  String(item.DATA).substring(5,7) + "/" + 
                                      String(item.DATA).substring(0,4) }
                          </td>
                          <td width="20%">{ formatNumeric(item.VALOR, 2) }</td>
                          <td width="16%">
                              { String(item.DATA_FINAL).substring(8,10) + "/" + 
                                  String(item.DATA_FINAL).substring(5,7) + "/" + 
                                      String(item.DATA_FINAL).substring(0,4) }
                          </td>
                          <td width="20%">
                              { (item.TIPO === 1) ? "Cartão" : (item.TIPO === 2) ? "PIX" : "Experiência" }
                          </td>
                          <td width="24%">{item.NUMERO_TRANSACAO}</td>
                      </tr>
                      ))
                  }

                  { usuario.Lst_PAGAMENTO.length === 0 &&
                      <tr>
                          <td colSpan="5">Nenhum registro encontrado.</td>
                      </tr>
                  }
              </tbody>
          </Table>
      </div>
  } 
*/}