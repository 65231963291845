import "./style.css"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { ReactComponent as Arrow } from "../../Assets/Img/arrow.svg"
import Input from "../../Components/Input"
import InputNumeric from "../../Components/InputNumeric"
import InputMasked from "../../Components/InputMasked"
import Select from "../../Components/Select"
import Spinner from 'react-bootstrap/Spinner'
import Img_estender from "../../Assets/Img/estender.png"
import Img_filtro_branco from "../../Assets/Img/filtro_branco.png"
import Img_logo_topo from "../../Assets/Img/logo_topo_colorida.png"
import Img_menu from "../../Assets/Img/Botoes/menu.png"
import Img_progredir from "../../Assets/Img/Botoes/progredir.png"
import Img_recolher from "../../Assets/Img/recolher.png"
import Img_salvar from "../../Assets/Img/Botoes/salvar.png"
import Img_star_all from "../../Assets/Img/star_all.png"
import Img_star_half from "../../Assets/Img/star_half.png"
import Img_star_empty from "../../Assets/Img/star_empty.png"

const api = axios.create({
  baseURL: "https://api.brconnect.com.br/api/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json;charset=utf-8"
  }
})

const ServicoContratado = ({ usuario, categorias, subCategorias, chamarMenu, chamarListaServicos, width }) => {
  const [avaliacao, setAvaliacao] = useState(0)
  const [codigo, setCodigo] = useState("")
  const [data, setData] = useState("")
  const [dataFiltro, setDataFiltro] = useState("")
  const [descricao, setDescricao] = useState("")
  const [descricaoServico, setDescricaoServico] = useState("")
  const [codigoCategoria, setCodigoCategoria] = useState("")
  const [codigoSubCategoria, setCodigoSubCategoria] = useState("")
  const [nomePrestador, setNomePrestador] = useState("")
  const [nomePrestadorFiltro, setNomePrestadorFiltro] = useState("")
  const [servico, setServico] = useState({})
  const [servicoAntigo, setServicoAntigo] = useState("")
  const [servicosExecutados, setServicosExecutados] = useState([])
  const [servicosSelecionados, setServicosSelecionados] = useState([])
  const [valor, setValor] = useState(0)
  const [valorFiltro, setValorFiltro] = useState(0)

  // 1 - Pesquisa , 2 - Serviço
  const [processo, setProcesso] = useState(1)

  const [mostrarFiltro, setMostrarFiltro] = useState(false)
  const [mostrarResultado, setMostrarResultado] = useState(false)

  const optionsRegPorPagina = "{\"RESULTADO\": [{\"ID\": 12, \"VALOR\": \"12\"}, {\"ID\": 24, \"VALOR\": \"24\"}, {\"ID\": 36, \"VALOR\": \"36\" }, {\"ID\": 100, \"VALOR\": \"Todos\" }]}"
  const [optionsCategoria, setOptionsCategoria] = useState("{\"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\"}]}")
  const [optionsSubCategoria, setOptionsSubCategoria] = useState("{\"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\"}]}")
  const [primeiroAcesso, setPrimeiroAcesso] = useState(true)

  const [paginaAtual, setPaginaAtual] = useState(1)
  const [regPorPagina, setRegPorPagina] = useState(12)
  const [totalRegistro, setTotalRegistro] = useState(0)
  const [totalPagina, setTotalPagina] = useState(0)

  const [carregando, setCarregando] = useState(null)
  const [mensagem, setMensagem] = useState("")
  const [mensagemErro, setMensagemErro] = useState("")

  useEffect(() => {
    if (primeiroAcesso) {
      // Preenche categorias
      var categoria = "{\"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\"}"
      categorias.map(item => ( 
        categoria += ",{\"ID\": "+ item.ID +", \"VALOR\": \""+ item.DESCRICAO +"\"}"
      ))
      categoria += "]}"
      setOptionsCategoria(categoria)

      // Preenche serviços prestados
      setCarregando("Buscando os serviços prestados, Aguarde...")

      var retornoJsonSP = []
      var jsonLoginSP = "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ + "\"" +
      ", \"SENHA\": \""+ usuario.SENHA +"\", \"COORDENADAS\": \""+ usuario.COORDENADAS +"\" }"

      var jsonServicoExecutado = "{ \"USUARIO\": { \"ID\": "+ usuario.ID +" } }"

      api.get("SERVICO_EXECUTADO/TotalRegistros_Consultar?jsonLogin=" + jsonLoginSP + "&jsonServicoExecutado=" + jsonServicoExecutado)
      .then((response) => { retornoJsonSP = JSON.parse(response.data) })
      .catch((response) => { retornoJsonSP = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível pesquisar a quantidade de serviços contratados ("+ response +")\"}") })
      .finally(() => {
        if (retornoJsonSP.CONCRETIZADO === false){
          setCarregando("")
          setMensagem("")
          setMensagemErro(retornoJsonSP.ERRO)
          setMostrarResultado(false)
          return
        }
        else
        {
          var tRegistro = retornoJsonSP.RESULTADO
          if (tRegistro === 0)
          {
            setCarregando("")
            setMensagem("")
            setMensagemErro("Não há nenhum serviço contratado.")
            setMostrarResultado(false)
            return
          }

          if (optionsRegPorPagina === 100)
          {
            api.get("SERVICO_EXECUTADO/Consultar?jsonLogin=" + jsonLoginSP + "&jsonServicoExecutado=" + jsonServicoExecutado +
              "&iQtdeInicial=0&iQtdeRegistros=" + tRegistro)
            .then((response) => { retornoJsonSP = JSON.parse(response.data) })
            .catch((response) => { retornoJsonSP = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível pesquisar os serviços contratados ("+ response +")\"}") })
            .finally(() => {
              if (retornoJsonSP.CONCRETIZADO === false){
                setCarregando("")
                setMensagem("")
                setMensagemErro(retornoJsonSP.ERRO)
                setMostrarResultado(false)
                return
              }
              else
              {
                setMensagem("")
                setMensagemErro("")
                setServicosExecutados(retornoJsonSP.RESULTADO)
                setServicosSelecionados(retornoJsonSP.RESULTADO)
                setMostrarResultado(true)
              }
              setCarregando("")
            })

            setTotalRegistro(tRegistro)
            setTotalPagina(1)
            if (paginaAtual !== 1) setPaginaAtual(1)
          }
          else
          {
            var operacao = tRegistro / regPorPagina
            var numero = Math.floor(operacao)
            var decimal = operacao % 1

            var tPagina = 0
            if (decimal > 0) tPagina = numero + 1
            else tPagina = numero
            
            setTotalRegistro(tRegistro)
            setTotalPagina(tPagina)
            if (tPagina < paginaAtual) setPaginaAtual(1)

            var qtdeInicial = 0
            if (paginaAtual > 1) qtdeInicial = ((paginaAtual - 1) * regPorPagina)

            api.get("SERVICO_EXECUTADO/Consultar?jsonLogin=" + jsonLoginSP + "&jsonServicoExecutado=" + jsonServicoExecutado + 
              "&iQtdeInicial=" + qtdeInicial + "&iQtdeRegistros=" + regPorPagina)
            .then((response) => { retornoJsonSP = JSON.parse(response.data) })
            .catch((response) => { retornoJsonSP = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível pesquisar os serviços contratados ("+ response +")\"}") })
            .finally(() => {
              if (retornoJsonSP.CONCRETIZADO === false){
                setCarregando("")
                setMensagem("")
                setMensagemErro(retornoJsonSP.ERRO)
                setMostrarResultado(false)
                return
              }
              else
              {
                setMensagem("")
                setMensagemErro("")
                setServicosExecutados(retornoJsonSP.RESULTADO)
                setServicosSelecionados(retornoJsonSP.RESULTADO)
                setMostrarResultado(true)
              }
              setCarregando("")
            })
          }
          setMensagem("")
          setMensagemErro("")
        }

        setProcesso(1)
        setCodigoCategoria("")
        setCodigoSubCategoria("")
        setNomePrestadorFiltro("")
        setValorFiltro(0)
        setDataFiltro("")
      })
      setPrimeiroAcesso(false)
    }

    if (processo === 1) {
      var servicosExecutados_1 = []
      if ((codigoCategoria !== "") && (String(codigoCategoria) !== "0")) {
        servicosExecutados.map(item => ( 
          (item.SERVICO.SUB_CATEGORIA.CATEGORIA.ID === parseInt(codigoCategoria)) && 
            servicosExecutados_1.push(item)
        ))
      } else servicosExecutados_1 = servicosExecutados

      var servicosExecutados_2 = []
      if ((codigoSubCategoria !== "") && (String(codigoSubCategoria) !== "0")) {
        servicosExecutados_1.map(item => ( 
          (item.SERVICO.SUB_CATEGORIA.ID === parseInt(codigoSubCategoria)) &&
            servicosExecutados_2.push(item)
        ))
      } else servicosExecutados_2 = servicosExecutados_1

      var servicosExecutados_3 = []
      if (valorFiltro > 0) {
        servicosExecutados_2.map(item => ( 
          (item.VALOR === parseFloat(valorFiltro)) &&
            servicosExecutados_3.push(item)
        ))
      } else servicosExecutados_3 = servicosExecutados_2

      var servicosExecutados_4 = []
      if (validarData(dataFiltro)) {
        servicosExecutados_3.map(item => ( 
          ((String(item.DATA).substring(8,10) + "/" + 
            String(item.DATA).substring(5,7) + "/" + 
              String(item.DATA).substring(0,4)) === String(dataFiltro)) && 
                servicosExecutados_4.push(item)
        ))
      } else servicosExecutados_4 = servicosExecutados_3

      var servicosExecutados_5 = []
      if (nomePrestadorFiltro !== "") {
        servicosExecutados_4.map(item => ( 
          (item.SERVICO.USUARIO.NOME.indexOf(nomePrestadorFiltro.toUpperCase()) !== -1) &&
            servicosExecutados_5.push(item)
        ))
      } else servicosExecutados_5 = servicosExecutados_4

      setServicosSelecionados(servicosExecutados_5)
    }

    if (processo === 2) {
      var jsonServico = "{ \"ID\": "+ servico.ID + 
      ", \"SERVICO\": { \"ID\": "+ servico.SERVICO.ID +" }" +
      ", \"USUARIO\": { \"ID\": "+ servico.USUARIO.ID +" }" +
      ", \"DATA\": \""+ servico.DATA +"\"" +
      ", \"VALOR\": "+ servico.VALOR

      if (servico.AVALIACAO != null) jsonServico += ", \"AVALIACAO\": "+ servico.AVALIACAO
      if (servico.DESCRICAO != null) jsonServico += ", \"DESCRICAO\": \""+ servico.AVALIACAO + "\""

      jsonServico += " }"
      setServicoAntigo(jsonServico)

      setCodigo(servico.ID)
      setNomePrestador(servico.SERVICO.USUARIO.NOME)
      setDescricaoServico(servico.SERVICO.SUB_CATEGORIA.CATEGORIA.DESCRICAO + "-" + servico.SERVICO.SUB_CATEGORIA.DESCRICAO + "-" + servico.SERVICO.DESCRICAO)
      setData(servico.DATA.substring(8,10) + "/" + servico.DATA.substring(5,7) + "/" + servico.DATA.substring(0,4))
      setValor("R$ "+ formatNumeric(servico.VALOR, 2))
      setAvaliacao(servico.AVALIACAO)
      setDescricao(servico.DESCRICAO)
    }
  }, [categorias, codigoCategoria, codigoSubCategoria, dataFiltro, nomePrestadorFiltro, valorFiltro, paginaAtual,
    primeiroAcesso, processo, regPorPagina, servicosExecutados, usuario.COORDENADAS, usuario.CPF_CNPJ,
    usuario.ID, usuario.SENHA, servico])

  const salvar = () => {
    var retornoJson = []
    var jsonLogin = "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ + "\", \"SENHA\": \""+ usuario.SENHA + "\" }"
    var jsonServicoExecutado = "{ \"ID\": " + codigo

    if (avaliacao !== 0)
      jsonServicoExecutado += ", \"AVALIACAO\": "+ avaliacao
    else 
    {
      setMensagemErro("É necessário informar a avaliação!");
      return;
    }

    if (descricao !== "") jsonServicoExecutado += ", \"DESCRICAO\": \""+ descricao + "\""
    else
    {
      setMensagemErro("É necessário informar a descrição da avaliação!");
      return;
    }

    jsonServicoExecutado += " }"

    setMensagem("")
    setMensagemErro("")
    setCarregando("Aguarde avaliando o prestador...")

    api.put("SERVICO_EXECUTADO/Avaliar?jsonLogin=" + jsonLogin + "&jsonServicoExecutadoAntigo=" + servicoAntigo +
      "&jsonServicoExecutadoNovo=" + jsonServicoExecutado)
    .then((response) => { retornoJson = JSON.parse(response.data) })
    .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível incluir o serviço executado ("+ response +")\"}") })
    .finally(() => {
      if (retornoJson.CONCRETIZADO === false){
        setCarregando("")
        setMensagemErro(retornoJson.ERRO)
        return
      }
      else
      {
        setMensagemErro("")
        setCodigo("")
        setNomePrestador("")
        setDescricaoServico("")
        setData("")
        setValor(0)
        setAvaliacao(0)
        setDescricao("")    
        setPrimeiroAcesso(true)
      }
      setCarregando("")
    })
  }

  function formatNumeric(value, decimals) {
    if (!value) {
      return ""
    }

    if (value === ".") {
      return value = "0."
    }
      
    var regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)
    const decimalsNumber = value.toString().match(regex)[0]
    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) {
      return "0"
    }

    return parsed
  }    

  function getFills(score){
    const fills = [0, 0, 0, 0, 0];  
    const integerPart = Math.floor(score);
        
    for (let i = 0; i < integerPart; i++) {
      fills[i] = 1;
    }
        
    const diff = score - integerPart;
    if (diff > 0) {
      fills[integerPart] = 0.5;
    }        
    return fills;
  }

  function paginaAnterior(){
    if (totalRegistro > 0) {
      if (paginaAtual !== 1) setPaginaAtual(paginaAtual - 1)
    }
  }

  function paginaSeguinte(){
    if (totalRegistro > 0) {
      if (paginaAtual < totalPagina) setPaginaAtual(paginaAtual + 1)
    }
  }

  function setCategoria(event) {
    var categoria = event
    var subCategoria = "{\"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\"}"
    subCategorias.map(item => ( 
      (parseInt(categoria) === item.CATEGORIA.ID) ?
        subCategoria += ",{\"ID\": "+ item.ID +", \"VALOR\": \""+ item.DESCRICAO +"\"}" : ""
    ))
    subCategoria += "]}"

    setOptionsSubCategoria(subCategoria)
    setCodigoCategoria(categoria)
  }

  const selecionarServico = (servico) => {
    setServico(servico)
    setProcesso(2)
  }

  function validarData(valor) {
    var erro=false;
    var date=valor;
    var ardt=new Array([]);
    var ExpReg=new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt=date.split("/");

    if ( date.search(ExpReg)===-1) erro = true
    else if (((ardt[1]===4)||(ardt[1]===6)||(ardt[1]===9)||(ardt[1]===11))&&(ardt[0]>30)) erro = true
    else if ( ardt[1]===2) {
      if ((ardt[0]>28)&&((ardt[2]%4)!==0))
        erro = true;
      if ((ardt[0]>29)&&((ardt[2]%4)===0))
        erro = true;
    }

    if (erro) return false;
    return true;
  }

  function voltarAdd() {
    setProcesso(1)
  }

  return(
    <div className="main_ServicoContratado">
      { (carregando !== "") &&
        <div id="carregando_ServicoContratado">
          <Spinner animation="border" variant="info" />
          <label id="carregandoTexto_ServicoContratado">{carregando}</label>
        </div>
      }

      { (carregando === "") &&
        <div className="main_ServicoContratado">
          <div id="cabecalho_ServicoContratado">
            <div id="cabecalhoMenu_ServicoContratado">
              <img src={Img_menu} alt="Menu" onClick={() => chamarMenu()} />
            </div>
            <div id="cabecalhoTitulo_ServicoContratado">
              <label id="titulo_ServicoContratado">SERVIÇOS</label><label id="tituloSub_ServicoContratado">CONTRATADOS</label>
            </div>
            <div id="cabecalhoLogo_ServicoContratado">
              <img src={Img_logo_topo} alt="Logo" />
            </div>
          </div>

          { ((processo === 1 && !mostrarResultado) || (processo === 2)) &&
            <div id="bordarBottom_ServicoContratado" />
          }          
            
          {/* 1 - Pesquisa */}
          { (processo === 1) &&
            <div className="main_ServicoContratado">
              { mostrarResultado &&
                <div className="main_ServicoContratado">
                  { !mostrarFiltro &&
                    <div id="pesquisa_filtroRecolhido_ServicoContratado" onClick={() => setMostrarFiltro(true)}>
                      <div id="pesquisa_filtroRecolhidoFiltro_ServicoContratado">
                        <img src={Img_filtro_branco} id="pesquisa_filtroRecolhidoFiltroImagem_ServicoContratado" alt="Filtro" />
                      </div>
                      <div id="pesquisa_filtroRecolhidoDescricao_ServicoContratado">
                        <img src={Img_estender} id="pesquisa_filtroRecolhidoDescricaoImagem_ServicoContratado" alt="Estender" />
                        <label id="pesquisa_filtroRecolhidoDescricaoTexto_ServicoContratado">Mostrar Filtros</label>
                      </div>
                    </div>
                  }

                  { mostrarFiltro &&
                    <>
                      <div id="pesquisa_filtro_ServicoContratado">
                        <div id="pesquisa_filtroFiltro_ServicoContratado">
                          <img src={Img_filtro_branco} id="pesquisa_filtroFiltroImagem_ServicoContratado" alt="Filtro" />
                        </div>
                        <div className="pesquisa_filtroCampos_ServicoContratado">

                          { width < 700 &&
                            <>
                              <div className="pesquisa_filtroCampo_ServicoContratado marginTop10_ServicoContratado">
                                <Select 
                                  title="Categoria: "
                                  name="categoria_ServicoContratado"
                                  options={JSON.parse(optionsCategoria)}
                                  disabled={false}
                                  value={codigoCategoria}
                                  onChange={setCategoria} />
                              </div>

                              <div className="pesquisa_filtroCampo_ServicoContratado">
                                <Select 
                                  title="Serviço: "
                                  name="subCategoria_ServicoContratado"
                                  options={JSON.parse(optionsSubCategoria)}
                                  disabled={false}
                                  value={codigoSubCategoria}
                                  onChange={setCodigoSubCategoria} />
                              </div>
                            </>
                          }

                          { width >= 700 &&
                            <div className="pesquisa_filtroCampo_ServicoContratado marginTop10_ServicoContratado">
                              <div className="pesquisa_filtroCampoDuplo_ServicoContratado">
                                <Select 
                                  title="Categoria: "
                                  name="categoria_ServicoContratado"
                                  options={JSON.parse(optionsCategoria)}
                                  disabled={false}
                                  value={codigoCategoria}
                                  onChange={setCategoria} />                                
                              </div>
                              <div className="pesquisa_filtroCampoDuplo_ServicoContratado">
                                <Select 
                                  title="Serviço: "
                                  name="subCategoria_ServicoContratado"
                                  options={JSON.parse(optionsSubCategoria)}
                                  disabled={false}
                                  value={codigoSubCategoria}
                                  onChange={setCodigoSubCategoria} />                                
                              </div>
                            </div>
                          }

                          <div className="pesquisa_filtroCampo_ServicoContratado">
                            <Input 
                              type="search"
                              title="Prestador:"
                              name="nomePrestador_ServicoContratado"
                              disabled={false}
                              maxLength={120}
                              placeholder=""
                              typeImage={0}
                              value={nomePrestadorFiltro || ""}
                              onChange={(event) => setNomePrestadorFiltro(event.target.value)} />
                          </div>

                          { width < 350 &&
                            <>
                              <div className="pesquisa_filtroCampo_ServicoContratado">
                                <InputNumeric 
                                  title="Valor"
                                  name="valor_ServicoContratado"
                                  disabled={false}
                                  typeImage={0}
                                  placeholder=""
                                  maxLength={13}
                                  value={ valorFiltro || ''}
                                  onChange={(event) => setValorFiltro(event.target.value)} />
                              </div>

                              <div className="pesquisa_filtroCampo_ServicoContratado">
                                <InputMasked
                                  title="Data"
                                  name="data_ServicoContratado"
                                  mask="99/99/9999"
                                  disabled={false}
                                  typeImage={0}
                                  placeholder=""
                                  value={dataFiltro || ""}
                                  onChange={(event) => setDataFiltro(event.target.value)} />
                              </div>

                              <div className="pesquisa_filtroCampo_ServicoContratado">
                                <Select 
                                  title="Registros: "
                                  name="regProPagina_ServicoContratado"
                                  options={JSON.parse(optionsRegPorPagina)}
                                  disabled={false}
                                  value={regPorPagina}
                                  onChange={setRegPorPagina} />
                              </div>
                            </>
                          }

                          { width >= 350 && width < 700 &&
                            <>
                              <div className="pesquisa_filtroCampo_ServicoContratado">
                                <div className="pesquisa_filtroCampoDuplo_ServicoContratado">
                                  <InputNumeric 
                                    title="Valor"
                                    name="valor_ServicoContratado"
                                    disabled={false}
                                    typeImage={0}
                                    placeholder=""
                                    maxLength={13}
                                    value={ valorFiltro || ''}
                                    onChange={(event) => setValorFiltro(event.target.value)} />
                                </div>
                                <div className="pesquisa_filtroCampoDuplo_ServicoContratado">
                                  <InputMasked
                                    title="Data"
                                    name="data_ServicoContratado"
                                    mask="99/99/9999"
                                    disabled={false}
                                    typeImage={0}
                                    placeholder=""
                                    value={dataFiltro || ""}
                                    onChange={(event) => setDataFiltro(event.target.value)} />
                                </div>
                              </div>

                              <div className="pesquisa_filtroCampo_ServicoContratado">
                                <Select 
                                  title="Registros: "
                                  name="regProPagina_ServicoContratado"
                                  options={JSON.parse(optionsRegPorPagina)}
                                  disabled={false}
                                  value={regPorPagina}
                                  onChange={setRegPorPagina} />
                              </div>
                            </>
                          }

                          { width >= 700 &&
                            <div className="pesquisa_filtroCampo_ServicoContratado">
                              <div className="pesquisa_filtroCampoDuplo_ServicoContratado">
                                <InputNumeric 
                                  title="Valor"
                                  name="valor_ServicoContratado"
                                  disabled={false}
                                  typeImage={0}
                                  placeholder=""
                                  maxLength={13}
                                  value={ valorFiltro || ''}
                                  onChange={(event) => setValorFiltro(event.target.value)} />
                              </div>
                              <div className="pesquisa_filtroCampoDuplo_ServicoContratado">
                                <InputMasked
                                  title="Data"
                                  name="data_ServicoContratado"
                                  mask="99/99/9999"
                                  disabled={false}
                                  typeImage={0}
                                  placeholder=""
                                  value={dataFiltro || ""}
                                  onChange={(event) => setDataFiltro(event.target.value)} />
                              </div>
                              <div className="pesquisa_filtroCampoDuplo_ServicoContratado">
                                <Select 
                                  title="Registros: "
                                  name="regProPagina_ServicoContratado"
                                  options={JSON.parse(optionsRegPorPagina)}
                                  disabled={false}
                                  value={regPorPagina}
                                  onChange={setRegPorPagina} />
                              </div>
                            </div>
                          }

                          <div id="pesquisa_filtroRecolher_ServicoContratado" onClick={() => setMostrarFiltro(false)}>
                            <div id="pesquisa_filtroRecolherView_ServicoContratado">
                              <img src={Img_recolher} id="pesquisa_filtroRecolherImagem_ServicoContratado" alt="Recolher" />
                              <label id="pesquisa_filtroRecolherTexto_ServicoContratado">Recolher Filtros</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }

                  <div id="pesquisa_servicos_ServicoContratado">
                    <div id="pesquisa_paginacao_ServicoContratado">
                      <div id="pesquisa_paginacaoBox_ServicoContratado">
                        <button className="pesquisa_paginacaoBotao_ServicoContratado" disabled={ (paginaAtual === 1) ? true : false } onClick={() => paginaAnterior() } >
                          <Arrow />
                        </button>
                        <p>{`${paginaAtual} de ${totalPagina}`}</p>
                        <button className="pesquisa_paginacaoBotao_ServicoContratado" disabled={ (paginaAtual === totalPagina) ? true : false} onClick={() => paginaSeguinte()} >
                          <Arrow className="pesquisa_flipHorizontal_ServicoContratado" />
                        </button>
                      </div>
                    </div>

                    { servicosSelecionados.map(item => (
                      <div key={"servicoContratado"+ item.ID} className="pesquisa_servico_ServicoContratado">

                        { width < 450 &&
                          <>
                            <div className="pesquisa_servicoIdentificacao_ServicoContratado">
                              <div className="pesquisa_servicoUsuario_ServicoContratado">
                                <label className="pesquisa_servicoUsuarioTexto_ServicoContratado">{item.SERVICO.USUARIO.NOME}</label>
                              </div>
                            </div>

                            { item.AVALIACAO !== 0 &&
                              <div className="pesquisa_containerAvaliacao_ServicoContratado">
                                { (getFills(item.AVALIACAO)[0] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoContratado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[0] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoContratado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoContratado" alt="Meia Estrela" /> }
                                { (getFills(item.AVALIACAO)[1] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoContratado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[1] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoContratado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoContratado" alt="Meia Estrela" /> }
                                { (getFills(item.AVALIACAO)[2] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoContratado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[2] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoContratado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoContratado" alt="Meia Estrela" /> }
                                { (getFills(item.AVALIACAO)[3] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoContratado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[3] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoContratado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoContratado" alt="Meia Estrela" /> }
                                { (getFills(item.AVALIACAO)[4] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoContratado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[4] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoContratado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoContratado" alt="Meia Estrela" /> }                                
                              </div>
                            }

                            <div className="pesquisa_containerAvaliarAvaliado_ServicoContratado">
                              <div className="pesquisa_avaliarAvaliado_ServicoContratado" onClick={() => selecionarServico(item)}>
                                { item.AVALIACAO === 0 && <label>Avaliar</label> }
                                { item.AVALIACAO !== 0 && <label>Re-Avaliar</label> }
                              </div>
                            </div>

                            <div className="pesquisa_containerDescricaoServico_ServicoContratado">
                              <label className="pesquisa_tituloServico_ServicoContratado">{item.SERVICO.SUB_CATEGORIA.CATEGORIA.DESCRICAO} - {item.SERVICO.SUB_CATEGORIA.DESCRICAO} - {item.SERVICO.DESCRICAO}</label>
                            </div>

                            <div className="pesquisa_outrasInformacoes_ServicoContratado">
                              <label>
                                { (item.VALOR === 0) && "Não fornecido" }
                                { (item.VALOR !== 0) && "R$ " + formatNumeric(item.VALOR, 2)}
                              </label>
                              <label>{item.DATA.substring(8,10) + "/" + item.DATA.substring(5,7) + "/" + item.DATA.substring(0,4)}</label>
                            </div>

                            { item.AVALIACAO !== 0 && item.DESCRICAO &&
                              <label>Avaliação: {item.DESCRICAO}</label>
                            }
                          </>
                        }

                        { width >= 450 &&
                          <>
                            <div className="pesquisa_servicoIdentificacao_ServicoContratado">
                              <div className="pesquisa_servicoUsuario_ServicoContratado">
                                <label className="pesquisa_servicoUsuarioTexto_ServicoContratado">{item.SERVICO.USUARIO.NOME}</label>
                              </div>
                              <div className="pesquisa_avaliacao_ServicoContratado">
                                { item.AVALIACAO === 0 && 
                                  <div className="pesquisa_avaliarAvaliado_ServicoContratado" onClick={() => selecionarServico(item)}>
                                    <label>Avaliar</label>
                                  </div>
                                }
                                
                                { item.AVALIACAO !== 0 &&
                                  <div>
                                    { (getFills(item.AVALIACAO)[0] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoContratado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[0] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoContratado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoContratado" alt="Meia Estrela" /> }
                                    { (getFills(item.AVALIACAO)[1] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoContratado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[1] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoContratado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoContratado" alt="Meia Estrela" /> }
                                    { (getFills(item.AVALIACAO)[2] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoContratado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[2] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoContratado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoContratado" alt="Meia Estrela" /> }
                                    { (getFills(item.AVALIACAO)[3] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoContratado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[3] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoContratado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoContratado" alt="Meia Estrela" /> }
                                    { (getFills(item.AVALIACAO)[4] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoContratado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[4] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoContratado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoContratado" alt="Meia Estrela" /> }
                                  </div>
                                }
                              </div>
                            </div>

                            <div className="pesquisa_tituloServico_ServicoContratado">
                              <div className="pesquisa_containerDescricaoServico_ServicoContratado">
                                <label className="pesquisa_tituloServico_ServicoContratado">{item.SERVICO.SUB_CATEGORIA.CATEGORIA.DESCRICAO} - {item.SERVICO.SUB_CATEGORIA.DESCRICAO} - {item.SERVICO.DESCRICAO}</label>
                              </div>
                              
                              { item.AVALIACAO !== 0 &&
                                <div className="pesquisa_avaliarAvaliado_ServicoContratado" onClick={() => selecionarServico(item)}>
                                  <label>Re-Avaliar</label>
                                </div>
                              }
                            </div>
                            <div className="pesquisa_valor_ServicoContratado">
                              <label>
                                { (item.VALOR === 0) && "Não fornecido" }
                                { (item.VALOR !== 0) && "R$ " + formatNumeric(item.VALOR, 2)}
                              </label>
                              <label>{item.DATA.substring(8,10) + "/" + item.DATA.substring(5,7) + "/" + item.DATA.substring(0,4)}</label>
                            </div>

                            { item.AVALIACAO !== 0 && item.DESCRICAO &&
                              <label>Avaliação: {item.DESCRICAO}</label>
                            }
                          </>
                        }
                      </div>
                      ))
                    }

                    { servicosSelecionados.length === 0 &&
                      <div id="pesquisa_sem_ServicoContratado">
                        <label>Nenhum serviço contratado com o filtro informado!</label>
                      </div>
                    }
                  </div>
                </div>
              }

              { (mensagem !== "") &&
                <div id="mensagem_ServicoContratado">
                  <label id="mensagemTexto_ServicoContratado">{mensagem}</label>
                </div>
              }

              { (mensagemErro !== "") &&
                <div id="mensagemErro_ServicoContratado">
                  <label id="mensagemErroTexto_ServicoContratado">{mensagemErro}</label>
                </div>
              }

              <div id="pesquisa_botoes_ServicoContratado">
                <img src={Img_progredir} alt="progredir" className="pesquisa_botao_ServicoContratado" onClick={() => chamarListaServicos()} />
              </div>
            </div>
          }

          {/* 2 - Serviço */}
          { (processo === 2) &&
            <div className="main_ServicoContratado">
              <div id="dados_container_ServicoContratado">
                <div id="dados_addTitulo_ServicoContratado">
                  <label id="dados_addTituloServico_ServicoContratado">AVALIAÇÃO</label><label id="dados_addTituloContratante_ServicoContratado">SERVIÇO</label>
                </div>
                <div id="dados_addTraco_ServicoContratado">
                  <hr id="dados_addTituloTraco_ServicoContratado"/>
                </div>                

                <div className="dados_addColumns_ServicoContratado">
                  <div className="dados_containerTitulo_ServicoContratado">
                    <label className="dados_containerTituloTexto_ServicoContratado">Prestador:</label>
                  </div>
                  <div className="dados_containerCampo_ServicoContratado">
                    <Input 
                      type="text"
                      name="nomePrestador_ServicoContratado"
                      disabled={true}
                      maxLength={120}
                      placeholder=""
                      typeImage={0}
                      value={nomePrestador || ""}
                      onChange={(event) => setNomePrestador(event.target.value)} />
                  </div>
                </div>

                <div className="dados_addColumns_ServicoContratado">
                  <div className="dados_containerTitulo_ServicoContratado">
                    <label className="dados_containerTituloTexto_ServicoContratado">Serviço:</label>
                  </div>
                  <div className="dados_containerCampo_ServicoContratado">
                    <Input 
                      type="text"
                      name="descricaoServico_ServicoContratado"
                      disabled={true}
                      maxLength={120}
                      placeholder=""
                      typeImage={0}
                      value={descricaoServico || ""}
                      onChange={(event) => setDescricaoServico(event.target.value)} />
                  </div>
                </div>

                { width < 420 &&
                  <>
                    <div className="dados_addColumns_ServicoContratado">
                      <div className="dados_containerCampo_ServicoContratado">
                        <div className="dados_containerTitulo_ServicoContratado">
                          <label className="dados_containerTituloTexto_ServicoContratado">Data:</label>
                        </div>
                        <div className="dados_containerCampo_ServicoContratado">
                          <Input 
                            type="text"
                            name="dataServico_ServicoContratado"
                            disabled={true}
                            maxLength={10}
                            placeholder=""
                            typeImage={0}
                            value={data}
                            onChange={(event) => setData(event.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div className="dados_addColumns_ServicoContratado">
                      <div className="dados_containerCampo_ServicoContratado">
                        <div className="dados_containerTitulo_ServicoContratado">
                          <label className="dados_containerTituloTexto_ServicoContratado">Valor:</label>
                        </div>
                        <div className="dados_containerCampo_ServicoContratado">
                          <Input
                            type="text"
                            name="valorServico_ServicoContratado"
                            disabled={true}
                            maxLength={10}
                            placeholder=""
                            typeImage={0}
                            value={valor}
                            onChange={(event) => setValor(event.target.value)} />
                        </div>
                      </div>
                    </div>
                  </>
                }

                { width >= 420 &&
                  <div className="dados_addColumns_ServicoContratado">
                    <div className="dados_containerCampo_ServicoContratado">
                      <div className="dados_containerTitulo_ServicoContratado">
                        <label className="dados_containerTituloTexto_ServicoContratado">Data:</label>
                      </div>
                      <div className="dados_containerCampo_ServicoContratado">
                        <Input 
                          type="text"
                          name="dataServico_ServicoContratado"
                          disabled={true}
                          maxLength={10}
                          placeholder=""
                          typeImage={0}
                          value={data}
                          onChange={(event) => setData(event.target.value)} />
                      </div>
                    </div>
                    <div className="dados_containerCampo_ServicoContratado">
                      <div className="dados_containerTituloDuplo_ServicoContratado">
                        <label className="dados_containerTituloTexto_ServicoContratado">Valor:</label>
                      </div>
                      <div className="dados_containerCampo_ServicoContratado">
                        <Input
                          type="text"
                          name="valorServico_ServicoContratado"
                          disabled={true}
                          maxLength={10}
                          placeholder=""
                          typeImage={0}
                          value={valor}
                          onChange={(event) => setValor(event.target.value)} />
                      </div>
                    </div>
                  </div>
                }

                <div className="dados_addColumnsAvaliacao_ServicoContratado">
                  <label>Avaliação:</label>
                </div>

                <div className="dados_addColumnsEstrela_ServicoContratado">
                  { (getFills(avaliacao)[0] === 0) ? <img src={Img_star_empty} className="dados_estrela_ServicoContratado" alt="Estrela vazia" onClick={() => setAvaliacao(1) }/> : (getFills(avaliacao)[0] === 1) ? <img src={Img_star_all} className="dados_estrela_ServicoContratado" alt="Estrela cheia" onClick={() => setAvaliacao(1) }/> : <img src={Img_star_half} className="dados_estrela_ServicoContratado" alt="Meia Estrela" onClick={() => setAvaliacao(1) } /> }
                  { (getFills(avaliacao)[1] === 0) ? <img src={Img_star_empty} className="dados_estrela_ServicoContratado" alt="Estrela vazia" onClick={() => setAvaliacao(2) }/> : (getFills(avaliacao)[1] === 1) ? <img src={Img_star_all} className="dados_estrela_ServicoContratado" alt="Estrela cheia" onClick={() => setAvaliacao(2) }/> : <img src={Img_star_half} className="dados_estrela_ServicoContratado" alt="Meia Estrela" onClick={() => setAvaliacao(2) }/> }
                  { (getFills(avaliacao)[2] === 0) ? <img src={Img_star_empty} className="dados_estrela_ServicoContratado" alt="Estrela vazia" onClick={() => setAvaliacao(3) }/> : (getFills(avaliacao)[2] === 1) ? <img src={Img_star_all} className="dados_estrela_ServicoContratado" alt="Estrela cheia" onClick={() => setAvaliacao(3) }/> : <img src={Img_star_half} className="dados_estrela_ServicoContratado" alt="Meia Estrela" onClick={() => setAvaliacao(3) }/> }
                  { (getFills(avaliacao)[3] === 0) ? <img src={Img_star_empty} className="dados_estrela_ServicoContratado" alt="Estrela vazia" onClick={() => setAvaliacao(4) }/> : (getFills(avaliacao)[3] === 1) ? <img src={Img_star_all} className="dados_estrela_ServicoContratado" alt="Estrela cheia" onClick={() => setAvaliacao(4) }/> : <img src={Img_star_half} className="dados_estrela_ServicoContratado" alt="Meia Estrela" onClick={() => setAvaliacao(4) }/> }
                  { (getFills(avaliacao)[4] === 0) ? <img src={Img_star_empty} className="dados_estrela_ServicoContratado" alt="Estrela vazia" onClick={() => setAvaliacao(5) }/> : (getFills(avaliacao)[4] === 1) ? <img src={Img_star_all} className="dados_estrela_ServicoContratado" alt="Estrela cheia" onClick={() => setAvaliacao(5) }/> : <img src={Img_star_half} className="dados_estrela_ServicoContratado" alt="Meia Estrela" onClick={() => setAvaliacao(5) }/> }
                </div>

                <div className="dados_addColumns_ServicoContratado">
                  <div className="dados_containerCampo_ServicoContratado">
                    <Input 
                      type="text"
                      title="Descrição:"
                      name="descricao_ServicoContratado"
                      disabled={false}
                      maxLength={255}
                      placeholder=""
                      typeImage={0}
                      value={descricao}
                      onChange={(event) => setDescricao(event.target.value)} />
                  </div>
                </div>
              </div>

              { (mensagem !== "") &&
                <div id="mensagem_ServicoContratado">
                  <label id="mensagemTexto_ServicoContratado">{mensagem}</label>
                </div>
              }

              { (mensagemErro !== "") &&
                <div id="mensagemErro_ServicoContratado">
                  <label id="mensagemErroTexto_ServicoContratado">{mensagemErro}</label>
                </div>
              }

              <div id="dados_containerBotoes_ServicoContratado">
                <div id="dados_botoes_ServicoContratado">
                  <img className="dados_botao_ServicoContratado" src={Img_progredir} alt="progredir" onClick={() => voltarAdd()}/>
                  <img className="dados_botao_ServicoContratado marginLeft5_ServicoContratado" src={Img_salvar} alt="prosseguir" onClick={() => salvar()} />
                </div>
              </div>
            </div>
          }
        </div>
      }
    </div>
  )
}

export default ServicoContratado