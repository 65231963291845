import "./style.css"
import { useEffect, useState } from "react"
import axios from "axios"
import Spinner from 'react-bootstrap/Spinner'
import Input from "../../Components/Input"
import Img_deletar from "../../Assets/Img/Botoes/excluir.png"

const api = axios.create({
  baseURL: "https://api.brconnect.com.br/api/",
  headers: {                
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json;charset=utf-8"
  }
})

const FotoServico = ({ usuario, servico }) => {
  const [quantidade, setQuantidade] = useState(0)
  const [retorno, setRetorno] = useState({})

  const [mostrarResultado, setMostrarResultado] = useState(false)

  const [carregando, setCarregando] = useState('')
  const [mensagem, setMensagem] = useState('')
  const [mensagemErro, setMensagemErro] = useState('')    

  const [atualizar, setAtualizar] = useState(1)

  useEffect(() => {
    var retornoJson = []

    setMensagem("")
    setMensagemErro("")
    setCarregando("Aguarde buscando as informações...")        

    var jsonLogin = "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ + "\", \"SENHA\": \""+ usuario.SENHA + "\" }"
    var jsonServicoFoto = "{ \"SERVICO\": { \"ID\": "+ servico.ID +"} }"

    api.get("SERVICO_FOTO/Consultar?jsonLogin="+ jsonLogin + "&jsonServicoFoto=" + jsonServicoFoto)
    .then((response) => { retornoJson = JSON.parse(response.data) })
    .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível comunicar com a WebAPI ("+ response +")\"}") })
    .finally(() => {
      if (retornoJson.CONCRETIZADO === false){
        setMensagem("")
        setMensagemErro(retornoJson.ERRO)
        setCarregando("")
        setMostrarResultado(false)
        return
      }
      else
      {
        retornoJson.RESULTADO && setQuantidade(retornoJson.RESULTADO.length)

        setRetorno(retornoJson)
        setCarregando("")
        setMostrarResultado(true)
      }
    })
  }, [atualizar])
   
  const enviar = async(e) => {
    var retornoJson = []

    const formData = new FormData();
    formData.append("JSON_LOGIN", "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ +"\", \"SENHA\": \""+ usuario.SENHA +"\" }");
    formData.append("JSON_SERVICO_FOTO", "{ \"SERVICO\": { \"ID\": "+ servico.ID + " } }");
    formData.append("QUANTIDADE", (quantidade === undefined) ? 0 : quantidade);
    formData.append("FILE", e);
        
    try
    {
      setCarregando("Aguarde efetuando o upload da foto do serviço...")

      const res = await axios({
        method: "post",
        url: "https://api.brconnect.com.br/api/SERVICO_FOTO/Incluir",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" }
      });
    
      retornoJson = JSON.parse(res.data)

      if (retornoJson.CONCRETIZADO){
        setMensagem("")
        setMensagemErro("")
        setCarregando("")            

        var atualizacao = atualizar + 1
        setAtualizar(atualizacao)
      }
      else{
        setMensagem("")
        setMensagemErro(retornoJson.ERRO)
        setCarregando("")
      }
    } catch (ex) {
      setMensagem("")
      setMensagemErro("Não foi possível inserir a foto do serviço. ("+ ex +")")
      setCarregando("")
    }
  }

  function excluir(event) {
    var retornoJson = []
    var jsonLogin   = "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ +"\", \"SENHA\": \""+ usuario.SENHA +"\" }"
    var jsonServicoFoto = "{ \"ID\": "+ event.ID +" }"

    setMensagem("")
    setMensagemErro("")
    setCarregando("Aguarde excluido o registro...")

    api.delete("SERVICO_FOTO/Excluir?jsonLogin="+ jsonLogin + "&jsonServicoFoto="+ jsonServicoFoto)
    .then((response) => { retornoJson = JSON.parse(response.data) })
    .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível comunicar com a WebAPI ("+ response +")\"}") })
    .finally(() => {
      if (retornoJson.CONCRETIZADO === false){
        setMensagem("")
        setMensagemErro(retornoJson.ERRO)
        setCarregando("")
        return
      }
      else
      {
        setMensagem("")
        setMensagemErro("")
        setCarregando("")

        var atualizacao = atualizar + 1
        setAtualizar(atualizacao)        
      }
    })
  }

  const handleArquivo = (e) => {
    const _file = e.target.files[0];
    enviar(_file)
  }

  return(
    <div className="main_servicoFoto">
      { (carregando !== "") &&
        <div id="carregando_servicoFoto">
          <Spinner animation="border" variant="info" />
          <label id="carregandoTexto_servicoFoto">{carregando}</label>
        </div>
      }

      { (carregando === "") &&
        <div className="main_servicoFoto">
          <div id="containerTitulo_servicoFoto">
            <div id="titulo_servicoFoto">
              <label id="tituloTexto_servicoFoto">Fotos</label>
            </div>
            
            <div id="add_servicoFoto">
              <Input 
                type="file"
                id="arquivo_FotoServico"
                name="arquivo_FotoServico"
                description="Arquivo"
                placeholder="Arquivo"
                maxLength={255}
                disabled={false}
                require={true}
                accept=".jpg,.jpeg,.png,.bmp"
                className="input-file"
                typeImage={2}
                onChange={handleArquivo} />
            </div>
          </div>

          { mostrarResultado &&
            <div id="fotos_servicoFoto">
              { (Array.isArray(retorno.RESULTADO)) &&
                retorno.RESULTADO.map(item => (
                  <div className="containerFoto_servicoFoto" key={item.ID}>
                    <img src={item.CAMINHO} className="foto_servicoFoto" alt="Foto do serviço" />
                    <img
                      className="excluir_servicoFoto"
                      name="deletar_PesquisaServico"
                      src={Img_deletar}
                      alt="Deletar"
                      height="36" 
                      width="36"
                      onClick={(() => { if (window.confirm("Deseja realmente excluir a foto do serviço ("+ item.CAMINHO + ") ?")) excluir(item) })} />
                  </div>
                ))
              }

              { (!Array.isArray(retorno.RESULTADO)) &&
                <div id="nenhumRegistro_servicoFoto">
                  <label id="nenhumRegistroTexto_servicoFoto">Nenhuma foto adicionada</label>
                </div>
              }
            </div>
          }

          { (mensagem !== "") &&
            <div id="mensagem_servicoFoto">
              <label id="mensagemTexto_servicoFoto">{mensagem}</label>
            </div>
          }

          { (mensagemErro !== "") &&
            <div id="mensagemErro_servicoFoto">
              <label id="mensagemErroTexto_servicoFoto">{mensagemErro}</label>
            </div>
          }            
        </div>
      }
{/*



            

            <br/> */}
            {/* { carregando && <Mensagem type={2} message={carregando} /> }
            { mensagem && <Mensagem type={1} message={mensagem} /> }
            { mensagemErro && <Mensagem type={3} message={mensagemErro} /> } */}
            
    </div>
  )
}

export default FotoServico