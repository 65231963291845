import "./style.css"
import React, { useState, useEffect } from "react"
import axios from "axios"
import Spinner from 'react-bootstrap/Spinner'
import Input from "../../../Components/Input"
import Img_Btn_Limpar from "../../../Assets/Img/clean.svg"
import Img_botao_sub_selecionada from "../../../Assets/Img/Botoes/sub_selecionada.png"

const api = axios.create({
  baseURL: "https://api.brconnect.com.br/api/",
  headers: {                
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json;charset=utf-8"
  }
})

const ListaServico = ({ usuario, descricao, nomeUsuario, setDescricao, setSelecionado, setLimpar, width }) => {
  const [servicoSelecionado, setServicoSelecionado] = useState(false)

  const [retorno, setRetorno] = useState({})

  const [carregando, setCarregando] = useState("")
  const [mensagem, setMensagem] = useState("")
  const [mensagemErro, setMensagemErro] = useState("")

  useEffect(() => {        
    var retornoJson = []

    setCarregando("Aguarde buscando os serviços...")
    setMensagem("")
    setMensagemErro("")

    var jsonLogin = "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ +"\", \"SENHA\": \""+ usuario.SENHA +"\" }"
    var jsonServico = "{ \"USUARIO\": { \"ID\": \""+ usuario.ID + "\" } }"

    api.get("SERVICO/Consultar?jsonLogin="+ jsonLogin + "&jsonServico=" + jsonServico)
    .then((response) => { retornoJson = JSON.parse(response.data) })
    .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível consultar os serviços ("+ response +")\"}") })
    .finally(() => {
      if (retornoJson.CONCRETIZADO === false){
        setCarregando("")
        setMensagem("")
        setMensagemErro(retornoJson.ERRO)

        document.getElementById("descricaoo_ListaServico") &&
          document.getElementById("descricaoo_ListaServico").focus()

        return
      }
      else
      {                    
        setMensagem("")
        setMensagemErro("")
        setRetorno(retornoJson)
        setCarregando("")

        document.getElementById("descricaoo_ListaServico") &&
          document.getElementById("descricaoo_ListaServico").focus()        
      }      
    })
  }, [usuario, descricao, nomeUsuario])

  const limpar = () => {        
    setLimpar("")
    setServicoSelecionado(false)
  }

  const selecionar = (servico) => {
    setSelecionado(servico)
    setServicoSelecionado(true)
  }

  return(        
    <div className="main_ListaServico">
      { (carregando !== "") &&
        <div id="carregando_ListaServico">
          <Spinner animation="border" variant="info" />
          <label id="carregandoTexto_ListaServico">{carregando}</label>
        </div>
      }

      { (carregando === "") &&
        <div className="main_ListaServico">
          <div className="main_ListaServico">
            <div id="campo_ListaServico">
              <div id="descricao_ListaServico">
                <Input 
                  type="search"
                  title={servicoSelecionado ? "Serviço:" : "" } 
                  name="descricaoo_ListaServico"
                  disabled={true}
                  maxLength={80}
                  placeholder="Selecione o serviço..."
                  typeImage={0}
                  value={descricao || ""}
                  onChange={(event) => setDescricao(event.target.value)} />
              </div>
              <div id={ servicoSelecionado ? "limparSelecionado_ListaServico" : "limpar_ListaServico" }>
                <img
                  name="limpar_ListaServico"
                  src={Img_Btn_Limpar}
                  alt="Limpar"
                  onClick={() => limpar()} />
              </div>
            </div>

            { (!servicoSelecionado) &&
              <div id="grid_ListaServico">
                { (Array.isArray(retorno.RESULTADO)) &&
                  <div id="selecione_ListaServico">
                    <label id="selecioneTexto_ListaServico">Selecione o serviço:</label>
                  </div>
                }

                { (Array.isArray(retorno.RESULTADO)) &&
                  retorno.RESULTADO.map(item => (
                    <div key={"trListaServico_Resultado"+ item.ID} className="resultado_ListaServico" onClick ={(() => selecionar(item))}>
                      <div className="resultadoDescricao_ListaServico">
                        <label>{item.SUB_CATEGORIA.DESCRICAO} {">"} {item.SUB_CATEGORIA.CATEGORIA.DESCRICAO} {">"} {item.DESCRICAO}</label>
                      </div> 
                      <div className="resultadoBotao_ListaServico">
                        <img src={Img_botao_sub_selecionada} alt="Botão" className="botao_ListaServico" />
                      </div>                         
                    </div>
                  ))
                }

                { (!Array.isArray(retorno.RESULTADO)) &&
                  <div id="nenhumRegistro_ListaServico">
                    <label id="nenhumRegistroTexto_ListaServico">Nenhum servico cadastrado!</label>
                  </div>
                }
              </div>
            }
          </div>

          { (mensagem !== "") &&
            <div id="mensagem_ListaServico">
              <label id="mensagemTexto_ListaServico">{mensagem}</label>
            </div>
          }

          { (mensagemErro !== "") &&
            <div id="mensagemErro_ListaServico">
              <label id="mensagemErroTexto_ListaServico">{mensagemErro}</label>
            </div>
          }          
        </div>
      }
    </div>
  )
}

export default ListaServico