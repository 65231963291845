import "./style.css"

const Footer = () =>  {
  return(
    <div id="footer_footer">
      <label id="footerLabel_footer">© 2023 BRConnect, all rights reserved</label>
    </div>
  )
}

export default Footer