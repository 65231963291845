import "./style.css"
import React, { useEffect, useState } from "react"
import axios from "axios"
import { ReactComponent as Arrow } from "../../Assets/Img/arrow.svg"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import InputNumeric from "../../Components/InputNumeric"
import Select from "../../Components/Select"
import Spinner from 'react-bootstrap/Spinner'
import Img_estender from "../../Assets/Img/estender.png"
import ListaServico from "../Servico/Lista"
import ListaUsuario from "../Usuario/Lista"
import Img_adicionar from "../../Assets/Img/Botoes/adicionar.png"
import Img_excluir from "../../Assets/Img/Botoes/excluir.png"
import Img_filtro_branco from "../../Assets/Img/filtro_branco.png"
import Img_logo_topo from "../../Assets/Img/logo_topo_colorida.png"
import Img_menu from "../../Assets/Img/Botoes/menu.png"
import Img_progredir from "../../Assets/Img/Botoes/progredir.png"
import Img_recolher from "../../Assets/Img/recolher.png"
import Img_salvar from "../../Assets/Img/Botoes/salvar.png"
import Img_star_all from "../../Assets/Img/star_all.png"
import Img_star_half from "../../Assets/Img/star_half.png"
import Img_star_empty from "../../Assets/Img/star_empty.png"

const api = axios.create({
  baseURL: "https://api.brconnect.com.br/api/",
  headers: {                
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json;charset=utf-8"
  }
})

const ServicoPrestado = ({ usuario, categorias, subCategorias, chamarMenu, chamarListaServicos, width }) => {
  const [atualizar, setAtualizar] = useState(1)
  const [codigoCategoria, setCodigoCategoria] = useState("")
  const [codigoCliente, setCodigoCliente] = useState("")
  const [codigoServico, setCodigoServico] = useState("")
  const [codigoSubCategoria, setCodigoSubCategoria] = useState("")
  const [data, setData] = useState("")
  const [dataAdd, setDataAdd] = useState("")
  const [descricaoServicoFiltro, setDescricaoServicoFiltro] = useState("")
  const [nomeCliente, setNomeCliente] = useState("")
  const [nomeClienteFiltro, setNomeClienteFiltro] = useState("")
  const [servicosExecutados, setServicosExecutados] = useState([])
  const [servicosSelecionados, setServicosSelecionados] = useState([])
  const [valor, setValor] = useState(0)
  const [valorAdd, setValorAdd] = useState(0)

  // 1 - Pesquisa , 2 - Serviço
  const [processo, setProcesso] = useState(1)

  const [mostrarFiltro, setMostrarFiltro] = useState(false)
  const [mostrarResultado, setMostrarResultado] = useState(false)

  const optionsRegPorPagina = "{\"RESULTADO\": [{\"ID\": 12, \"VALOR\": \"12\"}, {\"ID\": 24, \"VALOR\": \"24\"}, {\"ID\": 36, \"VALOR\": \"36\" }, {\"ID\": 100, \"VALOR\": \"Todos\" }]}"
  const [optionsCategoria, setOptionsCategoria] = useState("{\"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\"}]}")
  const [optionsSubCategoria, setOptionsSubCategoria] = useState("{\"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\"}]}")
  const [primeiroAcesso, setPrimeiroAcesso] = useState(true)

  const [paginaAtual, setPaginaAtual] = useState(1)
  const [regPorPagina, setRegPorPagina] = useState(12)
  const [totalRegistro, setTotalRegistro] = useState(0)
  const [totalPagina, setTotalPagina] = useState(0)

  const [carregando, setCarregando] = useState(null)
  const [mensagem, setMensagem] = useState("")
  const [mensagemErro, setMensagemErro] = useState("")

  useEffect(() => {
    if (primeiroAcesso) {
      // Preenche categorias
      var categoria = "{\"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\"}"
      categorias.map(item => ( 
        categoria += ",{\"ID\": "+ item.ID +", \"VALOR\": \""+ item.DESCRICAO +"\"}"
      ))
      categoria += "]}"
      setOptionsCategoria(categoria)

      // Preenche serviços prestados
      setCarregando("Buscando os serviços prestados, Aguarde...")

      var retornoJsonSP = []
      var jsonLoginSP = "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ + "\"" +
      ", \"SENHA\": \""+ usuario.SENHA +"\", \"COORDENADAS\": \""+ usuario.COORDENADAS +"\" }"

      var jsonServicoExecutado = "{ \"SERVICO\": { \"USUARIO\": { \"ID\": "+ usuario.ID +" } } }"

      api.get("SERVICO_EXECUTADO/TotalRegistros_Consultar?jsonLogin=" + jsonLoginSP + "&jsonServicoExecutado=" + jsonServicoExecutado)
      .then((response) => { retornoJsonSP = JSON.parse(response.data) })
      .catch((response) => { retornoJsonSP = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível pesquisar a quantidade de serviços prestados ("+ response +")\"}") })
      .finally(() => {                 
        if (retornoJsonSP.CONCRETIZADO === false){
          setCarregando("")
          setMensagem("")
          setMensagemErro(retornoJsonSP.ERRO)
          setMostrarResultado(false)
          return
        }
        else
        {
          var tRegistro   = retornoJsonSP.RESULTADO
          if (tRegistro === 0)
          {
            setCarregando("")
            setMensagem("")
            setMensagemErro("Não há nenhum serviço prestado.")
            setMostrarResultado(false)
            return
          }

          if (optionsRegPorPagina === 100)
          {
            api.get("SERVICO_EXECUTADO/Consultar?jsonLogin=" + jsonLoginSP + "&jsonServicoExecutado=" + jsonServicoExecutado +
              "&iQtdeInicial=0&iQtdeRegistros=" + tRegistro)
            .then((response) => { retornoJsonSP = JSON.parse(response.data) })
            .catch((response) => { retornoJsonSP = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível pesquisar os serviços prestados ("+ response +")\"}") })
            .finally(() => {
              if (retornoJsonSP.CONCRETIZADO === false){
                setCarregando("")
                setMensagem("")
                setMensagemErro(retornoJsonSP.ERRO)
                setMostrarResultado(false)
                return
              }
              else
              {
                setMensagem("")
                setMensagemErro("")
                setServicosExecutados(retornoJsonSP.RESULTADO)
                setServicosSelecionados(retornoJsonSP.RESULTADO)
                setMostrarResultado(true)
              }
              setCarregando("")
            })
                        
            setTotalRegistro(tRegistro)
            setTotalPagina(1)
            if (paginaAtual !== 1) setPaginaAtual(1)
          }
          else
          {
            var operacao    = tRegistro / regPorPagina
            var numero      = Math.floor(operacao)
            var decimal     = operacao % 1

            var tPagina     = 0
            if (decimal > 0) tPagina = numero + 1
            else tPagina    = numero
                        
            setTotalRegistro(tRegistro)
            setTotalPagina(tPagina)
            if (tPagina < paginaAtual) setPaginaAtual(1)

            var qtdeInicial = 0
            if (paginaAtual > 1) qtdeInicial = ((paginaAtual - 1) * regPorPagina)

            api.get("SERVICO_EXECUTADO/Consultar?jsonLogin=" + jsonLoginSP + "&jsonServicoExecutado=" + jsonServicoExecutado + 
              "&iQtdeInicial=" + qtdeInicial + "&iQtdeRegistros=" + regPorPagina)
            .then((response) => { retornoJsonSP = JSON.parse(response.data) })
            .catch((response) => { retornoJsonSP = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível pesquisar os serviços prestados ("+ response +")\"}") })
            .finally(() => {
              if (retornoJsonSP.CONCRETIZADO === false){
                setCarregando("")
                setMensagem("")
                setMensagemErro(retornoJsonSP.ERRO)
                setMostrarResultado(false)
                return
              }
              else
              {
                setMensagem("")
                setMensagemErro("")
                setServicosExecutados(retornoJsonSP.RESULTADO)
                setServicosSelecionados(retornoJsonSP.RESULTADO)
                setMostrarResultado(true)
              }
              setCarregando("")
            })
          }

          setMensagem("")
          setMensagemErro("")
        }

        setProcesso(1)
        setCodigoCategoria("")
        setCodigoSubCategoria("")
        setNomeCliente("")
        setValor(0)
        setData("")
      })
            
      setPrimeiroAcesso(false)
    }

    if (processo === 1) {
      var servicosExecutados_1 = []
      if ((codigoCategoria !== "") && (String(codigoCategoria) !== "0")) {
        servicosExecutados.map(item => ( 
          (item.SERVICO.SUB_CATEGORIA.ID === parseInt(codigoCategoria)) && 
            servicosExecutados_1.push(item)
        ))
      }
      else servicosExecutados_1 = servicosExecutados

      var servicosExecutados_2 = []
      if ((codigoSubCategoria !== "") && (String(codigoSubCategoria) !== "0")) {
        servicosExecutados_1.map(item => ( 
        (item.SERVICO.SUB_CATEGORIA.CATEGORIA.ID === parseInt(codigoSubCategoria)) &&
          servicosExecutados_2.push(item)
        ))
      }
      else servicosExecutados_2 = servicosExecutados_1

      var servicosExecutados_3 = []
      if (valor > 0) {
        servicosExecutados_2.map(item => ( 
          (item.VALOR === parseFloat(valor)) &&
            servicosExecutados_3.push(item)
        ))
      }
      else servicosExecutados_3 = servicosExecutados_2

      var servicosExecutados_4 = []
      if (validarData(data)) {
        servicosExecutados_3.map(item => ( 
        ( (String(item.DATA).substring(8,10) + "/" + 
          String(item.DATA).substring(5,7) + "/" + 
            String(item.DATA).substring(0,4)) === String(data)) && 
              servicosExecutados_4.push(item)
        ))
      }
      else servicosExecutados_4 = servicosExecutados_3

      var servicosExecutados_5 = []
      if (nomeCliente !== "") {
        servicosExecutados_4.map(item => ( 
          (item.USUARIO.NOME.indexOf(nomeCliente.toUpperCase()) !== -1) &&
            servicosExecutados_5.push(item)
        ))
      }
      else servicosExecutados_5 = servicosExecutados_4

      setServicosSelecionados(servicosExecutados_5)
    }
  }, [categorias, codigoCategoria, codigoSubCategoria, data, nomeCliente, paginaAtual, primeiroAcesso, processo,
    regPorPagina, servicosExecutados, usuario.COORDENADAS, usuario.CPF_CNPJ, usuario.ID, usuario.SENHA, valor])

  const excluir = (item) => {        
    var retornoJson = []     
    var jsonLogin = "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ + "\", \"SENHA\": \""+ usuario.SENHA + "\" }"
    var jsonServicoExecutado = "{ \"ID\": "+ item.ID +" }"

    setMensagem("")
    setMensagemErro("")
    setCarregando("Aguarde excluindo o serviço prestado...")

    api.delete("SERVICO_EXECUTADO/Excluir?jsonLogin=" + jsonLogin + "&jsonServicoExecutado=" + jsonServicoExecutado)
    .then((response) => { retornoJson = JSON.parse(response.data) })
    .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível excluir o serviço executado ("+ response +")\"}") })
    .finally(() => {
      if (retornoJson.CONCRETIZADO === false){
        setCarregando("")
        setMensagem("")
        setMensagemErro(retornoJson.ERRO)
        return
      }
      else
      {
        setMensagem("")
        setMensagemErro("")
        setPrimeiroAcesso(true)

        var atualizacao = atualizar + 1
        setAtualizar(atualizacao)
      }
      setCarregando("")
    })        
  }

  const salvar = () => {
    var retornoJson = []     
    var jsonLogin = "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ + "\", \"SENHA\": \""+ usuario.SENHA + "\" }"
    var jsonServicoExecutado = "{ "

    if (codigoCliente !== "") jsonServicoExecutado += " \"USUARIO\": { \"ID\": "+ codigoCliente + " }"
    else
    {
      setMensagemErro("É necessário informar o usuário!");
      return;
    }

    if (codigoServico !== "") jsonServicoExecutado += ", \"SERVICO\": { \"ID\": "+ codigoServico + " }"
    else
    {
      setMensagemErro("É necessário informar o serviço!");
      return;
    }

    if (validarData(dataAdd)) {
      var data = dataAdd.substring(6,10) + "/" + dataAdd.substring(3,5) + "/" + dataAdd.substring(0,2) + "T00:00:00"
      jsonServicoExecutado += ", \"DATA\": \""+ data + "\""
    }
    else
    {
      setMensagemErro("É necessário informar a data!");
      return;
    }

    if ((valorAdd !== "") && (valorAdd !== "0"))
      jsonServicoExecutado += ", \"VALOR\": "+ valorAdd
    else 
    {
      setMensagemErro("É necessário informar o valor!");
      return;
    }

    jsonServicoExecutado += " }"

    setMensagem("")
    setMensagemErro("")
    setCarregando("Aguarde incluindo o usuário...")

    api.post("SERVICO_EXECUTADO/Incluir?jsonLogin=" + jsonLogin + "&jsonServicoExecutado=" + jsonServicoExecutado)
    .then((response) => { retornoJson = JSON.parse(response.data) })
    .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível incluir o serviço executado ("+ response +")\"}") })
    .finally(() => {
      if (retornoJson.CONCRETIZADO === false){
        setCarregando("")
        setMensagem("")
        setMensagemErro(retornoJson.ERRO)
        return
      }
      else
      {
        setMensagemErro("")
        setMensagem("Inclusão efetuada com sucesso!")

        setCodigoServico("")
        setDescricaoServicoFiltro("")                
        setCodigoCliente("")
        setNomeClienteFiltro("")
        setDataAdd("")
        setValorAdd(0)
    
        setPrimeiroAcesso(true)                
      }

      setCarregando("")
    })
  }

  const setCliente = (usuario) => {
    setNomeClienteFiltro(usuario.NOME)
    setCodigoCliente(usuario.ID)
  }

  function formatNumeric(value, decimals) {
    if (!value) {
      return ""
    }

    if (value === ".") {
      return value = "0."
    }
      
    var regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)
    const decimalsNumber = value.toString().match(regex)[0]
    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) {
      return "0"
    }

    return parsed
  }    

  function getFills(score){
    const fills = [0, 0, 0, 0, 0];  
    const integerPart = Math.floor(score);
        
    for (let i = 0; i < integerPart; i++) {
      fills[i] = 1;
    }
        
    const diff = score - integerPart;
    if (diff > 0) {
      fills[integerPart] = 0.5;
    }
        
    return fills;
  }

  function paginaAnterior(){
    if (totalRegistro > 0) {
      if (paginaAtual !== 1) setPaginaAtual(paginaAtual - 1)
    }
  }

  function paginaSeguinte(){
    if (totalRegistro > 0) {
      if (paginaAtual < totalPagina) setPaginaAtual(paginaAtual + 1)
    }
  }    

  const adicionar = () => {
    setMensagem("")
    setMensagemErro("")

    setProcesso(2)
  }

  const limparCliente = () => {
    setNomeClienteFiltro("")
    setCodigoCliente("")
  }

  const limparServico = () => {
    setDescricaoServicoFiltro("")
    setCodigoServico("")
  }

  function setCategoria(event) {
    var categoria = event
    var subCategoria = "{\"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\"}"
    subCategorias.map(item => ( 
      (parseInt(categoria) === item.CATEGORIA.ID) ?
        subCategoria += ",{\"ID\": "+ item.ID +", \"VALOR\": \""+ item.DESCRICAO +"\"}" : ""
    ))
    subCategoria += "]}"

    setOptionsSubCategoria(subCategoria)
    setCodigoCategoria(categoria)
  }

  const setServico = (servico) => {
    setDescricaoServicoFiltro(servico.SUB_CATEGORIA.DESCRICAO + " - " + servico.DESCRICAO)
    setCodigoServico(servico.ID)
  }

  function validarData(valor) {
    var erro=false;
    var date=valor;
    var ardt=new Array([]);
    var ExpReg=new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt=date.split("/");        

    if ( date.search(ExpReg)===-1) erro = true
    else if (((ardt[1]===4)||(ardt[1]===6)||(ardt[1]===9)||(ardt[1]===11))&&(ardt[0]>30)) erro = true
    else if ( ardt[1]===2) {
      if ((ardt[0]>28)&&((ardt[2]%4)!==0))
        erro = true;
      if ((ardt[0]>29)&&((ardt[2]%4)===0))
        erro = true;
    }

    if (erro) return false;
    return true;
  }    

  function voltarAdd() {
    setCodigoCategoria("")
    setCodigoCliente("")
    setCodigoServico("")
    setCodigoSubCategoria("")
    setData("")
    setDataAdd("")
    setDescricaoServicoFiltro("")
    setNomeCliente("")
    setNomeClienteFiltro("")

    setMensagem("")

    if (!mostrarResultado) setMensagemErro("Não há nenhum serviço prestado.")
    else setMensagemErro("")

    setProcesso(1)
  }

  return(
    <div className="main_ServicoPrestado">
      { (carregando !== "") &&
        <div id="carregando_ServicoPrestado">
          <Spinner animation="border" variant="info" />
          <label id="carregandoTexto_ServicoPrestado">{carregando}</label>
        </div>
      }

      { (carregando === "") &&
        <div className="main_ServicoPrestado">
          <div id="cabecalho_ServicoPrestado">
            <div id="cabecalhoMenu_ServicoPrestado">
              <img src={Img_menu} alt="Menu" onClick={() => chamarMenu()} />
            </div>
            <div id="cabecalhoTitulo_ServicoPrestado">
              <label id="titulo_ServicoPrestado">SERVIÇOS</label><label id="tituloSub_ServicoPrestado">PRESTADOS</label>
            </div>
            <div id="cabecalhoLogo_ServicoPrestado">
              <img src={Img_logo_topo} alt="Logo" />
            </div>
          </div>

          { ((processo === 1 && !mostrarResultado) || (processo === 2)) &&
            <div id="bordarBottom_ServicoPrestado" />
          }
            
          {/* 1 - Pesquisa */}
          { (processo === 1) &&
            <div className="main_ServicoPrestado">             
              { mostrarResultado &&
                <div className="main_ServicoPrestado">
                  { !mostrarFiltro &&
                    <div id="pesquisa_filtroRecolhido_ServicoPrestado" onClick={() => setMostrarFiltro(true)}>
                      <div id="pesquisa_filtroRecolhidoFiltro_ServicoPrestado">
                        <img src={Img_filtro_branco} id="pesquisa_filtroRecolhidoFiltroImagem_ServicoPrestado" alt="Filtro" />
                      </div>
                      <div id="pesquisa_filtroRecolhidoDescricao_ServicoPrestado">
                        <img src={Img_estender} id="pesquisa_filtroRecolhidoDescricaoImagem_ServicoPrestado" alt="Estender" />
                        <label id="pesquisa_filtroRecolhidoDescricaoTexto_ServicoPrestado">Mostrar Filtros</label>
                      </div>
                    </div>
                  }

                  { mostrarFiltro &&
                    <>
                      <div id="pesquisa_filtro_ServicoPrestado">
                        <div id="pesquisa_filtroFiltro_ServicoPrestado">
                          <img src={Img_filtro_branco} id="pesquisa_filtroFiltroImagem_ServicoPrestado" alt="Filtro" />
                        </div>
                        <div className="pesquisa_filtroCampos_ServicoPrestado">

                          { width < 700 &&
                            <>
                              <div className="pesquisa_filtroCampo_ServicoPrestado marginTop10_ServicoPrestado">
                                <Select 
                                  title="Categoria: "
                                  name="categoria_ServicoPrestado"
                                  options={JSON.parse(optionsCategoria)}
                                  disabled={false}
                                  value={codigoCategoria}
                                  onChange={setCategoria} />
                              </div>

                              <div className="pesquisa_filtroCampo_ServicoPrestado">
                                <Select 
                                  title="Serviço: "
                                  name="subCategoria_ServicoPrestado"
                                  options={JSON.parse(optionsSubCategoria)}
                                  disabled={false}
                                  value={codigoSubCategoria}
                                  onChange={setCodigoSubCategoria} />
                              </div>
                            </>
                          }

                          { width >= 700 &&
                            <div className="pesquisa_filtroCampo_ServicoPrestado marginTop10_ServicoPrestado">
                              <div className="pesquisa_filtroCampoDuplo_ServicoPrestado">
                                <Select 
                                  title="Categoria: "
                                  name="categoria_ServicoPrestado"
                                  options={JSON.parse(optionsCategoria)}
                                  disabled={false}
                                  value={codigoCategoria}
                                  onChange={setCategoria} />
                              </div>
                              <div className="pesquisa_filtroCampoDuplo_ServicoPrestado">
                                <Select 
                                  title="Serviço: "
                                  name="subCategoria_ServicoPrestado"
                                  options={JSON.parse(optionsSubCategoria)}
                                  disabled={false}
                                  value={codigoSubCategoria}
                                  onChange={setCodigoSubCategoria} />
                              </div>
                            </div>
                          }

                          <div className="pesquisa_filtroCampo_ServicoPrestado">
                            <Input 
                              type="search"
                              title="Cliente:"
                              name="nomeCliente_ServicoPrestado"
                              disabled={false}
                              maxLength={120}
                              placeholder=""
                              typeImage={0}
                              value={nomeCliente || ""}
                              onChange={(event) => setNomeCliente(event.target.value)} />
                          </div>

                          { width < 350 &&
                            <>
                              <div className="pesquisa_filtroCampo_ServicoPrestado">
                                <InputNumeric
                                  title="Valor"
                                  name="valor_ServicoPrestado"
                                  disabled={false}
                                  typeImage={0}
                                  placeholder=""
                                  maxLength={13}
                                  value={ valor || ''}
                                  onChange={(event) => setValor(event.target.value)} />
                              </div>

                              <div className="pesquisa_filtroCampo_ServicoPrestado">
                                <InputMasked
                                  title="Data"
                                  name="data_ServicoPrestado"
                                  mask="99/99/9999"
                                  disabled={false}
                                  typeImage={0}
                                  placeholder=""
                                  value={data || ""}
                                  onChange={(event) => setData(event.target.value)} />
                              </div>

                              <div className="pesquisa_filtroCampo_ServicoPrestado">
                                <Select 
                                  title="Registros: "
                                  name="regProPagina_ServicoPrestado"
                                  options={JSON.parse(optionsRegPorPagina)}
                                  disabled={false}
                                  value={regPorPagina}
                                  onChange={setRegPorPagina} />
                              </div>
                            </>
                          }

                          { width >= 350 && width < 700 &&
                            <>
                              <div className="pesquisa_filtroCampo_ServicoPrestado">
                                <div className="pesquisa_filtroCampoDuplo_ServicoPrestado">
                                  <InputNumeric
                                    title="Valor"
                                    name="valor_ServicoPrestado"
                                    disabled={false}
                                    typeImage={0}
                                    placeholder=""
                                    maxLength={13}
                                    value={ valor || ''}
                                    onChange={(event) => setValor(event.target.value)} />
                                </div>
                                <div className="pesquisa_filtroCampoDuplo_ServicoPrestado">
                                  <InputMasked
                                    title="Data"
                                    name="data_ServicoPrestado"
                                    mask="99/99/9999"
                                    disabled={false}
                                    typeImage={0}
                                    placeholder=""
                                    value={data || ""}
                                    onChange={(event) => setData(event.target.value)} />
                                </div>                                
                              </div>

                              <div className="pesquisa_filtroCampo_ServicoPrestado">
                                <Select 
                                  title="Registros: "
                                  name="regProPagina_ServicoPrestado"
                                  options={JSON.parse(optionsRegPorPagina)}
                                  disabled={false}
                                  value={regPorPagina}
                                  onChange={setRegPorPagina} />
                              </div>
                            </>
                          }

                          { width >= 700 &&
                            <div className="pesquisa_filtroCampo_ServicoPrestado">
                              <div className="pesquisa_filtroCampoDuplo_ServicoPrestado">
                                <InputNumeric
                                  title="Valor"
                                  name="valor_ServicoPrestado"
                                  disabled={false}
                                  typeImage={0}
                                  placeholder=""
                                  maxLength={13}
                                  value={ valor || ''}
                                  onChange={(event) => setValor(event.target.value)} />
                              </div>
                              <div className="pesquisa_filtroCampoDuplo_ServicoPrestado">
                                <InputMasked
                                  title="Data"
                                  name="data_ServicoPrestado"
                                  mask="99/99/9999"
                                  disabled={false}
                                  typeImage={0}
                                  placeholder=""
                                  value={data || ""}
                                  onChange={(event) => setData(event.target.value)} />            
                              </div>
                              <div className="pesquisa_filtroCampoDuplo_ServicoPrestado">
                                <Select 
                                  title="Registros: "
                                  name="regProPagina_ServicoPrestado"
                                  options={JSON.parse(optionsRegPorPagina)}
                                  disabled={false}
                                  value={regPorPagina}
                                  onChange={setRegPorPagina} />
                              </div>
                            </div>
                          }

                          <div id="pesquisa_filtroRecolher_ServicoPrestado" onClick={() => setMostrarFiltro(false)}>
                            <div id="pesquisa_filtroRecolherView_ServicoPrestado">
                              <img src={Img_recolher} id="pesquisa_filtroRecolherImagem_ServicoPrestado" alt="Recolher" />
                              <label id="pesquisa_filtroRecolherTexto_ServicoPrestado">Recolher Filtros</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }

                  <div id="pesquisa_servicos_ServicoPrestado">
                    <div id="pesquisa_paginacao_ServicoPrestado">
                      <div id="pesquisa_paginacaoBox_ServicoPrestado">
                        <button className="pesquisa_paginacaoBotao_ServicoPrestado" disabled={ (paginaAtual === 1) ? true : false } onClick={() => paginaAnterior() } >
                          <Arrow />
                        </button>
                        <p>{`${paginaAtual} de ${totalPagina}`}</p>
                        <button className="pesquisa_paginacaoBotao_ServicoPrestado" disabled={ (paginaAtual === totalPagina) ? true : false} onClick={() => paginaSeguinte()} >
                          <Arrow className="pesquisa_flipHorizontal_ServicoPrestado" />
                        </button>
                      </div>
                    </div>

                    { servicosSelecionados.map(item => (
                      <div key={"servicoPrestado"+ item.ID} className="pesquisa_servico_ServicoPrestado">

                        { width < 450 &&
                          <>
                            <div className="pesquisa_servicoIdentificacao_ServicoPrestado">
                              <div className="pesquisa_servicoUsuario_ServicoPrestado">
                                <label className="pesquisa_servicoUsuarioTexto_ServicoPrestado">{item.USUARIO.NOME}</label>
                              </div>
                              <div className="pesquisa_containerBotaoExcluir_ServicoPrestado">
                                { item.AVALIACAO === 0 &&
                                  <div className="pesquisa_containerExcluir_ServicoPrestado">
                                    <img src={Img_excluir} alt="Excluir" id="excluir_ServicoPrestado" onClick={(() => { if (window.confirm("Deseja realmente excluir o servico prestado ?")) excluir(item) })} />
                                  </div>
                                }
                              </div>
                            </div>

                            <div className="pesquisa_containerAvaliacao_ServicoPrestado">
                              { item.AVALIACAO === 0 && <label className="pesquisa_naoAvaliado_ServicoPrestado">Não avaliado</label> }
                              { item.AVALIACAO !== 0 &&
                                <div>
                                  { (getFills(item.AVALIACAO)[0] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[0] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoPrestado" alt="Meia Estrela" /> }
                                  { (getFills(item.AVALIACAO)[1] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[1] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoPrestado" alt="Meia Estrela" /> }
                                  { (getFills(item.AVALIACAO)[2] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[2] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoPrestado" alt="Meia Estrela" /> }
                                  { (getFills(item.AVALIACAO)[3] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[3] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoPrestado" alt="Meia Estrela" /> }
                                  { (getFills(item.AVALIACAO)[4] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[4] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoPrestado" alt="Meia Estrela" /> }
                                </div>
                              }
                            </div>

                            <div>
                              <label className="pesquisa_descricaoServico_ServicoPrestado">{item.SERVICO.SUB_CATEGORIA.DESCRICAO} - {item.SERVICO.DESCRICAO}</label>
                            </div>

                            <div className="pesquisa_outrasInformacoes_ServicoPrestado">
                              <label>
                                { (item.VALOR === 0) && "Não fornecido" }
                                { (item.VALOR !== 0) && "R$ " + formatNumeric(item.VALOR, 2)}
                              </label>
                              <label>{item.DATA.substring(8,10) + "/" + item.DATA.substring(5,7) + "/" + item.DATA.substring(0,4)}</label>
                            </div>

                            { item.AVALIACAO !== 0 &&
                              <label>Comentário: {item.DESCRICAO}</label>
                            }                            
                          </>
                        }

                        { width >= 450 &&
                          <>
                            <div className="pesquisa_servicoIdentificacao_ServicoPrestado">
                              <div className="pesquisa_servicoUsuario_ServicoPrestado">
                                <label className="pesquisa_servicoUsuarioTexto_ServicoPrestado">{item.USUARIO.NOME}</label>
                              </div>
                              <div className="pesquisa_avaliacao_ServicoPrestado">
                                { item.AVALIACAO === 0 && <label className="pesquisa_naoAvaliado_ServicoPrestado">Não avaliado</label> }
                                { item.AVALIACAO !== 0 &&
                                  <div>
                                    { (getFills(item.AVALIACAO)[0] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[0] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoPrestado" alt="Meia Estrela" /> }
                                    { (getFills(item.AVALIACAO)[1] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[1] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoPrestado" alt="Meia Estrela" /> }
                                    { (getFills(item.AVALIACAO)[2] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[2] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoPrestado" alt="Meia Estrela" /> }
                                    { (getFills(item.AVALIACAO)[3] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[3] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoPrestado" alt="Meia Estrela" /> }
                                    { (getFills(item.AVALIACAO)[4] === 0) ? <img src={Img_star_empty} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela vazia" /> : (getFills(item.AVALIACAO)[4] === 1) ? <img src={Img_star_all} className="pesquisa_estrelas_ServicoPrestado" alt="Estrela cheia" /> : <img src={Img_star_half} className="pesquisa_estrelas_ServicoPrestado" alt="Meia Estrela" /> }
                                  </div>
                                }
                              </div>
                            </div>

                            <div className="pesquisa_descricao_ServicoPrestado">
                              <div className="pesquisa_containerDescricaoServico_ServicoPrestado">
                                <label className="pesquisa_descricaoServico_ServicoPrestado">{item.SERVICO.SUB_CATEGORIA.DESCRICAO} - {item.SERVICO.DESCRICAO}</label>
                              </div>
                              { item.AVALIACAO === 0 &&
                                <div className="pesquisa_containerExcluir_ServicoPrestado">
                                  <img src={Img_excluir} alt="Excluir" id="excluir_ServicoPrestado" onClick={(() => { if (window.confirm("Deseja realmente excluir o servico prestado ?")) excluir(item) })} />
                                </div>
                              }
                            </div>

                            <div className="pesquisa_outrasInformacoes_ServicoPrestado">
                              <label>
                                { (item.VALOR === 0) && "Não fornecido" }
                                { (item.VALOR !== 0) && "R$ " + formatNumeric(item.VALOR, 2)}
                              </label>
                              <label>{item.DATA.substring(8,10) + "/" + item.DATA.substring(5,7) + "/" + item.DATA.substring(0,4)}</label>
                            </div>

                            { item.AVALIACAO !== 0 &&
                              <label>Comentário: {item.DESCRICAO}</label>
                            }
                          </>
                        }
                        
                      </div>
                      ))
                    }

                    { servicosSelecionados.length === 0 &&
                      <div id="pesquisa_sem_ServicoPrestado">
                        <label>Nenhum serviço prestado com o filtro informado!</label>
                      </div>
                    }
                  </div>
                </div>
              }

              { (mensagem !== "") &&
                <div id="mensagem_ServicoPrestado">
                  <label id="mensagemTexto_ServicoPrestado">{mensagem}</label>
                </div>
              }

              { (mensagemErro !== "") &&
                <div id="mensagemErro_ServicoPrestado">
                  <label id="mensagemErroTexto_ServicoPrestado">{mensagemErro}</label>
                </div>
              }
              
              <div id="pesquisa_botoes_ServicoPrestado">
                <img src={Img_progredir} alt="progredir" className="pesquisa_botao_ServicoPrestado" onClick={() => chamarListaServicos()} />
                <img src={Img_adicionar} alt="prosseguir" className="pesquisa_botao_ServicoPrestado marginLeft5_ServicoPrestado"
                  onClick={() => adicionar()} />
              </div>                            
            </div>
          }

          {/* 2 - Serviço */}
          { (processo === 2) &&
            <div className="main_ServicoPrestado">
              <div id="dados_container_ServicoPrestado">
                <div id="dados_addTitulo_ServicoPrestado">
                  <label id="dados_addTituloServico_ServicoPrestado">CADASTRO</label><label id="dados_addTituloContratante_ServicoContratado">SERVIÇO PRESTADO</label>
                </div>
                <div id="dados_addTraco_ServicoPrestado">
                  <hr id="dados_addTituloTraco_ServicoPrestado"/>
                </div>

                <div className="dados_telas_ServicoPrestado">
                  <ListaServico
                    usuario={usuario} 
                    descricao={descricaoServicoFiltro}                    
                    nomeUsuario=""
                    setDescricao={setDescricaoServicoFiltro}
                    setSelecionado={setServico}
                    setLimpar={limparServico} 
                    width={width} />

                  <ListaUsuario
                    usuario={usuario} 
                    nome={nomeClienteFiltro}
                    setNome={setNomeClienteFiltro}
                    setSelecionado={setCliente}
                    setLimpar={limparCliente}
                    width={width} />
                </div>

                <div className="dados_containerCampos_ServicoPrestado">
                  <div className="dados_containerCampo_ServicoPrestado">
                    <InputMasked
                      title="Data"
                      name="dataAdd_ServicoPrestado"
                      mask="99/99/9999"
                      disabled={false}
                      typeImage={0}
                      placeholder=""
                      value={dataAdd || ""}
                      onChange={(event) => setDataAdd(event.target.value)} />
                  </div>
                  <div className="dados_containerCampo_ServicoPrestado marginLeft5_ServicoPrestado">
                    <InputNumeric 
                      title="Valor"
                      name="valorAdd_ServicoPrestado"
                      disabled={false}
                      typeImage={0}
                      placeholder=""
                      maxLength={13}
                      value={ valorAdd || ''}
                      onChange={(event) => setValorAdd(event.target.value)} />
                  </div>
                </div>
              </div>

              { (mensagem !== "") &&
                <div id="mensagem_ServicoPrestado">
                  <label id="mensagemTexto_ServicoPrestado">{mensagem}</label>
                </div>
              }

              { (mensagemErro !== "") &&
                <div id="mensagemErro_ServicoPrestado">
                  <label id="mensagemErroTexto_ServicoPrestado">{mensagemErro}</label>
                </div>
              }              
                    
              <div id="dados_botoes_ServicoPrestado">
                <img className="dados_botao_ServicoPrestado" src={Img_progredir} alt="progredir" onClick={() => voltarAdd()}/>
                <img className="dados_botao_ServicoPrestado marginLeft5_ServicoPrestado" src={Img_salvar} alt="prosseguir" onClick={() => salvar()}/>
              </div>
            </div>
          }
        </div>
      }
    </div>
  )
}

export default ServicoPrestado