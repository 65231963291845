import "./style.css"
import axios from "axios"
import React, { useState, useEffect } from 'react'
import Input from '../../Components/Input'
import InputNumeric from '../../Components/InputNumeric'
import Select from '../../Components/Select'
import Spinner from 'react-bootstrap/Spinner'
import FotoServico from '../FotoServico'
import CategoriaCard from "../CategoriaCard"
import SubCategoriaCard from "../SubCategoriaCard"
import Img_adicionar from "../../Assets/Img/Botoes/adicionar.png"
import Img_estender from "../../Assets/Img/estender.png"
import Img_excluir from "../../Assets/Img/Botoes/excluir.png"
import Img_filtro_branco from "../../Assets/Img/filtro_branco.png"
import Img_logo_topo from "../../Assets/Img/logo_topo_colorida.png"
import Img_menu from "../../Assets/Img/Botoes/menu.png"
import Img_progredir from "../../Assets/Img/Botoes/progredir.png"
import Img_recolher from "../../Assets/Img/recolher.png"
import Img_salvar from "../../Assets/Img/Botoes/salvar.png"
import Img_titulo_categoria from "../../Assets/Img/Titulos/categoria.png"
import Img_titulo_sub_categoria from "../../Assets/Img/Titulos/sub_categoria.png"

const api = axios.create({
  baseURL: "https://api.brconnect.com.br/api/",
  headers: {                
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json;charset=utf-8"
  }
})

const Servico = ({ usuario, categorias, subCategorias, chamarMenu, chamarListaServicos, width }) => {
  const [codigo, setCodigo] = useState("")
  const [codigoCategoria, setCodigoCategoria] = useState("")
  const [descricaoCategoria, setDescricaoCategoria] = useState("")
  const [codigoCategoriaFiltro, setCodigoCategoriaFiltro] = useState("")
  const [descricaoCategoriaFiltro, setDescricaoCategoriaFiltro] = useState("")
  const [categoriasFiltro, setCategoriasFiltro] = useState([categorias])
  const [codigoSubCategoria, setCodigoSubCategoria] = useState("")
  const [descricaoSubCategoria, setDescricaoSubCategoria] = useState("")
  const [codigoSubCategoriaFiltro, setCodigoSubCategoriaFiltro] = useState("")
  const [descricaoSubCategoriaFiltro, setDescricaoSubCategoriaFiltro] = useState("")
  const [subCategoriasFiltro, setSubCategoriasFiltro] = useState([])
  const [descricao, setDescricao] = useState("")
  const [descricaoFiltro, setDescricaoFiltro] = useState("")
  const [restricaoLocalidade, setRestricaoLocalidade] = useState(0)
  
  const [servicos, setServicos] = useState([])
  const [servicosSelecionados, setServicosSelecionados] = useState([])
  const [valor, setValor] = useState(0)

  // 1 - Pesquisa , 2 - Categoria, 3 - Sub-Categoria, 4 - Cadastro
  const [processo, setProcesso] = useState(1)

  const [mostrarFiltro, setMostrarFiltro] = useState(false)
  const [mostrarResultado, setMostrarResultado] = useState(false)

  const [optionsCategoria, setOptionsCategoria] = useState("{\"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\"}]}")
  const [optionsSubCategoria, setOptionsSubCategoria] = useState("{\"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\"}]}")
  const [primeiroAcesso, setPrimeiroAcesso] = useState(true)

  const [carregando, setCarregando] = useState(null)
  const [mensagem, setMensagem] = useState("")
  const [mensagemErro, setMensagemErro] = useState("")

  useEffect(() => {
    if (primeiroAcesso) {
      subCategorias.map(item => (
        item.SELECIONADO = false
      ))

      // Preenche categorias
      var categoria = "{\"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\"}"
      categorias.map(item => ( 
        categoria += ",{\"ID\": "+ item.ID +", \"VALOR\": \""+ item.DESCRICAO +"\"}"
      ))
      categoria += "]}"
      setOptionsCategoria(categoria)

      // Preenche serviços
      setCarregando("Buscando os serviços, Aguarde...")

      var retornoJsonS = []
      var jsonLoginS = "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ + "\"" +
      ", \"SENHA\": \""+ usuario.SENHA +"\", \"COORDENADAS\": \""+ usuario.COORDENADAS +"\" }"

      var jsonServico = "{ \"USUARIO\": { \"ID\": "+ usuario.ID +" } }"

      api.get("SERVICO/Consultar?jsonLogin=" + jsonLoginS + "&jsonServico=" + jsonServico)
      .then((response) => { retornoJsonS = JSON.parse(response.data) })
      .catch((response) => { retornoJsonS = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível pesquisar os serviços ("+ response +")\"}") })
      .finally(() => {                 
        if (retornoJsonS.CONCRETIZADO === false){
          setCarregando("")
          setMensagem("")
          setMensagemErro(retornoJsonS.ERRO)
          setMostrarResultado(false)
          return
        }
        else
        {
          setMensagem("")
          setMensagemErro("")

          if (retornoJsonS.AVISO === "Nenhum registro encontrado.") {
            setServicos([])
            setServicosSelecionados([])
          }
          else {
            setServicos(retornoJsonS.RESULTADO)
            setServicosSelecionados(retornoJsonS.RESULTADO)
          }
          setMostrarResultado(true)
        }
                
        setProcesso(1)
        setCodigo("")
        setCodigoCategoria("")
        setDescricaoCategoria("")
        setCodigoSubCategoria("")
        setDescricaoSubCategoria("")
        setDescricao("")
        setValor("")
        setRestricaoLocalidade(0)

        setCodigoCategoriaFiltro("")
        setCodigoSubCategoriaFiltro("")
        setDescricaoFiltro("")

        setCarregando("")
      })            
      setPrimeiroAcesso(false)
    }

    if (processo === 1) {
      var servicos_1 = []
      if ((codigoCategoriaFiltro !== "") && (String(codigoCategoriaFiltro) !== "0")) {
        servicos.map(item => ( 
          (item.SUB_CATEGORIA.CATEGORIA.ID === parseInt(codigoCategoriaFiltro)) && 
            servicos_1.push(item)
        ))
      } else servicos_1 = servicos

      var servicos_2 = []
      if ((codigoSubCategoriaFiltro !== "") && (String(codigoSubCategoriaFiltro) !== "0")) {
        servicos_1.map(item => ( 
          (item.SUB_CATEGORIA.ID === parseInt(codigoSubCategoriaFiltro)) &&
            servicos_2.push(item)
        ))
      } else servicos_2 = servicos_1

      var servicos_3 = []
      if (descricaoFiltro !== "") {
        servicos_2.map(item => ( 
        (item.DESCRICAO.indexOf(descricaoFiltro.toUpperCase()) !== -1) &&
          servicos_3.push(item)
        ))
      } else servicos_3 = servicos_2

      setServicosSelecionados(servicos_3)
    }

    if (processo === 2) {
      var arrCategorias = []
      if (descricaoCategoriaFiltro === "") {
        categorias.map(item => (
          arrCategorias.push(item)                    
        ))
      }
      else {
        categorias.map(item => (
          (item.DESCRICAO.indexOf(descricaoCategoriaFiltro.toUpperCase()) !== -1) &&
            arrCategorias.push(item)
        ))
      }
      setCategoriasFiltro(arrCategorias)
    }

    if (processo === 3) {
      var arrSubCategorias = []

      if (descricaoSubCategoriaFiltro === "") {
        subCategorias.map(item => (
          (item.CATEGORIA.ID === codigoCategoria) && 
            arrSubCategorias.push(item)                    
        ))
      }
      else {
        subCategorias.map(item => (
          ((item.CATEGORIA.ID === codigoCategoria) && 
            (item.DESCRICAO.indexOf(descricaoCategoriaFiltro.toUpperCase()) !== -1)) &&
              arrSubCategorias.push(item)
        ))
      }
      setSubCategoriasFiltro(arrSubCategorias)                
    }  
  }, [categorias, subCategorias, codigoCategoriaFiltro, codigoSubCategoriaFiltro, descricaoFiltro,
    codigoCategoria, descricaoCategoria, descricaoCategoriaFiltro, descricaoSubCategoriaFiltro,                
    primeiroAcesso, processo, servicos, usuario.COORDENADAS, usuario.CPF_CNPJ, usuario.ID,
    usuario.SENHA])

  function salvar(){
    var retornoJson = []
    var jsonServico_New = {}
    var jsonLogin = "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ +"\", \"SENHA\": \""+ usuario.SENHA +"\" }"

    jsonServico_New = "{ "
    if (codigo !== "") jsonServico_New += "\"ID\": "+ codigo
    if (jsonServico_New !== "{ ") jsonServico_New += ", "
    jsonServico_New += "\"USUARIO\": { \"ID\": "+ usuario.ID +" } "
    if (codigoCategoria !== "") jsonServico_New += ", \"SUB_CATEGORIA\": { \"ID\": "+ codigoSubCategoria +", \"DESCRICAO\": \""+ descricaoSubCategoria +"\" } "        

    if (descricao !== "") {
      jsonServico_New += ", \"DESCRICAO\": \""+ descricao + "\" "
      jsonServico_New += ", \"DESCRICAO_PESQUISA\": \""+ descricaoCategoria + " " + descricaoSubCategoria + " " + descricao + "\" "
    }

    if (valor !== "") jsonServico_New += ", \"VALOR\": "+ valor + " "
    if (restricaoLocalidade !== "") jsonServico_New += ", \"RESTRICAO_LOCALIDADE\": "+ restricaoLocalidade
    jsonServico_New += " }"                

    if (codigo === "")
    {
      setMensagemErro("")
      setCarregando("Aguarde incluindo o registro...")
                
      api.post("SERVICO/Incluir?jsonLogin="+ jsonLogin +"&jsonServico="+ jsonServico_New)
      .then((response) => { retornoJson = JSON.parse(response.data) })
      .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível comunicar com a WebAPI ("+ response +")\"}") })
      .finally(() => {
        if (retornoJson.CONCRETIZADO === false){
          setMensagemErro(retornoJson.ERRO)
          setCarregando('')
          setMensagem('')
          return
        }
        else
        {
          setMensagem("Inclusão efetuada com sucesso (" + JSON.parse(retornoJson.RESULTADO).DESCRICAO +")!")
          setMensagemErro("")

          setCodigo(JSON.parse(retornoJson.RESULTADO).ID)
            setDescricao(JSON.parse(retornoJson.RESULTADO).DESCRICAO)
        }
        setCarregando("")
      })            
    }
    else 
    {
      setMensagemErro("")
      setCarregando("Aguarde alterando o registro...")

      api.put("SERVICO/Alterar?jsonLogin="+ jsonLogin +"&jsonServicoAntigo={}"+
        "&jsonServicoNovo=" + jsonServico_New)
      .then((response) => { retornoJson = JSON.parse(response.data) })
      .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível comunicar com a WebAPI ("+ response +")\"}") })
      .finally(() => {
        if (retornoJson.CONCRETIZADO === false){
          setMensagemErro(retornoJson.ERRO)
          setCarregando('')
          setMensagem('')
          return
        }
        else
        {                                                        
          setMensagem("Alteração efetuada com sucesso (" + JSON.parse(retornoJson.RESULTADO).DESCRICAO +")!")
          setMensagemErro("")
          setDescricao(JSON.parse(retornoJson.RESULTADO).DESCRICAO)                    
        }
        setCarregando("")
      })
    }
  }

  const adicionar = () => {
    setProcesso(2)
  }

  const excluir = (item) => {
    var retornoJson = []
    var jsonServico_New = {}
    var jsonLogin = "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ +"\", \"SENHA\": \""+ usuario.SENHA +"\" }"

    jsonServico_New = "{ \"ID\": "+ item.ID + " }"

    setMensagemErro("")
    setCarregando("Aguarde excluindo o registro...")

    api.delete("SERVICO/Excluir?jsonLogin="+ jsonLogin + "&jsonServico=" + jsonServico_New)
    .then((response) => { retornoJson = JSON.parse(response.data) })
    .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível excluir o serviço ("+ response +")\"}") })
    .finally(() => {
      if (retornoJson.CONCRETIZADO === false){
        setMensagemErro(retornoJson.ERRO)
        setCarregando('')
        setMensagem('')
        return
      }
      else
      {                                                        
        setMensagem("Serviço excluído com sucesso (" + item.DESCRICAO +")!")
        setMensagemErro("")
        setPrimeiroAcesso(true)
      }
      setCarregando("")
    })        
  }

  const formatNumeric = (value, decimals) => {
    if (!value) {
      return ""
    }

    if (value === ".") {
      return value = "0."
    }
      
    var regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)
    const decimalsNumber = value.toString().match(regex)[0]
    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) {
      return "0"
    }
    return parsed
  }    

  function selecionar(event) {        
    setCodigo(event.ID)
    setCodigoCategoria(event.SUB_CATEGORIA.CATEGORIA.ID)
    setDescricaoCategoria(event.SUB_CATEGORIA.CATEGORIA.DESCRICAO)
    setCodigoSubCategoria(event.SUB_CATEGORIA.ID)
    setDescricaoSubCategoria(event.SUB_CATEGORIA.DESCRICAO)
    setDescricao(event.DESCRICAO)
    setValor(formatNumeric(event.VALOR, 2))
    setRestricaoLocalidade(event.RESTRICAO_LOCALIDADE)
    setProcesso(4)
  }

  function setCategoria(event) {
    var categoria = event

    var subCategoria = "{\"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\"}"
    subCategorias.map(item => ( 
      (parseInt(categoria) === item.CATEGORIA.ID) ?
        subCategoria += ",{\"ID\": "+ item.ID +", \"VALOR\": \""+ item.DESCRICAO +"\"}" : ""
    ))
    subCategoria += "]}"

    setOptionsSubCategoria(subCategoria)
    setCodigoCategoriaFiltro(categoria)
  }

  function voltarCategoria(event) {
    setProcesso(1)
  }

  const selecionarCategoria = (item) => {
    setCodigoCategoria(item.ID)
    setDescricaoCategoria(item.DESCRICAO)
    setProcesso(3)
  }

  const selecionarSubCategoria = (itens) => {
    setCodigoSubCategoria(itens.ID)
    setDescricaoSubCategoria(itens.DESCRICAO)
    setProcesso(4)
  }
    
  const voltarSubCategoria = (itens) => {
    subCategorias.map(item => (
      item.SELECIONADO = false
    ))

    setCodigoCategoriaFiltro("")
    setDescricaoCategoriaFiltro("")
    setCodigoCategoria("")
    setDescricaoCategoria("")
    setProcesso(2)
  }    

  return(
    <div className="main_Servico">
      <div id="cabecalho_Servico">
        <div id="cabecalhoMenu_Servico">
          <img src={Img_menu} alt="Menu" onClick={() => chamarMenu()} />
        </div>
        <div id="cabecalhoTitulo_Servico">
          <label id="titulo_Servico">CADASTRO</label><label id="tituloSub_Servico">SERVIÇOS</label>
        </div>
        <div id="cabecalhoLogo_Servico">
          <img src={Img_logo_topo} alt="Logo" />
        </div>
      </div>

      {/* 1 - Pesquisa  */}
      { (processo === 1) &&
        <div className="pesquisa_main_Servico">
          { (carregando !== "") &&
            <div id="pesquisa_carregando_Servico">
              <Spinner animation="border" variant="info" />
              <label id="pesquisa_carregandoTexto_Servico">{carregando}</label>
            </div>
          }

          { (carregando === "") &&
            <div className="pesquisa_main_Servico">
              { !mostrarFiltro &&
                <div id="pesquisa_filtroRecolhido_Servico" onClick={() => setMostrarFiltro(true)}>
                  <div id="pesquisa_filtroRecolhidoFiltro_Servico">
                    <img src={Img_filtro_branco} id="pesquisa_filtroRecolhidoFiltroImagem_Servico" alt="Filtro" />
                  </div>
                  <div id="pesquisa_filtroRecolhidoDescricao_Servico">
                    <img src={Img_estender} id="pesquisa_filtroRecolhidoDescricaoImagem_Servico" alt="Estender" />
                    <label id="pesquisa_filtroRecolhidoDescricaoTexto_Servico">Mostrar Filtros</label>
                  </div>
                </div>
              }

              { mostrarFiltro &&
                <>
                  { (width < 700) &&
                    <div id="pesquisa_filtro_Servico">
                      <div id="pesquisa_filtroFiltro_Servico">
                        <img src={Img_filtro_branco} id="pesquisa_filtroFiltroImagem_Servico" alt="Filtro" />
                      </div>
                      <div className="pesquisa_filtroCampos_Servico">
                        <div className="pesquisa_filtroCampos_Servico marginTop10_Servico">
                          <Select
                            title="Categoria: "
                            name="categoria_Servico"
                            options={JSON.parse(optionsCategoria)}
                            disabled={false}
                            value={codigoCategoriaFiltro}
                            onChange={(itemValue) => setCategoria(itemValue)} />
                        </div>
                        <div className="pesquisa_filtroCampos_Servico">
                          <Select
                            title="Serviço: "
                            name="subCategoria_Servico"
                            options={JSON.parse(optionsSubCategoria)}
                            disabled={false}
                            value={codigoSubCategoriaFiltro}
                            onChange={(itemValue) => setCodigoSubCategoriaFiltro(itemValue)} />
                        </div>
                        <div className="pesquisa_filtroCampos_Servico marginTop10_Servico marginBottom10_Servico">
                          <Input 
                            type="search"
                            title=""
                            name="descricao_Servico"
                            disabled={false}
                            maxLength={120}
                            placeholder="Descrição do Serviço"
                            typeImage={0}
                            withBorder={true}
                            value={descricaoFiltro || ""}
                            onChange={(event) => setDescricaoFiltro(event.target.value)} />
                        </div>
                        <div id="pesquisa_filtroRecolher_Servico" onClick={() => setMostrarFiltro(false)}>
                          <div id="pesquisa_filtroRecolherView_Servico">
                            <img src={Img_recolher} id="pesquisa_filtroRecolherImagem_Servico" alt="Recolher" />
                            <label id="pesquisa_filtroRecolherTexto_Servico">Recolher Filtros</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  { (width >= 700) &&
                    <div id="pesquisa_filtro_Servico">
                      <div id="pesquisa_filtroFiltro_Servico">
                        <img src={Img_filtro_branco} id="pesquisa_filtroFiltroImagem_Servico" alt="Filtro" />
                      </div>
                      <div className="pesquisa_filtroCampos_Servico">
                        <div className="pesquisa_filtroCampo_Servico">
                          <div className="pesquisa_filtroCampos_Servico">
                            <Select
                              title="Categoria: "
                              name="categoria_Servico"
                              options={JSON.parse(optionsCategoria)}
                              disabled={false}
                              value={codigoCategoriaFiltro}
                              onChange={(itemValue) => setCategoria(itemValue)} />
                          </div>
                          <div className="pesquisa_filtroCampos_Servico marginLeft5_Servico">
                            <Select
                              title="Serviço: "
                              name="subCategoria_Servico"
                              options={JSON.parse(optionsSubCategoria)}
                              disabled={false}
                              value={codigoSubCategoriaFiltro}
                              onChange={(itemValue) => setCodigoSubCategoriaFiltro(itemValue)} />
                          </div>
                        </div>
                        <div className="pesquisa_filtroCampos_Servico">
                          <Input 
                            type="search"
                            title=""
                            name="descricao_Servico"
                            disabled={false}
                            maxLength={120}
                            placeholder="Descrição do Serviço"
                            typeImage={0}
                            withBorder={true}
                            value={descricaoFiltro || ""}
                            onChange={(event) => setDescricaoFiltro(event.target.value)} />
                        </div>
                        <div id="pesquisa_filtroRecolher_Servico" onClick={() => setMostrarFiltro(false)}>
                          <div id="pesquisa_filtroRecolherView_Servico">
                            <img src={Img_recolher} id="pesquisa_filtroRecolherImagem_Servico" alt="Recolher" />
                            <label id="pesquisa_filtroRecolherTexto_Servico">Recolher Filtros</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </>
              }

              { (mensagem !== "") &&
                <div id="pesquisa_mensagem_Servico">
                  <label id="pesquisa_mensagemTexto_Servico">{mensagem}</label>
                </div>
              }

              { (mensagemErro !== "") &&
                <div id="pesquisa_mensagemErro_Servico">
                  <label id="pesquisa_mensagemErroTexto_Servico">{mensagemErro}</label>
                </div>
              }

              { mostrarResultado &&
                <div className="pesquisa_main_Servico">
                  <div id="pesquisa_servicos_Servico">
                    { servicosSelecionados.map(item => (
                      <div key={"servico"+ item.ID} className="pesquisa_servico_Servico">
                        <div className="pesquisa_categoria_Servico">
                          <div className="pesquisa_categoriaDescricao_Servico" onClick={() => selecionar(item)}>
                            <label>{item.SUB_CATEGORIA.CATEGORIA.DESCRICAO}</label>
                          </div>
                          <div className="pesquisa_categoriaExcluir_Servico">
                            <img src={Img_excluir} alt="Excluir" className="pesquisa_categoriaExcluirBotao_Servico" onClick={(() => { if (window.confirm("Deseja realmente excluir o servico ?")) excluir(item) })} />
                          </div>
                        </div>
                        <div className="pesquisa_subCategoria_Servico" onClick={() => selecionar(item)}>
                          {item.SUB_CATEGORIA.DESCRICAO}
                        </div>
                        <div className="pesquisa_titulo_Servico" onClick={() => selecionar(item)}>
                          <label className="pesquisa_tituloServico_Servico">{item.DESCRICAO}</label>                                                
                        </div>                                            
                      </div>                            
                      ))
                    }

                    { servicosSelecionados.length === 0 &&
                      <div id="pesquisa_sem_Servico">
                        { ((codigoCategoriaFiltro || codigoSubCategoriaFiltro) || descricaoFiltro) &&
                          <label id="pesquisa_semTexto_Servico">Nenhum serviço encontrado com o filtro informado!</label>
                        }

                        { !codigoCategoriaFiltro && !codigoSubCategoriaFiltro && !descricaoFiltro &&
                          <label id="pesquisa_semTexto_Servico">Nenhum serviço cadastrado!</label>
                        }
                      </div>
                    }
                  </div>

                  <div id="pesquisa_botoes_Servico">
                      <img className="pesquisa_botao_Servico" src={Img_progredir} alt="progredir" onClick={() => chamarListaServicos()} />
                      <img className="pesquisa_botao_Servico" src={Img_adicionar} alt="prosseguir" onClick={() => adicionar()} />
                  </div>
                </div>
              }
            </div>
          }
        </div>
      }

      {/* 2 - Categoria */}
      { (processo === 2) &&
        <div className="categoria_main_Servico">
          { (carregando !== "") &&
            <div id="categoria_carregando_Servico">
              <Spinner animation="border" variant="info" />
              <label id="categoria_carregandoTexto_Servico">{carregando}</label>
            </div>
          }

          { (carregando === "") &&
            <div className="categoria_main_Servico">
              { (mensagem !== "") &&
                <div id="categoria_mensagem_Servico">
                  <label id="categoria_mensagemTexto_Servico">{mensagem}</label>
                </div>
              }

              { (mensagemErro !== "") &&
                <div id="categoria_mensagemErro_Servico">
                  <label id="categoria_mensagemErroTexto_Servico">{mensagemErro}</label>
                </div>
              }

              { !mostrarFiltro &&
                <div id="categoria_filtroRecolhido_Servico" onClick={() => setMostrarFiltro(true)}>
                  <div id="categoria_filtroRecolhidoFiltro_Servico">
                    <img src={Img_filtro_branco} id="categoria_filtroRecolhidoFiltroImagem_Servico" alt="Filtro" />
                  </div>
                  <div id="categoria_filtroRecolhidoDescricao_Servico">
                    <img src={Img_estender} id="categoria_filtroRecolhidoDescricaoImagem_Servico" alt="Estender" />
                    <label id="categoria_filtroRecolhidoDescricaoTexto_Servico">Mostrar Filtros</label>
                  </div>
                </div>
              }

              { mostrarFiltro &&
                <div id="categoria_filtro_Servico">
                  <div id="categoria_filtroFiltro_Servico">
                    <img src={Img_filtro_branco} id="categoria_filtroFiltroImagem_Servico" alt="Filtro" />
                  </div>
                  <div id="categoria_filtroCampos_Servico">
                    <div id="categoria_filtroCampo_Servico">
                      <Input 
                        type="search"
                        title=""
                        name="descricaoCategoria_Servico"
                        disabled={false}
                        maxLength={50}
                        placeholder="Descrição da Categoria"
                        typeImage={0}
                        withBorder={true}
                        value={descricaoCategoriaFiltro || ""}
                        onChange={(event) => setDescricaoCategoriaFiltro(event.target.value)} />
                    </div>
                    <div id="categoria_filtroRecolher_Servico" onClick={() => setMostrarFiltro(false)}>
                      <div id="categoria_filtroRecolherView_Servico">
                        <img src={Img_recolher} id="categoria_filtroRecolherImagem_Servico" alt="Recolher" />
                        <label id="categoria_filtroRecolherTexto_Servico">Recolher Filtros</label>
                      </div>
                    </div>
                  </div>
                </div>
              }

              { categoriasFiltro &&
                <>
                  { (categoriasFiltro !== undefined) && (categoriasFiltro.length !== 0) &&
                    <>
                      <div id="categoria_titulo_Servico">
                        <img src={Img_titulo_categoria} alt="Titulo" />
                      </div>
                      <div id="categoria_dados_Servico">
                        { categoriasFiltro.map(item => (
                          <div key={"cat" + item.ID} id="catetoria_card_Servico" onClick={() =>  selecionarCategoria(item)}>
                            <CategoriaCard categoria={item} />
                          </div>
                          )) 
                        }
                      </div>
                    </>
                  }

                  { ((categoriasFiltro === undefined) || (categoriasFiltro.length === 0)) &&
                    <div id="categoria_dadosSem_Servico">
                      <label id="categoria_dadosSemTexto_Servico">Nenhuma categoria encontrada com o filtro "{descricaoCategoria}"!</label>
                    </div>
                  } 
                </>
              }

              <div id="categoria_botoes_Servico">
                <img className="categoria_botao_Servico" src={Img_progredir} alt="progredir" onClick={() => voltarCategoria()}/>
              </div>
            </div>
          }
        </div>
      }
      
      {/* 3 - Sub-Categoria */}
      { (processo === 3) &&
        <div className="subCategoria_main_Servico">
          { (carregando !== "") &&
            <div id="subCategoria_carregando_Servico">
              <Spinner animation="border" variant="info" />
              <label id="subCategoria_carregandoTexto_Servico">{carregando}</label>
            </div>
          }

          { (carregando === "") &&
            <div className="subCategoria_main_Servico">
              { (mensagem !== "") &&
                <div id="subCategoria_mensagem_Servico">
                  <label id="subCategoria_mensagemTexto_Servico">{mensagem}</label>
                </div>
              }

              { (mensagemErro !== "") &&
                <div id="subCategoria_mensagemErro_Servico">
                  <label id="subCategoria_mensagemErroTexto_Servico">{mensagemErro}</label>
                </div>
              }

              { !mostrarFiltro &&
                <div id="subCategoria_filtroRecolhido_Servico" onClick={() => setMostrarFiltro(true)}>
                  <div id="subCategoria_filtroRecolhidoFiltro_Servico">
                    <img src={Img_filtro_branco} id="subCategoria_filtroRecolhidoFiltroImagem_Servico" alt="Filtro" />
                  </div>
                  <div id="subCategoria_filtroRecolhidoDescricao_Servico">
                    <img src={Img_estender} id="subCategoria_filtroRecolhidoDescricaoImagem_Servico" alt="Estender" />
                    <label id="subCategoria_filtroRecolhidoDescricaoTexto_Servico">Mostrar Filtros</label>
                  </div>
                </div>
              }

              { mostrarFiltro &&
                <div id="subCategoria_filtro_Servico">
                  <div id="subCategoria_filtroFiltro_Servico">
                    <img src={Img_filtro_branco} id="subCategoria_filtroFiltroImagem_Servico" alt="Filtro" />
                  </div>
                  <div id="subCategoria_filtroCampos_Servico">
                    <div id="subCategoria_filtroCampo_Servico">
                      <Input 
                        type="search"
                        title=""
                        name="descricaoSubCategoria_Servico"                      
                        disabled={false}                      
                        maxLength={50}
                        placeholder="Descrição da Sub-Categoria"
                        typeImage={0}
                        withBorder={true}
                        value={descricaoSubCategoriaFiltro || ""}
                        onChange={(event) => setDescricaoSubCategoriaFiltro(event.target.value)} />
                    </div>
                    <div id="subCategoria_filtroRecolher_Servico" onClick={() => setMostrarFiltro(false)}>
                      <div id="subCategoria_filtroRecolherView_Servico">
                        <img src={Img_recolher} id="subCategoria_filtroRecolherImagem_Servico" alt="Recolher" />
                        <label id="subCategoria_filtroRecolherTexto_Servico">Recolher Filtros</label>
                      </div>
                    </div>
                  </div>  
                </div>
              }

              { subCategoriasFiltro &&
                <div className="subCategoria_main_Servico">
                  { ((subCategoriasFiltro !== undefined) && (subCategoriasFiltro.length !== 0)) &&
                    <>
                      <div id="subCategoria_titulo_Servico">
                        <img src={Img_titulo_sub_categoria} alt="Titulo" />
                      </div>
                      <div id="subCategoria_dados_Servico">
                        <SubCategoriaCard
                          codigoCategoria={codigoCategoria} 
                          subCategorias={subCategoriasFiltro} 
                          descricaoSubCategoria={descricaoSubCategoriaFiltro}
                          setSelecionar={selecionarSubCategoria}
                          setRetornar={voltarSubCategoria} 
                          tipo={2} />
                      </div>
                    </>
                  }

                  { ((subCategoriasFiltro === undefined) || (subCategoriasFiltro.length === 0)) &&
                    <div id="subCategoria_dadosSem_Servico">
                      <label id="subCategoria_dadosSemTexto_Servico">Nenhuma sub-categoria encontrada...</label>
                    </div>
                  }
                </div>
              }
            </div>
          }
        </div>
      }

      {/* 4 - Cadastro */}
      { (processo === 4) &&
        <div className="cadastro_main_Servico">
          <div className="cadastro_addColumns_Servico">
            <div className="cadastro_containerCampo_Servico">
              <Input 
                type="text"
                title="Categoria:"
                name="descricaoCategoria_Servico"
                disabled={true}
                maxLength={120}
                placeholder=""
                typeImage={0}
                value={descricaoCategoria || ""}
                onChange={(event) => setDescricaoCategoria(event.target.value)} />
            </div>
          </div>

          <div className="cadastro_addColumns_Servico">
            <div className="cadastro_containerCampo_Servico">
              <Input 
                type="text"
                title="Serviço:"
                name="descricaoSubCategoria_Servico"
                disabled={true}
                maxLength={120}
                placeholder=""
                typeImage={0}
                value={descricaoSubCategoria || ""}
                onChange={(event) => setDescricaoSubCategoria(event.target.value)} />
            </div>
          </div>

          <div className="cadastro_addColumns_Servico">
            <div className="cadastro_containerCampo_Servico">
              <Input 
                type="text"
                title="Descrição:"
                name="descricao_Servico"
                disabled={false}
                maxLength={120}
                placeholder=""
                typeImage={0}
                value={descricao || ""}
                onChange={(event) => setDescricao(event.target.value)} />
            </div>
          </div>

          { width < 420 &&
            <>
              <div className="cadastro_addColumns_Servico">
                <div className="cadastro_containerCampo_Servico">
                  <div className="cadastro_containerCampo_Servico">
                    <InputNumeric 
                      title="Valor"
                      name="valor_Servico"
                      disabled={false}
                      typeImage={0}
                      placeholder=""
                      maxLength={13}
                      value={ valor || ''}
                      onChange={(event) => setValor(event.target.value)} />
                  </div>
                </div>
              </div>
              <div className="cadastro_addColumns_Servico">
                <div className="cadastro_containerCampo_Servico">
                  <div className="cadastro_containerCampo_Servico">
                    <Select 
                      title="Restrição: "
                      name="restricaoLocalidade_Servico"
                      options={JSON.parse("{ \"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\" }, {\"ID\": 1, \"VALOR\": \"Não\" }, {\"ID\": 2, \"VALOR\": \"Até 5KM\" }, {\"ID\": 3, \"VALOR\": \"Até 15KM\"}, {\"ID\": 4, \"VALOR\": \"Até 50KM\" }] }")}
                      disabled={false}
                      value={restricaoLocalidade}
                      onChange={setRestricaoLocalidade} />
                  </div>
                </div>
              </div>
            </>
          }

          { width >= 420 &&
            <div className="cadastro_addColumns_Servico">
              <div className="cadastro_containerCampo_Servico">
                <div className="cadastro_containerCampo_Servico">
                  <InputNumeric 
                    title="Valor"
                    name="valor_Servico"
                    disabled={false}
                    typeImage={0}
                    placeholder=""
                    maxLength={13}
                    value={ valor || ''}
                    onChange={(event) => setValor(event.target.value)} />
                </div>
              </div>
              <div className="cadastro_containerCampo_Servico marginLeft5_Servico">
                <div className="cadastro_containerCampo_Servico">
                  <Select 
                    title="Restrição: "
                    name="restricaoLocalidade_Servico"
                    options={JSON.parse("{ \"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\" }, {\"ID\": 1, \"VALOR\": \"Não\" }, {\"ID\": 2, \"VALOR\": \"Até 5KM\" }, {\"ID\": 3, \"VALOR\": \"Até 15KM\"}, {\"ID\": 4, \"VALOR\": \"Até 50KM\" }] }")}
                    disabled={false}
                    value={restricaoLocalidade}
                    onChange={setRestricaoLocalidade} />
                </div>
              </div>
            </div>
          }          

          { codigo &&
            <>
              <br/>
              <FotoServico usuario={usuario} servico={ JSON.parse("{ \"ID\": "+ codigo + " }") } />
            </>
          }

          { (mensagem !== "") &&
            <div id="cadastro_mensagem_Servico">
              <label id="cadastro_mensagemTexto_Servico">{mensagem}</label>
            </div>
          }

          { (mensagemErro !== "") &&
            <div id="cadastro_mensagemErro_Servico">
              <label id="cadastro_mensagemErroTexto_Servico">{mensagemErro}</label>
            </div>
          }

          <div id="cadastro_botoes_Servico">
            <img className="cadastro_botao_Servico" src={Img_progredir} alt="progredir" onClick={() => setPrimeiroAcesso(true)}/>
              <img className="cadastro_botao_Servico marginLeft5_Servico" src={Img_salvar} alt="progredir" onClick={() => salvar()}/>
          </div>
        </div>
      }
    </div>
  )
}

export default Servico