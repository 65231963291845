import "./style.css"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { Carousel } from "react-bootstrap"
import Spinner from 'react-bootstrap/Spinner'
import Input from "../../Components/Input"
import Select from "../../Components/Select"
import CategoriaCard from "../CategoriaCard"
import SubCategoriaCard from "../SubCategoriaCard"
import EnlargePhoto from "../EnlargePhoto"
import Img_arrow_left from "../../Assets/Img/arrow_left.png"
import Img_arrow_right from "../../Assets/Img/arrow_right.png"
import Img_boneco_perfil from "../../Assets/Img/boneco_perfil.png"
import Img_email_perfil from "../../Assets/Img/email_perfil.png"
import Img_estender from "../../Assets/Img/estender.png"
import Img_filtro_branco from "../../Assets/Img/filtro_branco.png"
import Img_logo_topo from "../../Assets/Img/logo_topo_colorida.png"
import Img_mais_informacao from "../../Assets/Img/Botoes/mais_informacao.png"
import Img_menu from "../../Assets/Img/Botoes/menu.png"
import Img_progredir from "../../Assets/Img/Botoes/progredir.png"
import Img_recolher from "../../Assets/Img/recolher.png"
import Img_sem_foto from "../../Assets/Img/sem_foto.png"
import Img_sem_foto_servico from "../../Assets/Img/sem_foto_servico.png"
import Img_star_all from "../../Assets/Img/star_all.png"
import Img_star_half from "../../Assets/Img/star_half.png"
import Img_star_empty from "../../Assets/Img/star_empty.png"
import Img_telefone_perfil from "../../Assets/Img/telefone_perfil.png"
import Img_titulo_categoria from "../../Assets/Img/Titulos/categoria.png"
import Img_titulo_sub_categoria from "../../Assets/Img/Titulos/sub_categoria.png"
import Img_usuario_servico_prestado from "../../Assets/Img/usuario_servico_prestado.png"
import Img_whatsapp from "../../Assets/Img/whatsapp.png"

const api = axios.create({
  baseURL: "https://api.brconnect.com.br/api/",
  headers: {                
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json;charset=utf-8"
  }
})

const ListaServico = ({ usuario, categorias, subCategorias, chamarMenu, width }) => {
  const [categoriasFiltro, setCategoriasFiltro] = useState([categorias])
  const [codigoCategoria, setCodigoCategoria] = useState(0)
  const [descricaoCategoria, setDescricaoCategoria] = useState("")
  const [descricaoServico, setDescricaoServico] = useState("")
  const [descricaoSubCategoria, setDescricaoSubCategoria] = useState("")
  const [nomeUsuario, setNomeUsuario] = useState("")
  const [servicosExecutados, setServicosExecutados] = useState([])
  const [subCategoriasFiltro, setSubCategoriasFiltro] = useState([])
  const [subCategoriasSelecionadas, setSubCategoriasSelecionadas] = useState([])
  const [usuarioSelecionado, setUsuarioSelecionado] = useState({})    
  const [usuarios, setUsuarios] = useState([])    
  const [usuariosServicos, setUsuariosServicos] = useState([])          
    
  // 1 - Categoria , 2 - Sub-Categoria, 3 - Prestadores, 4 - Detalhe Prestador
  const [processo, setProcesso] = useState(1)

  const [mostrarFiltro, setMostrarFiltro] = useState(false)
  const [mostrarResultado, setMostrarResultado] = useState(false)
  const [pesquisaDireta, setPesquisaDireta] = useState(false)
  const [primeiraBusca, setPrimeiraBusca] = useState(false)
  const [atualizar, setAtualizar] = useState(0)

  const optionsRegPorPagina = "{\"RESULTADO\": [{\"ID\": 12, \"VALOR\": \"12\"}, {\"ID\": 24, \"VALOR\": \"24\"}, {\"ID\": 36, \"VALOR\": \"36\" }, {\"ID\": 100, \"VALOR\": \"Todos\" }]}"
  const optionsOrdenar = "{\"RESULTADO\": [{\"ID\": \"0\",\"VALOR\": \"Padrão\"}, {\"ID\": \"1\",\"VALOR\": \"Nome\"}, {\"ID\": \"2\", \"VALOR\": \"Preço Maior\"}, {\"ID\": \"3\", \"VALOR\": \"Preço Menor\"}, {\"ID\": \"4\", \"VALOR\": \"Tot. Avaliações\"}, {\"ID\": \"5\", \"VALOR\": \"Avaliação\"}]}"
  const [paginaAtual, setPaginaAtual] = useState(1)
  const [regPorPagina, setRegPorPagina] = useState(12)
  const [totalRegistro, setTotalRegistro] = useState(0)
  const [totalPagina, setTotalPagina] = useState(0)
  const [ordenar, setOrdenar] = useState(0)

  const [showEnlargePhoto, setShowEnlargePhoto] = useState(false)
  const [listPhoto, setListPhoto] = useState([])

  const [carregando, setCarregando] = useState(null)
  const [mensagem, setMensagem] = useState("")
  const [mensagemErro, setMensagemErro] = useState("")

  useEffect(() => {
    if (processo === 1) {
      setCarregando("Buscando as categorias, Aguarde...")
      var arrCategorias = []

      if (descricaoCategoria === "") {
        categorias.map(item => (
          (item.QTDE_SERVICO > 0) && arrCategorias.push(item)                    
        ))
      }
      else {
        categorias.map(item => (
          (item.DESCRICAO.indexOf(descricaoCategoria.toUpperCase()) !== -1) &&
            (item.QTDE_SERVICO > 0) && arrCategorias.push(item)
        ))
      }

      setCategoriasFiltro(arrCategorias)
      setCarregando("")
    }

    if (processo === 2) {
      setCarregando("Buscando os serviços, Aguarde...")
      var arrSubCategorias = []

      if (descricaoSubCategoria === "") {
        subCategorias.map(item => (
          (item.CATEGORIA.ID === codigoCategoria) && 
            (item.QTDE_SERVICO > 0) && arrSubCategorias.push(item)                    
        ))
      }
      else {
        subCategorias.map(item => (
          ((item.CATEGORIA.ID === codigoCategoria) && 
            (item.DESCRICAO.indexOf(descricaoCategoria.toUpperCase()) !== -1)) &&
              (item.QTDE_SERVICO > 0) && arrSubCategorias.push(item)
        ))
      }

      setSubCategoriasFiltro(arrSubCategorias)
      setCarregando("")
    }

    if (processo === 3) {
      if (!primeiraBusca){
        setCarregando("Buscando os prestadores, Aguarde...")

        var retornoJson = []
        var jsonLogin = "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ +"\", \"SENHA\": \""+ usuario.SENHA +"\", \"COORDENADAS\": \""+ usuario.COORDENADAS +"\" }"

        var jsonServico = "{ "    
        jsonServico += " \"SUB_CATEGORIAS\": \""+ subCategoriasSelecionadas +"\""
        if (nomeUsuario !== "") jsonServico += ", \"USUARIO\": { \"NOME\": \""+ nomeUsuario +"\" }"
        if (descricaoServico !== "") jsonServico += ", \"DESCRICAO_PESQUISA\": \""+ descricaoServico +"\""
        jsonServico += ", \"ORDENAR\": "+ ordenar
        jsonServico += " }"

        api.get("SERVICO/TotalRegistros_Listar?jsonLogin=" + jsonLogin + "&jsonServico=" + jsonServico)
        .then((response) => { retornoJson = JSON.parse(response.data) })
        .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível listar os serviços ("+ response +")\"}") })
        .finally(() => {                 
          if (retornoJson.CONCRETIZADO === false){
            setCarregando("")
            setMensagem("")
            setMensagemErro(retornoJson.ERRO)
            setMostrarResultado(false)
            return
          }
          else
          {
            var tRegistro = retornoJson.RESULTADO
            if (tRegistro === 0)
            {
              setCarregando("")
              setMensagem("")
              setMensagemErro("Nenhum prestador encontrado para o serviço selecionado.")
              setMostrarResultado(false)
              return
            }

            if (optionsRegPorPagina === 100)
            {
              api.get("SERVICO/Listar?jsonLogin=" + jsonLogin + "&jsonServico=" + jsonServico + 
                "&iQtdeInicial=0&iQtdeRegistros=" + tRegistro)
              .then((response) => { retornoJson = JSON.parse(response.data) })
              .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível listar os serviços ("+ response +")\"}") })
              .finally(() => {
                if (retornoJson.CONCRETIZADO === false){
                  setCarregando("")
                  setMensagem("")
                  setMensagemErro(retornoJson.ERRO)            
                  setMostrarResultado(false)
                  return
                }
                else
                {
                  setMensagem("")
                  setMensagemErro("")                                
            
                  var usu = []
                  retornoJson.RESULTADO.map(item => (
                    (!possuiVendedor(usu, item)) && 
                      (restricaoLocalidade(item)) && 
                        usu.push({ 
                          ID: item.USUARIO.ID,
                          NOME: item.USUARIO.NOME,
                          CAMINHO_FOTO: item.USUARIO.CAMINHO_FOTO,
                          WHATSAPP: item.USUARIO.WHATSAPP,
                          TELEFONE: item.USUARIO.TELEFONE,
                          EMAIL: item.USUARIO.EMAIL,
                          DISTANCIA: item.DISTANCIA })
                  ))

                  var srv = []
                  retornoJson.RESULTADO.map(item => (
                      srv.push(item)
                  ))

                  setUsuarios(usu)
                  setUsuariosServicos(srv)
                  setMostrarResultado(true)
                }
              
                setCarregando("")
              })
                          
              setTotalRegistro(tRegistro)
              setTotalPagina(1)
              if (paginaAtual !== 1) setPaginaAtual(1)
            }
            else
            {
              var operacao = tRegistro / regPorPagina
              var numero = Math.floor(operacao)
              var decimal = operacao % 1

              var tPagina = 0
              if (decimal > 0) tPagina = numero + 1
              else tPagina = numero
              
              setTotalRegistro(tRegistro)
              setTotalPagina(tPagina)
              if (tPagina < paginaAtual) setPaginaAtual(1)

              var qtdeInicial = 0
              if (paginaAtual > 1) qtdeInicial = ((paginaAtual - 1) * regPorPagina)
          
              api.get("SERVICO/Listar?jsonLogin=" + jsonLogin + "&jsonServico=" + jsonServico + 
                "&iQtdeInicial=" + qtdeInicial + "&iQtdeRegistros=" + regPorPagina)
              .then((response) => { retornoJson = JSON.parse(response.data) })
              .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível listar os serviços ("+ response +")\"}") })
              .finally(() => {
                if (retornoJson.CONCRETIZADO === false){
                  setCarregando("")
                  setMensagem("")
                  setMensagemErro(retornoJson.ERRO)            
                  setMostrarResultado(false)
                  return
                }
                else
                {
                  setMensagem("")
                  setMensagemErro("")
          
                  var usu = []
                  retornoJson.RESULTADO.map(item => (
                    (!possuiVendedor(usu, item)) && 
                      usu.push({ 
                        ID: item.USUARIO.ID,
                        NOME: item.USUARIO.NOME,
                        CAMINHO_FOTO: item.USUARIO.CAMINHO_FOTO,
                        WHATSAPP: item.USUARIO.WHATSAPP,
                        TELEFONE: item.USUARIO.TELEFONE,
                        EMAIL: item.USUARIO.EMAIL,
                        DISTANCIA: item.DISTANCIA })
                  ))

                  var srv = []
                  retornoJson.RESULTADO.map(item => (
                    srv.push(item)
                  ))

                  setUsuarios(usu)
                  setUsuariosServicos(srv)
                  setMostrarResultado(true)
                }
              
                setCarregando("")
              })
            }

            setMensagem("")
            setMensagemErro("")
          }
        })
      } else setPrimeiraBusca(false)
    }

    if (processo === 4) {
      setCarregando("Buscando os serviços prestados, Aguarde...")

      var retornoJsonS = []
      var jsonLoginS = "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ +"\", \"SENHA\": \""+ usuario.SENHA +
      "\", \"COORDENADAS\": \""+ usuario.COORDENADAS +"\" }"

      var jsonServicoExecutado = "{ "        
      jsonServicoExecutado += "\"SERVICO\": { \"USUARIO\": { \"ID\": "+ usuarioSelecionado.ID +" } }"
      jsonServicoExecutado += ", \"APENAS_AVALIADOS\": 1"
      jsonServicoExecutado += ", \"SUB_CATEGORIAS\": \""+ subCategoriasSelecionadas +"\""
      jsonServicoExecutado += " }"

      api.get("SERVICO_EXECUTADO/TotalRegistros_Consultar?jsonLogin=" + jsonLoginS +
        "&jsonServicoExecutado=" + jsonServicoExecutado)
      .then((response) => { retornoJsonS = JSON.parse(response.data) })
      .catch((response) => { retornoJsonS = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível listar os serviços executados ("+ response +")\"}") })
      .finally(() => {                 
        if (retornoJsonS.CONCRETIZADO === false){
          setCarregando("")
          setMensagem("")
          setMensagemErro(retornoJsonS.ERRO)
          setMostrarResultado(false)
          return
        }
        else
        {
          var tRegistro = retornoJsonS.RESULTADO
          if (tRegistro === 0)
          {
            setCarregando("")
            setMensagem("")
            setMensagemErro("Nenhum serviço avaliado para esse prestador.")
            setMostrarResultado(false)
            return
          }

          if (optionsRegPorPagina === 100)
          {
            api.get("SERVICO_EXECUTADO/Consultar?jsonLogin=" + jsonLoginS + 
              "&jsonServicoExecutado=" + jsonServicoExecutado + "&iQtdeInicial=0&iQtdeRegistros=" + tRegistro)
            .then((response) => { retornoJsonS = JSON.parse(response.data) })
            .catch((response) => { retornoJsonS = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível listar os serviços executados ("+ response +")\"}") })
            .finally(() => {
              if (retornoJsonS.CONCRETIZADO === false){
                setCarregando("")
                setMensagem("")
                setMensagemErro(retornoJsonS.ERRO)
                setMostrarResultado(false)
                return
              }
              else
              {
                setMensagem("")
                setMensagemErro("")            
                setServicosExecutados(retornoJsonS.RESULTADO)
                setMostrarResultado(true)
              }
            
              setCarregando("")
              setMostrarFiltro(false)
            })
                        
            setTotalRegistro(tRegistro)
            setTotalPagina(1)
            if (paginaAtual !== 1) setPaginaAtual(1)
          }
          else
          {
            var operacao = tRegistro / regPorPagina
            var numero = Math.floor(operacao)
            var decimal = operacao % 1

            var tPagina = 0
            if (decimal > 0) tPagina = numero + 1
            else tPagina = numero
                        
            setTotalRegistro(tRegistro)
            setTotalPagina(tPagina)
            if (tPagina < paginaAtual) setPaginaAtual(1)

            var qtdeInicial = 0
            if (paginaAtual > 1) qtdeInicial = ((paginaAtual - 1) * regPorPagina)
        
            api.get("SERVICO_EXECUTADO/Consultar?jsonLogin=" + jsonLoginS +
              "&jsonServicoExecutado=" + jsonServicoExecutado + "&iQtdeInicial=" + qtdeInicial +
                "&iQtdeRegistros=" + regPorPagina)
            .then((response) => { retornoJsonS = JSON.parse(response.data) })
            .catch((response) => { retornoJsonS = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível listar os serviços executados ("+ response +")\"}") })
            .finally(() => {
              if (retornoJsonS.CONCRETIZADO === false){
                setCarregando("")
                setMensagem("")
                setMensagemErro(retornoJsonS.ERRO)
                setMostrarResultado(false)
                return
              }
              else
              {
                setMensagem("")
                setMensagemErro("")
                setServicosExecutados(retornoJsonS.RESULTADO)
                setMostrarResultado(true)
              }
            
              setCarregando("")
              setMostrarFiltro(false)
            })
          }

          setMensagem("")
          setMensagemErro("")
        }
      })
    }
  }, [categorias, codigoCategoria, descricaoCategoria, descricaoSubCategoria, nomeUsuario, ordenar, paginaAtual,
    processo, regPorPagina, subCategorias, subCategoriasSelecionadas, usuario.COORDENADAS, usuario.CPF_CNPJ,
    usuario.SENHA, usuarioSelecionado.ID, atualizar
  ])

  const activeEnlargePhoto = (list) => {
    setListPhoto(list)
    setShowEnlargePhoto(true)
  }

  const buscarServicos = () => {
    if (descricaoServico.length <= 2) {
      alert("É necessário informar no mínimo 3 letras.")
      return
    }

    setPesquisaDireta(true)
    setPrimeiraBusca(true)
    setCategoriasFiltro([])    
    setCarregando("Buscando os prestadores, Aguarde...")    

    var retornoJson = []
    var jsonLogin = "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ +"\", \"SENHA\": \""+ usuario.SENHA +
    "\", \"COORDENADAS\": \""+ usuario.COORDENADAS +"\" }"
    
    var jsonServico = "{ \"DESCRICAO_PESQUISA\": \""+ descricaoServico +"\" }"

    api.get("SERVICO/TotalRegistros_Listar?jsonLogin=" + jsonLogin + "&jsonServico=" + jsonServico)
    .then((response) => { retornoJson = JSON.parse(response.data) })
    .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível listar os serviços ("+ response +")\"}") })
    .finally(() => {                 
      if (retornoJson.CONCRETIZADO === false){
        setCarregando("")
        setMensagem("")
        setMensagemErro(retornoJson.ERRO)        
        return
      }
      else
      {
        var tRegistro = retornoJson.RESULTADO
        if (tRegistro === 0)
        {
          setCarregando("")
          setMensagem("")
          setMensagemErro("Nenhum prestador encontrado com o filtro \""+  descricaoServico +"\".")
          return
        }

        if (optionsRegPorPagina === 100)
        {
          api.get("SERVICO/Listar?jsonLogin=" + jsonLogin + "&jsonServico=" + jsonServico + 
            "&iQtdeInicial=0&iQtdeRegistros=" + tRegistro)
          .then((response) => { retornoJson = JSON.parse(response.data) })
          .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível listar os serviços ("+ response +")\"}") })
          .finally(() => {
            if (retornoJson.CONCRETIZADO === false){
              setCarregando("")
              setMensagem("")
              setMensagemErro(retornoJson.ERRO)
              return
            }
            else
            {
              setMensagem("")
              setMensagemErro("")
         
              var usu = []
              retornoJson.RESULTADO.map(item => (
                (!possuiVendedor(usu, item)) && 
                  (restricaoLocalidade(item)) && 
                    usu.push({
                      ID: item.USUARIO.ID,
                      NOME: item.USUARIO.NOME,
                      CAMINHO_FOTO: item.USUARIO.CAMINHO_FOTO,
                      WHATSAPP: item.USUARIO.WHATSAPP,
                      TELEFONE: item.USUARIO.TELEFONE,
                      EMAIL: item.USUARIO.EMAIL,
                      DISTANCIA: item.DISTANCIA })
              ))

              var srv = []
              retornoJson.RESULTADO.map(item => (
                  srv.push(item)
              ))

              setUsuarios(usu)
              setUsuariosServicos(srv)
              setMostrarResultado(true)
              setProcesso(3)
            }
          
            setCarregando("")
          })
                      
          setTotalRegistro(tRegistro)
          setTotalPagina(1)
          if (paginaAtual !== 1) setPaginaAtual(1)
        }
        else
        {
          var operacao = tRegistro / regPorPagina
          var numero = Math.floor(operacao)
          var decimal = operacao % 1

          var tPagina = 0
          if (decimal > 0) tPagina = numero + 1
          else tPagina = numero
          
          setTotalRegistro(tRegistro)
          setTotalPagina(tPagina)
          if (tPagina < paginaAtual) setPaginaAtual(1)

          var qtdeInicial = 0
          if (paginaAtual > 1) qtdeInicial = ((paginaAtual - 1) * regPorPagina)
      
          api.get("SERVICO/Listar?jsonLogin=" + jsonLogin + "&jsonServico=" + jsonServico + 
            "&iQtdeInicial=" + qtdeInicial + "&iQtdeRegistros=" + regPorPagina)
          .then((response) => { retornoJson = JSON.parse(response.data) })
          .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível listar os serviços ("+ response +")\"}") })
          .finally(() => {
            if (retornoJson.CONCRETIZADO === false){
              setCarregando("")
              setMensagem("")
              setMensagemErro(retornoJson.ERRO)            
              return
            }
            else
            {
              setMensagem("")
              setMensagemErro("")
      
              var usu = []
              retornoJson.RESULTADO.map(item => (
                (!possuiVendedor(usu, item)) && 
                  usu.push({
                    ID: item.USUARIO.ID,
                    NOME: item.USUARIO.NOME,
                    CAMINHO_FOTO: item.USUARIO.CAMINHO_FOTO,
                    WHATSAPP: item.USUARIO.WHATSAPP,
                    TELEFONE: item.USUARIO.TELEFONE,
                    EMAIL: item.USUARIO.EMAIL,
                    DISTANCIA: item.DISTANCIA })
              ))

              var srv = []
              retornoJson.RESULTADO.map(item => (
                srv.push(item)
              ))

              setUsuarios(usu)
              setUsuariosServicos(srv)
              setMostrarResultado(true)
              setProcesso(3)
            }
          
            setCarregando("")
          })
        }

        setMensagem("")
        setMensagemErro("")
      }
    })
  }

  const retornarPrestador = () => {
    setProcesso(3)
    setServicosExecutados([])
    setUsuarioSelecionado({})
  }
  
  const retornarServico = () => {
    subCategorias.map(item => (
      item.SELECIONADO = false
    ))

    setPesquisaDireta(false)
    setPrimeiraBusca(true)
    setDescricaoServico("")

    setUsuarios([])
    setCodigoCategoria("")
    setDescricaoCategoria("")
    setProcesso(1)

    setCarregando("")
    setMensagem("")
    setMensagemErro("")

    setAtualizar(atualizar + 1)
  }

  const retornarSubCategoria = () => {
    subCategorias.map(item => (
      item.SELECIONADO = false
    ))

    setUsuarios([])
    setCodigoCategoria("")
    setDescricaoCategoria("")
    setProcesso(1)
  }

  const selecionarCategoria = (item) => {
    setCodigoCategoria(item.ID)
    setProcesso(2)
    setMostrarFiltro(false)
  }

  const selecionarPrestador = (usuario) => {
    setUsuarioSelecionado(usuario)
    setProcesso(4)
    setMostrarFiltro(false)
  }  

  const selecionarSubCategoria = () => {
    var selecionadas = []
    var selecionados = ""

    subCategorias.map(item => (
      (item.SELECIONADO) && selecionadas.push(item)
    ))

    if (selecionadas.length === 0) {
      alert("É necessário selecionar pelo menos um serviço.")
      return;
    }

    selecionadas.map(item => (
      (selecionados === "") ? selecionados += String(item.ID) 
        : selecionados += ", " + String(item.ID)
    ))

    setSubCategoriasSelecionadas(selecionados)
    setPesquisaDireta(false)
    setPrimeiraBusca(false)
    setProcesso(3)
    setMostrarFiltro(false)
  }

  function formatNumeric(value, decimals) {
    if (!value) {
      return ""
    }

    if (value === ".") {
      return value = "0."
    }
    
    var regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)
    const decimalsNumber = value.toString().match(regex)[0]
    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) {
      return "0"
    }

    return parsed
  }

  function getFills(score){
    const fills = [0, 0, 0, 0, 0];  
    const integerPart = Math.floor(score);
        
    for (let i = 0; i < integerPart; i++) {
      fills[i] = 1;
    }
    
    const diff = score - integerPart;
    if (diff > 0) {
      fills[integerPart] = 0.5;
    }
    
    return fills;
  }

  function paginaAnterior(){
    if (totalRegistro > 0) {
      if (paginaAtual !== 1) setPaginaAtual(paginaAtual - 1)
    }
  }

  function paginaSeguinte(){
    if (totalRegistro > 0) {
      if (paginaAtual < totalPagina) setPaginaAtual(paginaAtual + 1)
    }
  }

  function possuiVendedor(usu, item) {
    var possui = false

    usu.map(itemU => (
      (itemU.NOME === item.USUARIO.NOME) ? possui = true : ""
    ))

    return possui
  }

  function primeiroNome(nome) {
    var nomeCompleto = nome.split(" ");
    var nomeR = ""

    if (nomeCompleto.length > 1) nomeR = nomeCompleto[0] + " " + nomeCompleto[1].substring(0,1) + "."
    else nomeR = nomeCompleto[0]

    return nomeR
  }

  function restricaoLocalidade(item) {
    var aparecer = false

    if (item.RESTRICAO_LOCALIDADE === 1) aparecer = true;
    else {
      if (item.RESTRICAO_LOCALIDADE === 2) {
        if (item.DISTANCIA <= 5) aparecer = true;
      }

      if (item.RESTRICAO_LOCALIDADE === 3) {
        if (item.DISTANCIA <= 15) aparecer = true;
      }

      if (item.RESTRICAO_LOCALIDADE === 4) {
        if (item.DISTANCIA <= 50) aparecer = true;
      }
    }

    return aparecer
  }

  return(
    <div id="main_ListaServico">
      <div id="cabecalho_ListaServico">
        <div id="cabecalhoMenu_ListaServico">
          <img src={Img_menu} alt="Menu" onClick={() => chamarMenu()} />
        </div>
        <div id="cabecalhoTitulo_ListaServico">
          <label id="titulo_ListaServico">BUSCAR</label><label id="tituloSub_ListaServico">SERVIÇOS</label>
        </div>
        <div id="cabecalhoLogo_ListaServico">
          <img src={Img_logo_topo} alt="Logo" />
        </div>
      </div>
     
      {/* 1 - Categoria */}
      { (processo === 1) &&
        <div className="categoria_main_ListaServico">
          { (carregando !== "") &&
            <div id="categoria_carregando_ListaServico">
              <Spinner animation="border" variant="info" />
              <label id="categoria_carregandoTexto_ListaServico">{carregando}</label>
            </div>
          }

          { (carregando === "") &&
            <div className="categoria_main_ListaServico">
              { !mostrarFiltro && !pesquisaDireta &&
                <div id="categoria_filtroRecolhido_ListaServico" onClick={() => setMostrarFiltro(true)}>
                  <div id="categoria_filtroRecolhidoFiltro_ListaServico">
                    <img src={Img_filtro_branco} id="categoria_filtroRecolhidoFiltroImagem_ListaServico" 
                      alt="Filtro" />
                  </div>
                  <div id="categoria_filtroRecolhidoDescricao_ListaServico">
                    <img src={Img_estender} id="categoria_filtroRecolhidoDescricaoImagem_ListaServico" 
                      alt="Estender" />
                    <label id="categoria_filtroRecolhidoDescricaoTexto_ListaServico">Mostrar Filtros</label>
                  </div>
                </div>
              }

              { mostrarFiltro && !pesquisaDireta &&
                <div id="categoria_filtro_ListaServico">
                  <div id="categoria_filtroFiltro_ListaServico">
                    <img src={Img_filtro_branco} id="categoria_filtroFiltroImagem_ListaServico" alt="Filtro" />
                  </div>
                  <div id="categoria_filtroCampos_ListaServico">
                    <div id="categoria_filtroCampo_ListaServico">
                      <Input 
                        type="search"
                        title=""
                        name="descricaoCategoria_ListaServico"
                        disabled={false}
                        maxLength={50}
                        placeholder="Descrição da Categoria"
                        typeImage={0}
                        withBorder={true}
                        value={descricaoCategoria || ""}
                        onChange={(event) => setDescricaoCategoria(event.target.value)} />
                    </div>
                    <div id="categoria_filtroRecolher_ListaServico" onClick={() => setMostrarFiltro(false)}>
                      <div id="categoria_filtroRecolherView_ListaServico">
                        <img src={Img_recolher} id="categoria_filtroRecolherImagem_ListaServico" 
                          alt="Recolher" />
                        <label id="categoria_filtroRecolherTexto_ListaServico">Recolher Filtros</label>
                      </div>
                    </div>
                  </div>
                </div>
              }

              <div id="categoria_descricaoServico_ListaServico">
                <Input
                  type="search"
                  title=""
                  name="descricaoServico_Usuario"
                  disabled={false}
                  maxLength={255}
                  typeImage={2}
                  placeholder="O que você precisa?"
                  withBorder={true}
                  value={descricaoServico}
                  onChange={(event) => setDescricaoServico(event.target.value)} 
                  onEvent={buscarServicos} 
                  onEventRemove={retornarServico} />
              </div>

              <div id="categoria_titulo_ListaServico">
                <img src={Img_titulo_categoria} alt="Titulo" />
              </div>

              { categoriasFiltro && !pesquisaDireta &&
                <>
                  { (categoriasFiltro !== undefined) && (categoriasFiltro.length !== 0) &&
                    <div id="categoria_categorias_ListaServico">
                      <div id="categoria_dados_ListaServico">
                        { categoriasFiltro.map(item => (
                          <div key={"cat" + item.ID} id="catetoria_card_ListaServico"
                            onClick={() =>  selecionarCategoria(item)}>
                            <CategoriaCard categoria={item} />
                          </div>
                          )) 
                        }
                      </div>
                    </div>
                  }

                  { ((categoriasFiltro === undefined) || (categoriasFiltro.length === 0)) && !pesquisaDireta &&
                    <div id="categoria_dadosSem_ListaServico">
                      <label id="categoria_dadosSemTexto_ListaServico">Nenhuma categoria encontrada com o filtro "{descricaoCategoria}"!</label>
                    </div>
                  }

                </>
              }

              { (mensagem !== "") &&
                <div id="categoria_mensagem_ListaServico">
                  <label id="categoria_mensagemTexto_ListaServico">{mensagem}</label>
                </div>
              }

              { (mensagemErro !== "") &&
                <div id="categoria_mensagemErro_ListaServico">
                  <label id="categoria_mensagemErroTexto_ListaServico">{mensagemErro}</label>
                </div>
              }
            </div>
          }
        </div>
      }

      {/* 2 - Sub-Categoria */}
      { (processo === 2) && 
        <div className="subCategoria_main_ListaServico">
          { (carregando !== "") &&
            <div id="subCategoria_carregando_ListaServico">
              <Spinner animation="border" variant="info" />
              <label id="subCategoria_carregandoTexto_ListaServico">{carregando}</label>
            </div>
          }

          { (carregando === "") &&
            <div className="subCategoria_main_ListaServico">
              { !mostrarFiltro &&
                <div id="subCategoria_filtroRecolhido_ListaServico" onClick={() => setMostrarFiltro(true)}>
                  <div id="subCategoria_filtroRecolhidoFiltro_ListaServico">
                    <img src={Img_filtro_branco} id="subCategoria_filtroRecolhidoFiltroImagem_ListaServico"
                      alt="Filtro" />
                  </div>
                  <div id="subCategoria_filtroRecolhidoDescricao_ListaServico">
                    <img src={Img_estender} id="subCategoria_filtroRecolhidoDescricaoImagem_ListaServico"
                      alt="Estender" />
                    <label id="subCategoria_filtroRecolhidoDescricaoTexto_ListaServico">Mostrar Filtros</label>
                  </div>
                </div>
              }

              { mostrarFiltro &&
                <div id="subCategoria_filtro_ListaServico">
                  <div id="subCategoria_filtroFiltro_ListaServico">
                    <img src={Img_filtro_branco} id="subCategoria_filtroFiltroImagem_ListaServico"
                      alt="Filtro" />
                  </div>
                  <div id="subCategoria_filtroCampos_ListaServico">
                    <div id="subCategoria_filtroCampo_ListaServico">
                      <Input 
                        type="search"
                        title=""
                        name="descricaoSubCategoria_ListaServico"                      
                        disabled={false}                      
                        maxLength={50}
                        placeholder="Descrição da Sub-Categoria"
                        typeImage={0}
                        withBorder={true}
                        value={descricaoSubCategoria || ""}
                        onChange={(event) => setDescricaoSubCategoria(event.target.value)} />
                    </div>
                    <div id="subCategoria_filtroRecolher_ListaServico" onClick={() => setMostrarFiltro(false)}>
                      <div id="subCategoria_filtroRecolherView_ListaServico">
                        <img src={Img_recolher} id="subCategoria_filtroRecolherImagem_ListaServico"
                          alt="Recolher" />
                        <label id="subCategoria_filtroRecolherTexto_ListaServico">Recolher Filtros</label>
                      </div>
                    </div>
                  </div>  
                </div>
              }

              { subCategoriasFiltro &&
                <div className="subCategoria_main_ListaServico">
                  { ((subCategoriasFiltro !== undefined) && (subCategoriasFiltro.length !== 0)) &&
                    <>
                      <div id="subCategoria_titulo_ListaServico">
                        <img src={Img_titulo_sub_categoria} alt="Titulo" />
                      </div>
                      <div id="subCategoria_dados_ListaServico">
                        <SubCategoriaCard
                          codigoCategoria={codigoCategoria} 
                          subCategorias={subCategoriasFiltro} 
                          descricaoSubCategoria={descricaoSubCategoria}
                          setSelecionar={selecionarSubCategoria}
                          setRetornar={retornarSubCategoria} 
                          tipo={1} 
                          width={width} />
                      </div>
                    </>
                  }

                  { ((subCategoriasFiltro === undefined) || (subCategoriasFiltro.length === 0)) &&
                    <div id="subCategoria_dadosSem_ListaServico">
                      <label id="subCategoria_dadosSemTexto_ListaServico">Nenhuma sub-categoria encontrada...</label>
                    </div>
                  }

                  { (mensagem !== "") &&
                    <div id="subCategoria_mensagem_ListaServico">
                      <label id="subCategoria_mensagemTexto_ListaServico">{mensagem}</label>
                    </div>
                  }

                  { (mensagemErro !== "") &&
                    <div id="subCategoria_mensagemErro_ListaServico">
                      <label id="subCategoria_mensagemErroTexto_ListaServico">{mensagemErro}</label>
                    </div>
                  }
                </div>
              } 
            </div>
          }
        </div>
      }

      {/* 3 - Prestadores */}
      { (processo === 3) &&
        <div className="serviceProvider_main_ListService">
          { (carregando !== "") &&
            <div id="serviceProvider_loading_ListService">
              <Spinner animation="border" variant="info" />
              <label id="serviceProvider_textLoading_ListService">{carregando}</label>
            </div>
          }

          { (carregando === "") && (!showEnlargePhoto) &&
            <div className="serviceProvider_main_ListService">

              { !mostrarFiltro &&
                <div id="serviceProvider_extendFilter_ListService" onClick={() => setMostrarFiltro(true)}>
                  <div id="serviceProvider_extendFilterFilter_ListService">
                    <img src={Img_filtro_branco} id="serviceProvider_imageExtendFilterFilter_ListService" alt="Filtro" />
                  </div>
                  <div id="serviceProvider_descriptionExtendFilter_ListService">
                    <img src={Img_estender} id="serviceProvider_imageDescriptionExtendFilter_ListService" alt="Estender" />
                    <label id="serviceProvider_textDescriptionExtendFilter_ListService">Mostrar Filtros</label>
                  </div>
                </div>
              }

              { mostrarFiltro &&
                <div id="serviceProvider_filter_ListService">
                  <div id="serviceProvider_filterFilter_ListService">
                    <img src={Img_filtro_branco} id="serviceProvider_imageFilterFilter_ListService" alt="Filtro" />
                  </div>
                  <div className="serviceProvider_containerFieldFilter_ListService">
                    <div className="serviceProvider_FieldFilter_ListService">
                      <Input 
                        type="search"
                        title=""
                        name="prestador_ListaServico"
                        disabled={false}
                        maxLength={80}
                        placeholder="Prestador"
                        typeImage={0}
                        withBorder={true}
                        value={nomeUsuario || ""}
                        onChange={(event) => setNomeUsuario(event.target.value)} />
                    </div>
                    <div className="serviceProvider_FieldFilter_ListService">
                      <div className="serviceProvider_FieldFilterDouble_ListService">
                        <Select                             
                          title="Registros: "
                          name="regProPagina_ListaServico"
                          options={JSON.parse(optionsRegPorPagina)}
                          disabled={false}
                          value={regPorPagina}
                          onChange={(itemValue) => setRegPorPagina(itemValue)} />
                      </div>
                      <div className="serviceProvider_FieldFilterDouble_ListService">
                        <Select 
                          title="Ordenar: "
                          name="ordenar_ListaServico"
                          options={JSON.parse(optionsOrdenar)}
                          disabled={false}
                          value={ordenar}
                          onChange={(itemValue) => setOrdenar(itemValue)} />
                      </div>
                    </div>
                    <div id="serviceProvider_retractFilter_ListService" onClick={() => setMostrarFiltro(false)}>
                      <div id="serviceProvider_viewRetractFilter_ListService">
                        <img src={Img_recolher} id="serviceProvider_imageRetractFilter_ListService"alt="Recolher" />
                        <label id="serviceProvider_textRetractFilter_ListService">Recolher Filtros</label>
                      </div>
                    </div>
                  </div>
                </div>
              }

              { pesquisaDireta &&
                <div id="serviceProvider_serviceDescription_ListaServico">
                  <label id="serviceProvider_labelServiceDescription_ListaServico">O que você precisa?</label>
                  <label>"{descricaoServico}"</label>
                </div> 
              }

              { mostrarResultado &&
                <>
                  { ((usuarios !== undefined) && (usuarios.length !== 0)) &&
                    <>
                      <div id="serviceProvider_pagination_ListService">
                        <div id="serviceProvider_viewPagination_ListService">
                          <div className="serviceProvider_buttonPagination_ListService" onClick={() => paginaAnterior()}>
                            <img src={Img_arrow_left} className="serviceProvider_buttonImagePagination_ListService" alt="Seta Esquerda" />
                          </div>
                          <label id="serviceProvider_textPagination_ListService"> {`${paginaAtual} de ${totalPagina}`} </label>
                          <div className="serviceProvider_buttonPagination_ListService" onClick={() => paginaSeguinte()}>
                            <img src={Img_arrow_right} className="serviceProvider_buttonImagePagination_ListService" alt="Seta Esquerda" />
                          </div>
                        </div>
                      </div>

                      { usuarios.map(item => (
                        <div key={"listaServicoUsuarios" + item.ID } id="serviceProvider_container_ListService">
                          { (width < 700) &&
                            <>
                              <div id="serviceProvider_To699_data_ListService">
                                <div id="serviceProvider_To699_nameServiceProvider_ListService" onClick={() => selecionarPrestador(item)}>
                                  <label id="serviceProvider_To699_textNameServiceProvider_ListService">{item.NOME}</label>
                                  <img src={Img_mais_informacao} id="serviceProvider_To699_buttonMoreInformations_ListService" alt="Mais Informações" />
                                  <label id="serviceProvider_To699_textMoreInformations_ListService">Mais informações</label>
                                </div>
                                <div id="serviceProvider_To699_otherInformations_ListService">
                                  <div id="serviceProvider_To699_containerImageWhatsApp_ListService">
                                    { (item.WHATSAPP === 1) && 
                                      <img src={Img_whatsapp} id="serviceProvider_To699_imageWhatsApp_ListService" alt="WhatsApp" /> 
                                    }
                                  </div>
                                  <div id="serviceProvider_To699_containerPhone_ListService">
                                    { (item.TELEFONE.length === 10) && 
                                      <label id="serviceProvider_To699_phone_ListService"> { "(" + String(item.TELEFONE).substring(0, 2) + ")" +
                                        String(item.TELEFONE).substring(2, 10) }</label> 
                                    }

                                    { (item.TELEFONE.length === 11) && 
                                      <label id="serviceProvider_To699_phone_ListService"> { "(" + String(item.TELEFONE).substring(0, 2) + ")" +
                                        String(item.TELEFONE).substring(3, 4) + " " + String(item.TELEFONE).substring(3, 11) }</label> 
                                    }
                                  </div>
                                  <div id="serviceProvider_To699_containerDistance_ListService" onClick={() => selecionarPrestador(item)}>
                                    <label id="serviceProvider_To699_titleDistance_ListService">Distância:</label>
                                    <label id="serviceProvider_To699_distance_ListService">{ (item.DISTANCIA !== 0) ? formatNumeric(item.DISTANCIA, 2) + " km" : 0 + " km" }</label>
                                  </div>
                                </div>

                                { usuariosServicos.map(itemS => (
                                  (itemS.USUARIO.ID === item.ID) &&
                                    <div key={"listServiceUser"+ itemS.ID} className="serviceProvider_To699_service_ListService">
                                      <div className="serviceProvider_To699_containerPhoto_ListService">
                                        { (itemS.Lst_SERVICO_FOTO.length !== 0) &&
                                          <Carousel>
                                            { itemS.Lst_SERVICO_FOTO.map(itemF => (                                                                        
                                              <Carousel.Item key={"listServiceUserPhoto"+ itemF.ID} onClick={() => activeEnlargePhoto(itemS.Lst_SERVICO_FOTO)}>
                                                <img                                                                                
                                                  className="serviceProvider_servicePhoto_ListService"
                                                  src={itemF.CAMINHO}
                                                  alt={"Foto" + String(item.ID)} />
                                              </Carousel.Item>
                                              ))
                                            }
                                          </Carousel>
                                        }                                    

                                        { (String(itemS.Lst_SERVICO_FOTO) === "") &&
                                          <div key={"listServiceUserPhoto6"} className="serviceProvider_To699_containerNoPhoto_ListService">
                                            <img src={Img_sem_foto_servico} alt="Sem Foto" className="serviceProvider_servicePhoto_ListService" />
                                          </div>
                                        }
                                      </div>

                                      { (width <= 300) &&
                                        <div className="serviceProvider_To699_containerDataService_ListService">
                                          <div className="serviceProvider_To699_dataServiceRow_ListService">
                                            <div className="serviceProvider_To699_dataServiceFlexGrow1_ListService">
                                              <label className="serviceProvider_To699_dataServiceDescription_ListService">{itemS.SUB_CATEGORIA.DESCRICAO}</label>
                                            </div>
                                            <div className="serviceProvider_To699_dataStar_ListService">
                                              { (getFills(itemS.TOTAL_MEDIA)[0] === 0) ? 
                                                  <img src={Img_star_empty} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Sem Estrelas" /> 
                                                : (getFills(itemS.TOTAL_MEDIA)[0] === 1) ? 
                                                  <img src={Img_star_all} className="serviceProvider_To699_imageDataStar_ListService" 
                                                    alt="Estrela Cheia" />
                                                : <img src={Img_star_half} 
                                                  className="serviceProvider_To699_imageDataStar_ListService" 
                                                  alt="Meia Estrela" /> }
                                              { (getFills(itemS.TOTAL_MEDIA)[1] === 0) ? 
                                                  <img src={Img_star_empty} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Sem Estrelas" /> 
                                                : (getFills(itemS.TOTAL_MEDIA)[1] === 1) ? 
                                                  <img src={Img_star_all} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Estrela Cheia" /> 
                                                : <img src={Img_star_half} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Meia Estrela" /> }
                                              { (getFills(itemS.TOTAL_MEDIA)[2] === 0) ?
                                                  <img src={Img_star_empty} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Sem Estrelas" /> 
                                                : (getFills(itemS.TOTAL_MEDIA)[2] === 1) ? 
                                                  <img src={Img_star_all} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Estrela Cheia" /> 
                                                : <img src={Img_star_half} className="serviceProvider_To699_imageDataStar_ListService"
                                                  alt="Meia Estrela" /> }
                                              { (getFills(itemS.TOTAL_MEDIA)[3] === 0) ?
                                                  <img src={Img_star_empty}  className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Sem Estrelas" /> 
                                                : (getFills(itemS.TOTAL_MEDIA)[3] === 1) ?
                                                  <img src={Img_star_all} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Estrela Cheia" /> 
                                                : <img src={Img_star_half} className="serviceProvider_To699_imageDataStar_ListService"
                                                  alt="Meia Estrela" /> }
                                              { (getFills(itemS.TOTAL_MEDIA)[4] === 0) ?
                                                  <img src={Img_star_empty} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Sem Estrelas" />
                                                : (getFills(itemS.TOTAL_MEDIA)[4] === 1) ?
                                                  <img src={Img_star_all} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Estrela Cheia" /> 
                                                : <img src={Img_star_half} className="serviceProvider_To699_imageDataStar_ListService" 
                                                  alt="Meia Estrela" /> }
                                            </div>
                                          </div>
                                          <div className="serviceProvider_To699_dataServiceFlexGrow1_ListService">
                                            { itemS.VALOR === 0 &&
                                              <label className="serviceProvider_To699_dataValue_ListService">Não informado</label>
                                            }

                                            { itemS.VALOR !== 0 &&
                                              <label className="serviceProvider_To699_dataValue_ListService">{"R$ "+ formatNumeric(itemS.VALOR,2)}</label>
                                            }
                                          </div>
                                          <div className="serviceProvider_dataServiceFlexGrow0_ListService">
                                            <label className="serviceProvider_To699_dataTotalAssessment_ListService">Total de Avaliações: (  { itemS.TOTAL_AVALIACOES } )</label>
                                          </div>
                                          <label className="serviceProvider_To699_dataDescription_ListaServico">{ itemS.DESCRICAO }</label>
                                        </div>
                                      }

                                      { (width > 300) &&
                                        <div className="serviceProvider_To699_containerDataService_ListService">
                                          <div className="serviceProvider_To699_dataServiceRow_ListService">
                                            <div className="serviceProvider_To699_dataServiceFlexGrow1_ListService">
                                              <label className="serviceProvider_To699_dataServiceDescription_ListService">{itemS.SUB_CATEGORIA.DESCRICAO}</label>
                                            </div>
                                            <div className="serviceProvider_To699_dataStar_ListService">
                                              { (getFills(itemS.TOTAL_MEDIA)[0] === 0) ?
                                                  <img src={Img_star_empty} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Sem Estrelas" /> 
                                                : (getFills(itemS.TOTAL_MEDIA)[0] === 1) ? 
                                                  <img src={Img_star_all} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Estrela Cheia" /> 
                                                : <img src={Img_star_half} className="serviceProvider_To699_imageDataStar_ListService"
                                                  alt="Meia Estrela" /> }
                                              { (getFills(itemS.TOTAL_MEDIA)[1] === 0) ?
                                                  <img src={Img_star_empty} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Sem Estrelas" />
                                                : (getFills(itemS.TOTAL_MEDIA)[1] === 1) ?
                                                  <img src={Img_star_all} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Estrela Cheia" />
                                                : <img src={Img_star_half} className="serviceProvider_To699_imageDataStar_ListService"
                                                  alt="Meia Estrela" /> }
                                              { (getFills(itemS.TOTAL_MEDIA)[2] === 0) ?
                                                  <img src={Img_star_empty} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Sem Estrelas" /> 
                                                : (getFills(itemS.TOTAL_MEDIA)[2] === 1) ?
                                                  <img src={Img_star_all} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Estrela Cheia" /> 
                                                : <img src={Img_star_half} className="serviceProvider_To699_imageDataStar_ListService"
                                                  alt="Meia Estrela" /> }
                                              { (getFills(itemS.TOTAL_MEDIA)[3] === 0) ?
                                                  <img src={Img_star_empty} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Sem Estrelas" />
                                                : (getFills(itemS.TOTAL_MEDIA)[3] === 1) ?
                                                  <img src={Img_star_all} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Estrela Cheia" />
                                                : <img src={Img_star_half} className="serviceProvider_To699_imageDataStar_ListService"
                                                  alt="Meia Estrela" /> }
                                              { (getFills(itemS.TOTAL_MEDIA)[4] === 0) ?
                                                  <img src={Img_star_empty} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Sem Estrelas" /> 
                                                : (getFills(itemS.TOTAL_MEDIA)[4] === 1) ?
                                                  <img src={Img_star_all} className="serviceProvider_To699_imageDataStar_ListService"
                                                    alt="Estrela Cheia" /> 
                                                : <img src={Img_star_half} className="serviceProvider_To699_imageDataStar_ListService"
                                                  alt="Meia Estrela" /> }
                                            </div>
                                          </div>
                                          <div className="serviceProvider_To699_dataServiceRow_ListService">
                                            <div className="serviceProvider_To699_dataServiceFlexGrow1_ListService">
                                              { itemS.VALOR === 0 &&
                                                <label className="serviceProvider_To699_dataValue_ListService">Não informado</label>
                                              }

                                              { itemS.VALOR !== 0 &&
                                                <label className="serviceProvider_To699_dataValue_ListService">{"R$ "+ formatNumeric(itemS.VALOR,2)}</label>
                                              }
                                            </div>
                                            <div id="serviceProvider_To699_dataServiceFlexGrow0_ListService">
                                              <label id="serviceProvider_To699_dataTotalAssessment_ListService">Total de Avaliações: (  { itemS.TOTAL_AVALIACOES } )</label>
                                            </div>
                                          </div>
                                          <label id="serviceProvider_To699_dataDescription_ListaServico">{ itemS.DESCRICAO }</label>
                                        </div>
                                      }
                                    </div> 
                                  )) 
                                }
                              </div>
                            </>
                          }

                          { (width >= 700) &&
                            <>
                              <div id="serviceProvider_Above699_data_ListService">
                                <div id="serviceProvider_Above699_containerDataUser_ListService">
                                  <div id="serviceProvider_Above699_user_ListService" onClick={() => selecionarPrestador(item)}>
                                    <label id="serviceProvider_Above699_userName_ListService">{item.NOME}</label>
                                    <img src={Img_mais_informacao} id="serviceProvider_Above699_imageMoreInformation_ListService" alt="Mais Informações" />
                                    <label id="serviceProvider_Above699_moreInformation_ListService">Mais informações</label>
                                  </div>
                                  <div id="serviceProvider_Above699_containerDistance_ListService" onClick={() => selecionarPrestador(item)}>
                                    <label id="serviceProvider_Above699_titleDistance_ListService">Distância:</label>
                                    <label id="serviceProvider_Above699_distance_ListService">{ (item.DISTANCIA !== 0) ? formatNumeric(item.DISTANCIA, 2) + " km" : 0 + " km" }</label>
                                  </div>
                                </div>
                                <div id="serviceProvider_Above699_otherInformations_ListService">
                                  <div id="serviceProvider_Above699_containerImageWhatsApp_ListService">
                                    { (item.WHATSAPP === 1) && 
                                      <img src={Img_whatsapp} id="serviceProvider_Above699_imageWhatsApp_ListService" alt="WhatsApp" /> }
                                  </div>
                                  <div id="serviceProvider_Above699_containerPhone_ListService">
                                    { (item.TELEFONE.length === 10) && 
                                      <label id="serviceProvider_Above699_phone_ListService"> { "(" + String(item.TELEFONE).substring(0, 2) + ")" + String(item.TELEFONE).substring(2, 10) }</label> 
                                    }

                                    { (item.TELEFONE.length === 11) && 
                                      <label id="serviceProvider_Above699_phone_ListService"> { "(" + String(item.TELEFONE).substring(0, 2) + ")" + String(item.TELEFONE).substring(3, 4) + " " + String(item.TELEFONE).substring(3, 11) }</label> 
                                    }
                                  </div>
                                </div>
                            
                                { usuariosServicos.map(itemS => (
                                  <>
                                    { itemS.USUARIO.ID === item.ID &&
                                      <div key={"listaServicoUsuarioServico"+ itemS.ID} className="serviceProvider_Above699_containerService_ListService">
                                        <div className="serviceProvider_Above699_service_ListService">
                                          <div className="serviceProvider_Above699_containerServiceTitle_ListService">
                                            <div className="serviceProvider_Above699_serviceTitle_ListService">
                                              <label className="serviceProvider_Above699_textServiceTitle_ListService">{itemS.SUB_CATEGORIA.CATEGORIA.DESCRICAO} {" ("} {itemS.DESCRICAO} {")"}</label>
                                              { (getFills(itemS.TOTAL_MEDIA)[0] === 0) ? 
                                                  <img src={Img_star_empty} className="serviceProvider_Above699_imageDataStar_ListService"
                                                    alt="Sem Estrelas" />
                                                : (getFills(itemS.TOTAL_MEDIA)[0] === 1) ?
                                                  <img src={Img_star_all} className="serviceProvider_Above699_imageDataStar_ListService"
                                                    alt="Estrela Cheia" /> 
                                                : <img src={Img_star_half} className="serviceProvider_Above699_imageDataStar_ListService"
                                                  alt="Meia Estrela" />
                                              }
                                              
                                              { (getFills(itemS.TOTAL_MEDIA)[1] === 0) ?
                                                  <img src={Img_star_empty} className="serviceProvider_Above699_imageDataStar_ListService"
                                                    alt="Sem Estrelas" />
                                                : (getFills(itemS.TOTAL_MEDIA)[1] === 1) ?
                                                  <img src={Img_star_all} className="serviceProvider_Above699_imageDataStar_ListService"
                                                    alt="Estrela Cheia" />
                                                : <img src={Img_star_half} className="serviceProvider_Above699_imageDataStar_ListService"
                                                  alt="Meia Estrela" /> 
                                              }

                                              { (getFills(itemS.TOTAL_MEDIA)[2] === 0) ?
                                                  <img src={Img_star_empty} className="serviceProvider_Above699_imageDataStar_ListService"
                                                    alt="Sem Estrelas" />
                                                : (getFills(itemS.TOTAL_MEDIA)[2] === 1) ?
                                                  <img src={Img_star_all} className="serviceProvider_Above699_imageDataStar_ListService"
                                                    alt="Estrela Cheia" />
                                                : <img src={Img_star_half} className="serviceProvider_Above699_imageDataStar_ListService"
                                                  alt="Meia Estrela" />
                                              }
                                              
                                              { (getFills(itemS.TOTAL_MEDIA)[3] === 0) ?
                                                  <img src={Img_star_empty} className="serviceProvider_Above699_imageDataStar_ListService"
                                                    alt="Sem Estrelas" />
                                                : (getFills(itemS.TOTAL_MEDIA)[3] === 1) ?
                                                  <img src={Img_star_all} className="serviceProvider_Above699_imageDataStar_ListService"
                                                    alt="Estrela Cheia" />
                                                : <img src={Img_star_half} className="serviceProvider_Above699_imageDataStar_ListService"
                                                  alt="Meia Estrela" /> 
                                              }

                                              { (getFills(itemS.TOTAL_MEDIA)[4] === 0) ?
                                                  <img src={Img_star_empty} className="serviceProvider_Above699_imageDataStar_ListService"
                                                    alt="Sem Estrelas" />
                                                : (getFills(itemS.TOTAL_MEDIA)[4] === 1) ?
                                                  <img src={Img_star_all} className="serviceProvider_Above699_imageDataStar_ListService"
                                                    alt="Estrela Cheia" /> 
                                                : <img src={Img_star_half} className="serviceProvider_Above699_imageDataStar_ListService"
                                                  alt="Meia Estrela" /> 
                                              }
                                            </div>
                                            <div className="serviceProvider_Above699_cotainerValue_ListService">
                                              <label className="serviceProvider_Above699_value_ListService">Valor:</label>
                                              { itemS.VALOR === 0 &&
                                                <label className="serviceProvider_Above699_textValue_ListService">Não informado</label>
                                              }

                                              { itemS.VALOR !== 0 &&
                                                <label className="serviceProvider_Above699_textValue_ListService">{"R$ "+ formatNumeric(itemS.VALOR,2)}</label>
                                              }
                                            </div>
                                          </div>
                                          <div className="serviceProvider_Above699_containerPhoto_ListService">
                                            { (itemS.Lst_SERVICO_FOTO.length !== 0) &&
                                              <>
                                                <div>
                                                  { (itemS.Lst_SERVICO_FOTO[0] !== undefined) &&
                                                    <img className="serviceProvider_Above699_imageServicePhoto_ListService" src={itemS.Lst_SERVICO_FOTO[0].CAMINHO} alt="Foto Serviço" onClick={() => activeEnlargePhoto(itemS.Lst_SERVICO_FOTO)} />
                                                  }
                                                </div>
                                                <div className="serviceProvider_Above699_servicePhotoAfterFirst_ListService">
                                                  { (itemS.Lst_SERVICO_FOTO[1] !== undefined) &&
                                                    <img className="serviceProvider_Above699_imageServicePhoto_ListService" src={itemS.Lst_SERVICO_FOTO[1].CAMINHO} alt="Foto Serviço" onClick={() => activeEnlargePhoto(itemS.Lst_SERVICO_FOTO)}/>
                                                  }
                                                </div>
                                                <div className="serviceProvider_Above699_servicePhotoAfterFirst_ListService">
                                                  { (itemS.Lst_SERVICO_FOTO[2] !== undefined) &&
                                                    <img className="serviceProvider_Above699_imageServicePhoto_ListService" src={itemS.Lst_SERVICO_FOTO[2].CAMINHO} alt="Foto Serviço" onClick={() => activeEnlargePhoto(itemS.Lst_SERVICO_FOTO)}/>
                                                  }
                                                </div>
                                                <div className="serviceProvider_Above699_servicePhotoAfterFirst_ListService">
                                                  { (itemS.Lst_SERVICO_FOTO[3] !== undefined) &&
                                                    <img className="serviceProvider_Above699_imageServicePhoto_ListService" src={itemS.Lst_SERVICO_FOTO[3].CAMINHO} alt="Foto Serviço" onClick={() => activeEnlargePhoto(itemS.Lst_SERVICO_FOTO)}/>
                                                  }
                                                </div>
                                                <div className="serviceProvider_Above699_servicePhotoLast_ListService">
                                                  { (itemS.Lst_SERVICO_FOTO[4] !== undefined) &&
                                                    <img className="serviceProvider_Above699_imageServicePhoto_ListService" src={itemS.Lst_SERVICO_FOTO[4].CAMINHO} alt="Foto Serviço" onClick={() => activeEnlargePhoto(itemS.Lst_SERVICO_FOTO)}/>
                                                  }
                                                </div>
                                              </>
                                            }

                                            { (itemS.Lst_SERVICO_FOTO.length === 0) &&
                                              <>
                                                <div>
                                                  <img className="serviceProvider_Above699_imageServicePhoto_ListService" src={Img_sem_foto_servico} alt="Foto Serviço" />
                                                </div>
                                              </>
                                            }
                                          </div>
                                        </div>
                                      </div> 
                                    }
                                  </>
                                  )) 
                                }
                              </div>
                            </>
                          }
                        </div>
                        ))
                      }
                    </>
                  }

                  { ((usuarios === undefined) || (usuarios.length === 0)) &&
                    <div id="serviceProvider_noData_ListService">
                      <label id="serviceProvider_textNoData_ListService">Nenhum serviço encontrado...</label>
                    </div>
                  }
                </>
              }

              { (mensagem !== "") &&
                <div id="serviceProvider_menssage_ListService">
                  <label id="serviceProvider_textMessage_ListService">{mensagem}</label>
                </div>
              }

              { (mensagemErro !== "") &&
                <div id="serviceProvider_menssageError_ListService">
                  <label id="serviceProvider_textMessageError_ListService">{mensagemErro}</label>
                </div>
              }

              <div id="serviceProvider_button_ListService" onClick={() => retornarServico()}>
                <img src={Img_progredir} id="serviceProvider_imageButton_ListServic" alt="Retornar" />
              </div>
            </div>
          }

          { (carregando === "") && (showEnlargePhoto) &&
            <div className="serviceProvider_main_ListService">
              <EnlargePhoto  listPhoto={listPhoto} setShowEnlargePhoto={setShowEnlargePhoto} />
            </div>
          }
        </div>
      }

      {/* 4 - Dados Prestadores  */}
      { (processo === 4) &&
        <div className="dadosPrestador_main_ListaServico">
          { (carregando !== "") &&
            <div id="dadosPrestador_carregando_ListaServico">
              <Spinner animation="border" variant="info" />
              <label id="dadosPrestador_carregandoTexto_ListaServico">{carregando}</label>
            </div>
          }

          { (carregando === "") &&
            <div className="dadosPrestador_main_ListaServico">

              { !mostrarFiltro &&
                <div id="dadosPrestador_filtroRecolhido_ListaServico" onClick={() => setMostrarFiltro(true)}>
                  <div id="dadosPrestador_filtroRecolhidoFiltro_ListaServico">
                    <img src={Img_filtro_branco} id="dadosPrestador_filtroRecolhidoFiltroImagem_ListaServico" alt="Filtro" />
                  </div>
                  <div id="dadosPrestador_filtroRecolhidoDescricao_ListaServico">
                    <img src={Img_estender} id="dadosPrestador_filtroRecolhidoDescricaoImagem_ListaServico" alt="Estender" />
                    <label id="dadosPrestador_filtroRecolhidoDescricaoTexto_ListaServico">Mostrar Filtros</label>
                  </div>
                </div>
              }

              { mostrarFiltro &&
                <div id="dadosPrestador_filtro_ListaServico">
                  <div id="dadosPrestador_filtroFiltro_ListaServico">
                    <img src={Img_filtro_branco} id="dadosPrestador_filtroFiltroImagem_ListaServico" alt="Filtro" />
                  </div>
                  <div className="dadosPrestador_filtroCampos_ListaServico">
                    <div className="dadosPrestador_filtroCampo_ListaServico">
                      <Select 
                        title="Registros: "
                        name="regProPagina"
                        options={JSON.parse(optionsRegPorPagina)}
                        disabled={false}
                        grow={1}
                        value={regPorPagina}
                        set={(itemValue) => setRegPorPagina(itemValue)} />
                    </div>
                    <div id="dadosPrestador_filtroRecolher_ListaServico" onClick={() => setMostrarFiltro(false)}>
                      <div id="dadosPrestador_filtroRecolherView_ListaServico">
                        <img src={Img_recolher} id="dadosPrestador_filtroRecolherImagem_ListaServico" alt="Recolher" />
                        <label id="dadosPrestador_filtroRecolherTexto_ListaServico">Recolher Filtros</label>
                      </div>
                    </div>
                  </div>
                </div>
              }

              <div id="dadosPrestador_usuario_ListaServico">
                <div id="dadosPrestador_usuarioFoto_ListaServico">
                  { ((usuarioSelecionado.CAMINHO_FOTO !== null) && (usuarioSelecionado.CAMINHO_FOTO !== "")) &&
                    <img src={usuarioSelecionado.CAMINHO_FOTO} id="dadosPrestador_usuarioFotoImagem_ListaServico" alt="Foto" />
                  }

                  { ((usuarioSelecionado.CAMINHO_FOTO === null) || (usuarioSelecionado.CAMINHO_FOTO === "")) &&
                    <img src={Img_sem_foto} id="dadosPrestador_usuarioFotoSemImagem_ListaServico" alt="Sem Foto" />
                  }
                </div>
                
                <div id="dadosPrestador_dadosInformacao_ListaServico">
                  <div id="dadosPrestador_usuarioNome_ListaServico">
                    <img src={Img_boneco_perfil} id="dadosPrestador_usuarioNomeImagem_ListaServico" alt="Boneco" />
                    <label id="dadosPrestador_usuarioNomeTexto_ListaServico">{usuarioSelecionado.NOME}</label>
                  </div>
                  <div id="dadosPrestador_usuarioEmail_ListaServico">
                    <img src={Img_email_perfil} id="dadosPrestador_usuarioEmailImagem_ListaServico" alt="Email" />
                    <label id="dadosPrestador_usuarioEmailTexto_ListaServico">{usuarioSelecionado.EMAIL}</label>
                  </div>
                  <div id="dadosPrestador_usuarioTelefone_ListaServico">
                    <img src={Img_telefone_perfil} id="dadosPrestador_usuarioTelefoneImagem_ListaServico" alt="Telefone" />
                    { (usuarioSelecionado.TELEFONE.length === 10) && <label id="dadosPrestador_usuarioTelefoneTexto_ListaServico"> { "(" + String(usuarioSelecionado.TELEFONE).substring(0, 2) + ")" + String(usuarioSelecionado.TELEFONE).substring(2, 10) }</label> }
                    { (usuarioSelecionado.TELEFONE.length === 11) && <label id="dadosPrestador_usuarioTelefoneTexto_ListaServico"> { "(" + String(usuarioSelecionado.TELEFONE).substring(0, 2) + ")" + String(usuarioSelecionado.TELEFONE).substring(3, 4) + " " + String(usuarioSelecionado.TELEFONE).substring(3, 11) }</label> }
                  </div>
                </div>
              </div>
            
              { mostrarResultado &&
                <>
                  <div id="dadosPrestador_dados_ListaServico">
                    { (servicosExecutados !== undefined) && (servicosExecutados.length !== 0) &&
                      <>
                        <div id="dadosPrestador_paginacao_ListaServico">
                          <div id="dadosPrestador_paginacaoView_ListaServico">
                            <div className="dadosPrestador_paginacaoBotao_ListaServico" onClick={() => paginaAnterior()}>
                              <img src={Img_arrow_left} className="dadosPrestador_paginacaoBotaoImagem_ListaServico" alt="Seta Esquerda" />
                            </div>
                            <label id="dadosPrestador_paginacaoTexto_ListaServico"> {`${paginaAtual} de ${totalPagina}`} </label>
                            <div className="dadosPrestador_paginacaoBotao_ListaServico" onClick={() => paginaSeguinte()}>
                              <img src={Img_arrow_right} className="dadosPrestador_paginacaoBotaoImagem_ListaServico" alt="Seta Esquerda" />
                            </div>
                          </div>
                        </div>

                        <div className="dadosPrestador_main_ListaServico">
                          { servicosExecutados.map(item => (
                            <div key={"listaServicoPrestadorServico"+ item.ID} id="dadoPrestador_dados_ListaServico">
                              <div id="dadoPrestador_usuario_ListaServico">
                                <div id="dadoPrestador_usuarioView_ListaServico">
                                  <div id="dadoPrestador_usuarioFoto_ListaServico">
                                    <img src={Img_usuario_servico_prestado} className="dadoPrestador_usuarioFotoImagem_ListaServico" alt="Titulo" />
                                  </div>
                                  <div id="dadoPrestador_usuarioNome_ListaServico">
                                    <label id="dadoPrestador_usuarioNomeTexto_ListaServico">{primeiroNome(item.USUARIO.NOME)}</label>
                                  </div>
                                </div>
                              </div>
                              <div id="dadosPrestador_servicoPrestado_ListaServico">
                                <div id="dadosPrestador_estrelas_ListaServico">
                                  { (getFills(item.AVALIACAO)[0] === 0) ? <img src={Img_star_empty} className="dadosPrestador_estrelasImagem_ListaServico" alt="Titulo" /> : (getFills(item.AVALIACAO)[0] === 1) ? <img src={Img_star_all} className="dadosPrestador_estrelasImagem_ListaServico" alt="Titulo" /> : <img src={Img_star_half} className="dadosPrestador_estrelasImagem_ListaServico" alt="Titulo" /> }
                                  { (getFills(item.AVALIACAO)[1] === 0) ? <img src={Img_star_empty} className="dadosPrestador_estrelasImagem_ListaServico" alt="Titulo" /> : (getFills(item.AVALIACAO)[1] === 1) ? <img src={Img_star_all} className="dadosPrestador_estrelasImagem_ListaServico" alt="Titulo" /> : <img src={Img_star_half} className="dadosPrestador_estrelasImagem_ListaServico" alt="Titulo" /> }
                                  { (getFills(item.AVALIACAO)[2] === 0) ? <img src={Img_star_empty} className="dadosPrestador_estrelasImagem_ListaServico" alt="Titulo" /> : (getFills(item.AVALIACAO)[2] === 1) ? <img src={Img_star_all} className="dadosPrestador_estrelasImagem_ListaServico" alt="Titulo" /> : <img src={Img_star_half} className="dadosPrestador_estrelasImagem_ListaServico" alt="Titulo" /> }
                                  { (getFills(item.AVALIACAO)[3] === 0) ? <img src={Img_star_empty} className="dadosPrestador_estrelasImagem_ListaServico" alt="Titulo" /> : (getFills(item.AVALIACAO)[3] === 1) ? <img src={Img_star_all} className="dadosPrestador_estrelasImagem_ListaServico" alt="Titulo" /> : <img src={Img_star_half} className="dadosPrestador_estrelasImagem_ListaServico" alt="Titulo" /> }
                                  { (getFills(item.AVALIACAO)[4] === 0) ? <img src={Img_star_empty} className="dadosPrestador_estrelasImagem_ListaServico" alt="Titulo" /> : (getFills(item.AVALIACAO)[4] === 1) ? <img src={Img_star_all} className="dadosPrestador_estrelasImagem_ListaServico" alt="Titulo" /> : <img src={Img_star_half} className="dadosPrestador_estrelasImagem_ListaServico" alt="Titulo" /> }
                                </div>
                                <div id="dadosPrestador_data_ListaServico">
                                  <label id="dadosPrestador_dataDecricao_ListaServico">{item.DATA.substring(8,10) + "/" + item.DATA.substring(5,7) + "/" + item.DATA.substring(0,4) + " " + item.DATA.substring(11,19)}</label>
                                </div>
                                <div id="dadosPrestador_servico_ListaServico">
                                  <label id="dadosPrestador_servicoDescricao_ListaServico">{item.SERVICO.SUB_CATEGORIA.DESCRICAO}</label>
                                </div>
                                <div id="dadosPrestador_avaliacao_ListaServico">
                                  <label id="dadosPrestador_avaliacaoDescricao_ListaServico">{item.DESCRICAO}</label>
                                </div>
                              </div>
                            </div>
                            ))
                          }
                        </div>
                      </>
                    }
                  </div>
                </>
              }

              { (mensagem !== "") &&
                <div id="dadosPrestador_mensagem_ListaServico">
                  <label id="dadosPrestador_mensagemTexto_ListaServico">{mensagem}</label>
                </div>
              }

              { (mensagemErro !== "") &&
                <div id="dadosPrestador_mensagemErro_ListaServico">
                  <label id="dadosPrestador_mensagemErroTexto_ListaServico">{mensagemErro}</label>
                </div>
              }

              <div id="dadosPrestador_botao_ListaServico" onClick={() => retornarPrestador()}>
                <img src={Img_progredir} id="dadosPrestador_botaoImagem_ListaServico" alt="Retornar" />
              </div>
            </div>
          }
        </div>
      }
    </div>
  )
}

export default ListaServico