import "./style.css"
import Img_boneco_perfil from "../../Assets/Img/boneco_perfil.png"
import Img_dados_pessoais from "../../Assets/Menu/dados-pessoais.png"
import Img_email_perfil from "../../Assets/Img/email_perfil.png"
import Img_progredir from "../../Assets/Img/Botoes/progredir.png"
import Img_sair from "../../Assets/Img/Botoes/sair.png"
import Img_sem_foto from "../../Assets/Img/sem_foto.png"
import Img_servicos from "../../Assets/Menu/cadastro-servicos.png"
import Img_servicos_contratos from "../../Assets/Menu/servicos-contratados.png"
import Img_servicos_prestados from "../../Assets/Menu/servicos-prestados.png"
import Img_seta_perfil from "../../Assets/Img/Botoes/seta_perfil.png"
import Img_telefone_perfil from "../../Assets/Img/telefone_perfil.png"
import Img_menu from "../../Assets/Img/Botoes/menu.png"
import Img_logo_topo from "../../Assets/Img/logo_topo_colorida.png"

const Menu = ({ usuario, logoff, chamarListaServicos, chamarDadosCadastrais, chamarServicos,
  chamarServicosContratados, chamarServicosPrestados }) => {

  return (
    <div id="main_Menu">
      <div id="cabecalho_Menu">
        <div id="cabecalhoMenu_Menu">
          <img src={Img_menu} alt="Menu" />
        </div>
        <div id="cabecalhoTitulo_Menu">
          <label id="titulo_Menu">MEU</label><label id="tituloSub_Menu">PERFIL</label>
        </div>
        <div id="cabecalhoLogo_Menu">
          <img src={Img_logo_topo} alt="Logo" />
        </div>
      </div>
      <div id="traco_Menu"/>
    
      <div id="perfil_Menu">
        <div id="containerPerfil_Menu">
          <div id="foto_Menu">
            { ((usuario.CAMINHO_FOTO ===  null) || (usuario.CAMINHO_FOTO ===  ""))  &&
              <img src={Img_sem_foto} id="imgFoto_Menu" alt="Sem foto" /> }
            { ((usuario.CAMINHO_FOTO !== null) && (usuario.CAMINHO_FOTO !== "")) &&
              <img src={usuario.CAMINHO_FOTO} id="imgFoto_Menu" alt="Foto" /> }
          </div>
          <div id="informacoes_Menu">
            <div className="containerNome_Menu">
              <div>
                <img src={Img_boneco_perfil} id="containerBoneco_Menu" alt="Usuário perfil" /> 
              </div>
              <div className="containerNomeTexto_Menu">
                <label>{usuario.NOME}</label>
              </div>
            </div>
            <div className="containerNome_Menu">
              <div>
                <img src={Img_email_perfil} className="containerNomeImagem_Menu" alt="Email perfil" />
              </div>
              <div className="containerNomeTexto_Menu">
                <label>{usuario.EMAIL}</label>
              </div>
            </div>
            <div className="containerNome_Menu">
              <div>                
                <img src={Img_telefone_perfil} className="containerNomeImagem_Menu" alt="Telefone perfil" />
              </div>
              <div className="containerNomeTexto_Menu">
                <label>{usuario.TELEFONE}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="telas_Menu">
        <div className="containerTela_Menu">
          <div className="bordaTitulo_Menu">
            <img src={Img_dados_pessoais} alt="Dados Cadastrais" className="botao42_Menu" />
          </div>
          <div className="tituloTela_Menu">
            <label>Dados Cadastrais</label>
          </div>
          <div className="flexGrow0_Menu">
            <img src={Img_seta_perfil} alt="Seta perfil" className="botao42_Menu"
              onClick={(() => { chamarDadosCadastrais() })} />
          </div>
        </div>

        <div className="containerTela_Menu">
          <div className="bordaTitulo_Menu">
            <img src={Img_servicos_contratos} alt="Serviços Contratados" className="botao42_Menu" />
          </div>
          <div className="tituloTela_Menu">
            <label>Serviços Contratados</label>
          </div>
          <div className="flexGrow0_Menu">
            <img src={Img_seta_perfil} alt="Seta perfil" className="botao42_Menu"
              onClick={(() => { chamarServicosContratados() })} />
          </div>
        </div>

        <div className="containerTela_Menu">
          <div className="bordaTitulo_Menu">
            <img src={Img_servicos} alt="Cadastro de Serviços" className="botao42_Menu" />
          </div>
          <div className="tituloTela_Menu">
            <label>Cadastro de Serviços</label>
          </div>
          <div className="flexGrow0_Menu">
            <img src={Img_seta_perfil} alt="Seta perfil" className="botao42_Menu"
              onClick={(() => { chamarServicos() })} />
          </div>
        </div>

        <div className="containerTela_Menu">
          <div className="bordaTitulo_Menu">
            <img src={Img_servicos_prestados} alt="Serviços Prestados" className="botao42_Menu" />
          </div>
          <div className="tituloTela_Menu">
            <label>Serviços Prestados</label>
          </div>
          <div className="flexGrow0_Menu">
            <img src={Img_seta_perfil} alt="Seta perfil" className="botao42_Menu"
              onClick={(() => { chamarServicosPrestados() })} />
          </div>
        </div>
      </div>
        
      <div id="retornar_Menu">
        <img id="botaoRetornar_Menu" src={Img_progredir} alt="Retornar" onClick={() => chamarListaServicos()} />
        <img id="botaoSair_Menu" src={Img_sair} alt="Sair" onClick={logoff} />
      </div>
    </div>
  )
}

export default Menu