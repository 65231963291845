import "./style.css"
import React from "react"

const Select = ({title, name, options, disabled, value, onChange}) => {
  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return(
    <div id="main_Select">
      { ((title !== undefined) && (title !== "")) && <label id="titulo_Select">{title}</label> }

      <div id="container_Select">
        <div className="campo_Select">
          <select
            className="class_Select"
            id={name}
            name={name}
            disabled={disabled}
            value={value}
            onChange={handleChange} >

            {options.RESULTADO.map((index) => (
              <option key={"opt" + {name} + index.ID} value={index.ID}>{index.VALOR}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}

export default Select