import "./style.css"
import React from "react"
import Img_adicionar from "../../Assets/Img/Botoes/adicionar.png"
import Img_cadeado from "../../Assets/Img/cadeado.png"
import Img_camera from "../../Assets/Img/camera.png"
import Img_excluir from "../../Assets/Img/Botoes/excluir.png"
import Img_lupa from "../../Assets/Img/Botoes/lupa.png"
import Img_sem_foto from "../../Assets/Img/sem_foto.png"

const Input = ({type, title, name, disabled, maxLength, typeImage, placeholder, accept, pathPhoto, value, onChange, onEvent, onEventRemove}) => {
  const hiddenFileInput = React.useRef(null);

  const handleClickFileInput = event => {
    hiddenFileInput.current.click();
  };

  return(
    <div id="main_Input">
      { ((title !== undefined) && (title !== "")) && <label id="titulo_Input">{title}</label> }

      { type !== "file" &&
        <div id="container_Input">
          { (typeImage === 1) &&
           <div className="viewCampo_Input">
            <div className="imagem_Input">
              <img src={Img_cadeado} className="botao_Input" alt="cadeado" />
            </div>
            <div className="campo_Input">
             <input 
               className="classImagem_Input"
               type={type}
               id={name}
               name={name}
               disabled={disabled}
               maxLength={maxLength}
               placeholder={placeholder}
               value={value}
               onChange={onChange} />
            </div>
           </div>
          }

          { (typeImage === 2) &&
           <div className="viewCampo_Input">           
            <div className="viewCampoBusca_Input">
             <input 
               className="classImagemBusca_Input"
               type={type}
               id={name}
               name={name}
               disabled={disabled}
               maxLength={maxLength}
               placeholder={placeholder}
               value={value}
               onChange={onChange} />
            </div>

            <div className="imagemBusca_Input" onClick={onEvent}>
              <img src={Img_lupa} className="botao_Input" alt="cadeado" />
            </div>
            <div className="imagemBusca_Input" onClick={onEventRemove}>
              <img src={Img_excluir} className="botao_Input" alt="excluir" />
            </div>
           </div>
          }

          { ((typeImage !== 1) && (typeImage !== 2)) &&
           <div className="campo_Input">
             <input
               className="class_Input"
               type={type}
               id={name}
               name={name}
               disabled={disabled}
               maxLength={maxLength}
               placeholder={placeholder}               
               value={value}
               onChange={onChange} />           
           </div>
          }          
        </div>
      }

      { type === "file" &&
        <>
          <input
            className="file_Input"
            type={type}
            ref={hiddenFileInput}
            id={name}
            name={name}
            maxLength={maxLength}
            accept={accept}
            disabled={disabled}
            value={value}
            onChange={onChange} />

          { typeImage === 1 &&
            <div id="login_Input">
              <div>
                { ((pathPhoto ===  null) || (pathPhoto ===  ""))  && <img src={Img_sem_foto} className="foto_Input" alt="Sem foto" onClick={handleClickFileInput} /> }
                { ((pathPhoto !== null) && (pathPhoto !== "")) && <img src={pathPhoto} className="foto_Input" alt="Foto" onClick={handleClickFileInput} /> }
              </div>
              <div id="containerCamera_Input">
                <img src={Img_camera} id="camera_Input" alt="Camera" onClick={handleClickFileInput}/>
              </div>
            </div>
          }

          { typeImage === 2 &&
            <img src={Img_adicionar} id="camera_Input" alt="Camera" onClick={handleClickFileInput}/>
          }
        </>      
      }

    </div>
  )
}

export default Input