import "./style.css"
import React, { useState, useEffect } from "react"
import Img_botao_sub_nao_selecionada from "../../Assets/Img/Botoes/sub_nao_selecionada.png"
import Img_botao_sub_selecionada from "../../Assets/Img/Botoes/sub_selecionada.png"
import Img_progredir from "../../Assets/Img/Botoes/progredir.png"
import Img_prosseguir from "../../Assets/Img/Botoes/prosseguir.png"

const SubCategoriaCard = ({subCategorias, descricaoSubCategoria, setSelecionar, setRetornar, tipo}) => {    
  const [visivel, setVisivel] = useState(true)
  const [atualizar, setAtualizar] = useState(0)
    
  useEffect(() => {
    if (descricaoSubCategoria !== "") {            
      subCategorias.map(item => (
        (item.DESCRICAO.indexOf(descricaoSubCategoria.toUpperCase()) !== -1) ?
          item.VISIVEL = true
         : item.VISIVEL = false
        ))

        setVisivel(false)
        subCategorias.map(item => (
          item.VISIVEL && setVisivel(true)
        ))
    }
    else{
      subCategorias.map(item => (
        item.VISIVEL = true
      ))                
    }
  }, [descricaoSubCategoria, subCategorias, atualizar])        

  const selecionar = (subCategoria) => {        
    subCategorias.map(item => (
      (item.ID === subCategoria.ID) &&
        ((item.SELECIONADO) ? item.SELECIONADO = false : item.SELECIONADO = true)
    ))
        
    if (tipo === 1) setAtualizar(atualizar + 1);
    else setSelecionar(subCategoria)     
  }

  return (
    <div className="main_SubCategoria">
      <div className="main_SubCategoria">
        { subCategorias.map(item => (
          (item.VISIVEL) &&
            <div key={"trSubCategoriaCard"+ item.ID} id="cardItem_SubCategoria" onClick={() => selecionar(item)}>
              <div id="descricao_SubCategoria">
                {item.DESCRICAO}
              </div>
              <div id="selecionar_SubCategoria">
                { (item.SELECIONADO === true) ?
                    <img src={Img_botao_sub_selecionada} alt="Botão" className="botao_SubCategoria" />
                  : <img src={Img_botao_sub_nao_selecionada} alt="Botão" className="botao_SubCategoria" />
                }              
              </div>
            </div>
          ))
        }

        { ((!visivel) && (descricaoSubCategoria !== "")) &&
          <div id="semRegistro_SubCategoria">
            <label>Nenhum serviço encontrado com a descrição "{descricaoSubCategoria}"</label>
          </div>
        }
      </div>

      <div id="botoes_SubCategoria">
        <div>
          <img className="botao_SubCategoria" src={Img_progredir} alt="Retornar" onClick={() => setRetornar()}/>
        </div>

        <div>
          <img className="botao_SubCategoria" src={Img_prosseguir} alt="Selecionar"
            onClick={() => setSelecionar(subCategorias)} />
        </div>
      </div>
    </div>
  )
}

export default SubCategoriaCard