import "./style.css"
import React from "react"
import InputMask from "react-input-mask"
import img_boneco from "../../Assets/Img/boneco.png"
import img_excluir from "../../Assets/Img/Botoes/excluir.png"
import img_lupa from "../../Assets/Img/Botoes/lupa.png"

const InputMasked = ({title, name, mask, disabled, typeImage, placeholder, value, onChange, setImagem, setImagemExclusion}) => {
  return(
    <div id="main_InputMasked">
      { ((title !== undefined) && (title !== "")) && <label id="titulo_InputMasked">{title}</label> }

      <div id="container_InputMasked">
        { (typeImage === 1) &&
          <div className="viewCampo_InputMasked">
            <div className="imagem_InputMasked">
              <img src={img_boneco} className="botao_InputMasked" alt="Boneco" />
            </div>
            <div className="campo_InputMasked">
               
              { disabled &&
                <InputMask
                  className="class_InputMasked"
                  id={name}
                  name={name}
                  mask={mask}
                  disabled
                  placeholder={placeholder}                
                  value={value}
                  onChange={onChange} />
              }

              { !disabled &&            
                <InputMask
                  className="class_InputMasked"
                  id={name}
                  name={name}
                  mask={mask}                  
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange} />
              }
            </div>
          </div>
        }

        { (typeImage === 2) &&
          <div className="viewCampo_InputMasked">
            <div className="campo_InputMasked">

              { disabled &&
                <InputMask
                  className="class_InputMasked"
                  id={name}
                  name={name}
                  mask={mask}
                  disabled
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange} />
              }

              { !disabled &&
                <InputMask
                  className="class_InputMasked"
                  id={name}
                  name={name}
                  mask={mask}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange} />
              }

            </div>
            <div id="imagemCep_InputMasked">
              <img src={img_lupa} id="botaoLupa_InputMasked" alt="Lupa" onClick={setImagem} />
              <img src={img_excluir} id="botaoExclusion_InputMasked" alt="Excluir" onClick={setImagemExclusion} />
            </div>
          </div>
        }
        
        { ((typeImage !== 1) && (typeImage !== 2)) &&
          <div className="campo_InputMasked">

            { disabled &&
              <InputMask
                className="class_InputMasked"
                id={name}
                name={name}
                mask={mask}
                disabled={disabled}
                placeholder={placeholder}
                value={value}
                onChange={onChange} />
            }

            { !disabled &&
              <InputMask
                className="class_InputMasked"
                id={name}
                name={name}
                mask={mask}
                placeholder={placeholder}
                value={value}
                onChange={onChange} />
            }

          </div>
        }
      </div>
    </div>
  )
}

export default InputMasked