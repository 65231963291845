import "./style.css"
import React from "react"
import NumberFormat from "react-number-format"
import img_boneco from "../../Assets/Img/boneco.png"

const InputNumeric = ({title, name, disabled, typeImage, placeholder, maxLength, value, onChange}) => {
  return(
    <div id="main_InputNumeric">
      { ((title !== undefined) && (title !== "")) && <label id="titulo_InputNumeric">{title}</label> }

      <div id="container_InputNumeric">
        { (typeImage === 1) &&
          <div className="viewCampo_InputNumeric">
            <div className="imagem_InputNumeric">
              <img src={img_boneco} className="botao_InputNumeric" alt="Boneco" />
            </div>
            <div className="campo_InputNumeric">
              <NumberFormat 
                className="class_InputNumeric"
                id={name}
                name={name}
                disabled={disabled}
                placeholder={placeholder}
                maxLength={maxLength}
                allowNegative={false}
                value={value}
                onChange={onChange} />
            </div>
          </div>
        }

        { (typeImage !== 1) &&
          <div className="campo_InputNumeric">
            <NumberFormat 
              className="class_InputNumeric"
              id={name}
              name={name}
              disabled={disabled}
              placeholder={placeholder}
              maxLength={maxLength}
              allowNegative={false}
              value={value}
              onChange={onChange} />            
          </div>
        }
      </div>
    </div>
  )
}

export default InputNumeric