import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import { useState, useEffect } from 'react';
import axios from "axios"
import Login from "./Containers/Login"
import RecuperarSenha from "./Containers/RecuperarSenha"
import ServicoPrestado from "./Containers/ServicoPrestado"
import ServicoContratado from "./Containers/ServicoContratado"
import Usuario from "./Containers/Usuario"
import Menu from "./Containers/Menu"
import Servico from "./Containers/Servico"
import ListaServico from "./Containers/ListaServico"
import Footer from './Components/Footer';

const api = axios.create({
  baseURL: "https://api.brconnect.com.br/api/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json;charset=utf-8"
  }
})

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const [adimplente, setAdimplente] = useState(true)

  const [categorias, setCategorias] = useState([])
  const [subCategorias, setSubCategorias] = useState([])
  const [usuario, setUsuario] = useState({})

  const [mostrarLogin, setMostrarLogin] = useState(true)
  const [mostrarRecuperarSenha, setMostrarRecuperarSenha] = useState(false)
  const [mostrarNovoUsuario, setMostrarNovoUsuario] = useState(false)
  const [mostrarListaServico, setMostrarListaServico] = useState(false)
  const [mostrarMenu, setMostrarMenu] = useState(false)
  const [mostrarUsuario, setMostrarUsuario] = useState(false)
  const [mostrarServico, setMostrarServico] = useState(false)
  const [mostrarServicoContratado, setMostrarServicoContratado] = useState(false)
  const [mostrarServicoPrestado, setMostrarServicoPrestado] = useState(false)

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const setLogon = (usuario) => {
    setMostrarLogin(false)
    setUsuario(usuario)
    setMostrarListaServico(true)
  }

  const setNovoUsuario = () => {
    setMostrarLogin(false)
    setMostrarNovoUsuario(true)
  }

  const setRecuperarSenha = () => {
    setMostrarLogin(false)
    setMostrarRecuperarSenha(true)
  }

  const setSelecionarUsuario = (usuario) => {
    setUsuario(usuario)

    var retornoCategoria = { CONCRETIZADO: false, RESULTADO: { ID: 0 }, ERRO: "", AVISO: "" }
    var retornoSubCategoria = { CONCRETIZADO: false, RESULTADO: { ID: 0 }, ERRO: "", AVISO: "" }
    var jsonLogin = "{ \"CPF_CNPJ\": \""+ usuario.CPF_CNPJ +"\", \"SENHA\": \""+ usuario.SENHA +"\" }";

    // Busca informações das categorias
    api.get("CATEGORIA/Consultar?jsonLogin="+ jsonLogin)
    .then((response) => { retornoCategoria = JSON.parse(response.data) })
    .catch((response) => { retornoCategoria = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível obter as categorias. ("+ response +")\"}") })
    .finally(() => {
      if (retornoCategoria.CONCRETIZADO === false){
        setMostrarLogin(true)
        return
      }
      setCategorias(retornoCategoria.RESULTADO)
    })

    // Busca informações das sub-categorias
    api.get("SUB_CATEGORIA/Consultar?jsonLogin="+ jsonLogin)
    .then((response) => { retornoSubCategoria = JSON.parse(response.data) })
    .catch((response) => { retornoSubCategoria = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível obter as categorias. ("+ response +")\"}") })
    .finally(() => {
      if (retornoSubCategoria.CONCRETIZADO === false){
        setMostrarLogin(true)
        return
      }
      setSubCategorias(retornoSubCategoria.RESULTADO)
    })

    setMostrarLogin(false)
    setMostrarNovoUsuario(false)
    setMostrarUsuario(false)
    setMostrarListaServico(true)
  }

  const setVoltarNovoUsuario = () => {
    setMostrarNovoUsuario(false)
    setMostrarLogin(true)
  }

  const setVoltarRecuperarSenha = () => {
    setMostrarRecuperarSenha(false)
    setMostrarLogin(true)
  }

  const setVoltarUsuario = () => {
    setMostrarUsuario(false)
    setMostrarListaServico(true)
  }

  const logoff = () => {
    setMostrarListaServico(false)
    setMostrarMenu(false)
    setMostrarUsuario(false)
    setMostrarServico(false)
    setMostrarServicoContratado(false)
    setMostrarServicoPrestado(false)
    setMostrarLogin(true)
    setUsuario({})
  }

  const chamarMenu = () => {
    setMostrarListaServico(false)
    setMostrarUsuario(false)
    setMostrarServico(false)
    setMostrarServicoContratado(false)
    setMostrarServicoPrestado(false)
    setMostrarMenu(true)
  }

  const chamarListaServicos = () => {
    setMostrarMenu(false)
    setMostrarUsuario(false)
    setMostrarServico(false)
    setMostrarServicoContratado(false)
    setMostrarServicoPrestado(false)
    setMostrarListaServico(true)    
  }

  const chamarDadosCadastrais = () => {
    setMostrarListaServico(false)
    setMostrarMenu(false)
    setMostrarServico(false)
    setMostrarServicoContratado(false)
    setMostrarServicoPrestado(false)
    setMostrarUsuario(true)
  }

  const chamarServicosContratados = () => {
    setMostrarListaServico(false)
    setMostrarMenu(false)
    setMostrarUsuario(false)
    setMostrarServico(false)
    setMostrarServicoPrestado(false)
    setMostrarServicoContratado(true)
  }

  const chamarServicos = () => {
    setMostrarListaServico(false)
    setMostrarMenu(false)
    setMostrarUsuario(false)
    setMostrarServicoContratado(false)
    setMostrarServicoPrestado(false)
    setMostrarServico(true)
  }

  const chamarServicosPrestados = () => {
    setMostrarListaServico(false)
    setMostrarMenu(false)
    setMostrarUsuario(false)
    setMostrarServico(false)
    setMostrarServicoContratado(false)
    setMostrarServicoPrestado(true)
  }

  return (
    <div style={{display: "flex", flexGrow: 1, flexBasis: 0, flexDirection: "column", minHeight: height}}>
      <div style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        {/* Login */}
        { mostrarLogin &&
          <Login
            setLogon={setLogon}
            setNovoUsuario={setNovoUsuario}
            setRecuperarSenha={setRecuperarSenha} 
            setCategorias={setCategorias}
            setSubCategorias={setSubCategorias} />
        }

        {/* Recuperar senha */}
        { mostrarRecuperarSenha &&
          <RecuperarSenha setVoltar={setVoltarRecuperarSenha} />
        }

        {/* Novo usuário */}
        { mostrarNovoUsuario &&
          <Usuario 
            usuario={{ ID: undefined, Lst_PAGAMENTO: [] }}
            setSelecionar={setSelecionarUsuario}
            setVoltarNovo={setVoltarNovoUsuario} 
            width={width} />
        }

        {/* Lista serviço */}
        { mostrarListaServico &&
          <ListaServico
            usuario={usuario}
            categorias={categorias}
            subCategorias={subCategorias}
            chamarMenu={chamarMenu}
            logoff={logoff} 
            width={width} />
        }

        {/* Mostrar Menus */}
        { mostrarMenu &&
          <Menu 
            usuario={usuario}
            chamarDadosCadastrais={chamarDadosCadastrais}
            chamarServicos={chamarServicos}
            chamarServicosContratados={chamarServicosContratados}
            chamarServicosPrestados={chamarServicosPrestados}
            chamarListaServicos={chamarListaServicos}
            logoff={logoff} />
        }

        {/* Usuário */}
        { mostrarUsuario &&
          <Usuario 
            usuario={usuario}
            setSelecionar={setSelecionarUsuario}
            setVoltar={setVoltarUsuario}
            chamarMenu={chamarMenu} 
            width={width} />
        }

        {/* Serviço Contratados */}
        { mostrarServicoContratado &&
          <ServicoContratado
            usuario={usuario}
            categorias={categorias}
            subCategorias={subCategorias}
            chamarMenu={chamarMenu}
            chamarListaServicos={chamarListaServicos} 
            width={width} />
        }

        {/* Serviço */}
        { mostrarServico &&
          <Servico
            usuario={usuario}
            categorias={categorias}
            subCategorias={subCategorias}
            chamarMenu={chamarMenu}
            chamarListaServicos={chamarListaServicos} 
            width={width} />
        }

        {/* Serviço Prestado */}
        { mostrarServicoPrestado &&
          <ServicoPrestado
            usuario={usuario}
            categorias={categorias}
            subCategorias={subCategorias}
            chamarMenu={chamarMenu}
            chamarListaServicos={chamarListaServicos}
            logoff={logoff} 
            width={width} />
        }
      </div>
      <div style={{ display: "flex", flexGrow: 0 }}>
        <Footer />
      </div>
    </div>
  )
}

export default App;
